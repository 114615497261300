import AWS from "aws-sdk";
AWS.config.update({
  region: process.env.REACT_APP_COGNITO_POOL_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
  }),
});
// Enable AWS SDK logging
AWS.config.logger = console;
export default async function addPhoto(files, albumName) {
  console.log(files, "file in test files");
  var file = files;
  var fileName = Math.floor(Math.random() * 10000000) + removeSpaces(file.name);
  var albumPhotosKey = albumName + "/";
  var photoKey = albumPhotosKey + fileName;

  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: photoKey,
      Body: file,
      ACL: "public-read",
    },
  });

  var promise = await upload.promise();
  promise.imageName = fileName;
  return promise;
}

export async function addAudio(blob, albumName) {
  var d = new Date();
  var file = new File([blob], d.valueOf(), { type: "audio/wav" });
  const fileName = albumName + "/" + file.name;
  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: fileName,
      Body: file,
      ACL: "public-read",
    },
  });

  var promise = await upload.promise();
  promise.fileName = file.name;
  return promise;
}

export const DUMMY_IMAGE = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"

export const AWS_S3_BASE_URL_EMPTY = "https://sportex.s3.ap-south-1.amazonaws.com/"

export const S3_LATEST_URL = "https://sportex.s3.ap-south-1.amazonaws.com/channelImages/"

export const AWS_PHOTO_BASE_URL =
  "https://sportex.s3.ap-south-1.amazonaws.com/profileImages/";

export const AWS_BANNER_PHOTO_BASE_URL =
  "https://sportex.s3.ap-south-1.amazonaws.com/banner/";

export const AWS_TIPS_IMAGE =
  "https://sportex.s3.ap-south-1.amazonaws.com/tipsImages/";
export const AWS_TIPS_AUDIO =
  "https://sportex.s3.ap-south-1.amazonaws.com/tipsAudios/";

export const AWS_PACKAGES_BASE_URL =
  "https://sportex.s3.ap-south-1.amazonaws.com/packageImages/";

export const AWS_COMPETITION_BASE_URL = "https://sportex.s3.ap-south-1.amazonaws.com/competition/"

export function removeSpaces(str) {
  return str.replace(/\s+/g, '');
}