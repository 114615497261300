import React, { useEffect, useRef, useState } from "react";
import { Row, Card, Col, Button, Modal, Container, Spinner } from "react-bootstrap";
import  toast  from "react-hot-toast";
import { getCustomTextForMatch, putCustomMessage } from "../../../services/asyncFunctions";
export default function GlobalCustomMessage({
  show,
  onHide,
  matchId
}) {
  const inputRef = useRef();
  const [isChecked, setIsChecked] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const  [currentMatchId, setCurrentMatchId] = useState(matchId);
  function handleEventSend(){
    onHide();
  }  


  async function getMatchCustomText(){
    setIsLoading(true);
    try{
        const response = await getCustomTextForMatch(matchId);
        setMessage(response.data.data?.screenText);
    }catch(error){
        toast.error(error?.response?.data?.message)
    }finally{
        setIsLoading(false);
    }
  }

  async function updateMessage(){
    if(!message.trim() || !matchId) return;
    setIsLoading(true);
    let payload = {
        screenText: message,
        matchId,
        isPermanent: isChecked
    }
    try{
        const response = await putCustomMessage(payload);
        toast.success("Message Updated")
        onHide();
    }catch(error){
        toast.error(error?.response?.data?.message)
    }finally{
        setIsLoading(false);
    }
  }

  useEffect(() => {
    if(show ){
    //   inputRef.current.focus();
      getMatchCustomText();
    }
    if(!show){
      setIsChecked(false);
    }
  },[show])
  return (
    <>
      <Modal
        size="lg"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => onHide()}
      >
        <Modal.Header>
          <Modal.Title>Custom message</Modal.Title>
          <Button variant="" className="close" onClick={() => onHide()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
        { !isLoading &&  <div className="d-flex align-items-center flex-column flex-wrap form-group">
            <input
              type="text"
              ref={inputRef}
              className="custom-text-input"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            <div className="d-flex align-items-center flex-column gap-2 mb-4">
              <label htmlFor="permanant">Permanent Field</label>
              <input onChange={(e)=> setIsChecked(e.target.checked)} type="checkbox" name="" id="permanant" />
            </div>
            <button className="btn btn-warning m-auto mt-3" type="button" onClick={updateMessage}>Broadcast</button>
          </div>}
          {isLoading && <Spinner variant="info" animation="border"/>}
        </Modal.Body>
      </Modal>
    </>
  );
}
