import React, { useEffect, useRef, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
export default function CustomMessage({
  show,
  onHide,
  customText,
  handleCustomTextChange,
  sendEvent,
  currentPermanentText,
  removePermanentText
}) {
  const inputRef = useRef();
  const [isChecked, setIsChecked] = useState(false)



  function handleEventSend(){
    sendEvent(isChecked);
    handleCustomTextChange("")
    onHide();
  }  

  function removePermanent(){
    removePermanentText();
    onHide();
  }


  useEffect(() => {
    if(show){
      inputRef.current.focus();
      // setPermanentText(currentPermanentText);
    }
    if(!show){
      setIsChecked(false);
    }
  },[show])

  return (
    <>
      <Modal
        size="lg"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => onHide()}
      >
        <Modal.Header>
          <Modal.Title>Custom message</Modal.Title>
          <Button variant="" className="close" onClick={() => onHide()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center flex-column flex-wrap form-group">
            <input
              type="text"
              ref={inputRef}
              className="custom-text-input"
              value={customText}
              onChange={(e) => {
                handleCustomTextChange(e.target.value);
              }}
            />
            <div className="d-flex align-items-center flex-column gap-2 mb-4">
              <label htmlFor="permanant">Permanent Field</label>
              <input onChange={(e)=> setIsChecked(e.target.checked)} type="checkbox" name="" id="permanant" />
            </div>
       
            <button className="btn btn-warning m-auto mt-3" type="button" onClick={handleEventSend}>Broadcast</button>
            <div className="d-flex align-items-center flex-column gap-2 mt-4">
              <label htmlFor="permanant " className="font-weight-bold">Current Permanent Text</label>
              <p className=" font-italic blockquote">{currentPermanentText ? currentPermanentText : "N/A"}</p>
           {currentPermanentText && <button className="btn btn-secondary p-2 px-3 m-auto mt-3" type="button" onClick={removePermanent}>Remove Permanent Text</button>}
            
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
