import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { getUnselltedMatchSessions } from '../../services/asyncFunctions';
import { toastError } from '../components/PluginsMenu/Nestable/utils';

const UnsettledMatchSessions = () => {
    const {matchId} = useParams();
    const [isLoading, setIsLoading]  = useState(true);
    const [sessions, setSessions] = useState(null);



    async function fetchSessions(){
        setIsLoading(true);
        try {
          let result = await getUnselltedMatchSessions(matchId);
          setSessions(result?.data?.data);
        } catch (e) {
          toastError("Could not get sessions");
        } finally {
          setIsLoading(false);
        }
    }


useEffect(()=>{
    fetchSessions();
},[])

  return (
        <Card.Body>
          {isLoading && <h2 className="text-center">Loading...</h2>}
          <div className="d-flex align-items-center justify-content-between">
            {/* <div className="d-flex flex-column">
              <label htmlFor="">Items per page</label>
              <select value={limit} onChange={(e)=> setLimit(e.target.value)}>
                {Array.from({ length: 10 }, (_, index) => (index + 1) * 10).map(
                  (value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  )
                )}
              </select>
            </div> */}
            <div className="d-flex align-items-center ">
            {/* <button className="btn btn-primary mr-2" onClick={goBackToMatches}>
              Go Back
            </button>
            <button className="btn btn-warning mr-2" onClick={refreshBets}>
              Refresh
            </button> */}
            {/* <button className="btn btn-warning" onClick={()=> setShowExportModal(true)} >
              Export All
            </button> */}
            </div>
          </div>
          <div className="table-responsive">
            <div id="order_lis" className="dataTables_wrapper no-footer">
              <table
                id="example5"
                className="display mb-4 dataTablesCard dataTable no-footer w-100 "
                style={{ minWidth: 845 }}
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      className="sorting_asc"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-sort="ascending"
                      aria-label="Order ID: activate to sort column descending"

                    >
                     Session Title
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                
                    >
                      Result
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sessions &&
                    sessions.map((item) => (
                      <tr key={item._id} className="text-left mb-2">
                        <td className='fs-3'> <strong>{item}</strong></td>
                        <td>Not Settled</td>
                      </tr>
                    ))}


                </tbody>

              </table>
              {!sessions || sessions.length == 0 && <h3 className='text-center'>No Data</h3>}

            </div>
          </div>
          {/* {totalBetPages > 1 && (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={totalBetPages}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              forcePage={page}
            />
          )} */}
        </Card.Body>
    
  )
}

export default UnsettledMatchSessions