import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import MatchSettingsModal from "./modals/MatchSettingsModal";
import { putLeaderBoardStatus } from "../../services/asyncFunctions";

const DefaultMatchLeaderboard = ({tableData, refetch}) => {
   const [modalShow, setModalShow] = useState(false);
   async function updateStatus(matchId, state){
       try{
         let result = await putLeaderBoardStatus(matchId, state);
         console.log(result);
         if(result.status === 200) refetch();
       }catch(e){
        console.log(e)
       }
   }

   console.log(tableData)
  return (
      <Fragment>
        <MatchSettingsModal show={modalShow} onHide={()=>setModalShow(false)}/>
         <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <div id="order_lis" className="dataTables_wrapper no-footer">
                <table
                  id="example5"
                  className="display mb-4 dataTablesCard dataTable no-footer w-100 "
                  style={{ minWidth: 845 }}
                  role="grid"
                  aria-describedby="example5_info"
                >
                  <thead>
                    <tr role="row">
                      <th
                        className="sorting_asc"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-sort="ascending"
                        aria-label="Order ID: activate to sort column descending"
                        // style={{ width: "71.3333px" }}
                      >
                        Match Name
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Date: activate to sort column ascending"
                        // style={{ width: "74.6667px" }}
                      >
                        Leaderboard Status
                      </th>
  
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        // style={{ width: "98.6667px" }}
                      >
                        Edit LeaderBoard
                      </th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {tableData && tableData.map(item => <tr role="row" key={item._id}>
                      <td className="pointer match-name">{item.title}</td>
                      <td>
                      <span className={`btn btn-sm light ${item.rewards.isActive ? 'btn-success' : 'btn-danger'}`} onClick={()=> updateStatus(item.matchId, !item.rewards.isActive)}>{item.rewards.isActive ? 'ON' : 'OFF'}</span>
                      </td>
                      <td>
                        <span className="btn btn-sm light btn-warning" onClick={()=>setModalShow(true)}>Edit</span>
                      </td>
                    </tr>) }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          
        </div>
      </Fragment>
    );
  
};

export default DefaultMatchLeaderboard;
