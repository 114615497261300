import React, { useEffect, useRef, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import ChatCard from "./ChatCard";
import PerfectScrollbar from "react-perfect-scrollbar";
// import { getConversationList } from "../../../services/DashboardAsyncFunctions";
import toast from "react-hot-toast";
import { deleteChannel, getChannels, putFakeViews } from "../../../services/asyncFunctions";
import eventBus from "../../../services/Eventbus";
import { useDispatch } from "react-redux";
import { loadingToggleAction } from "../../../store/actions/AuthActions";
import Swal from "sweetalert2";
import FakeViewsModal from "../modals/FakeViewsModal";

function ConversationList() {
  const [isLoading, setIsLoading] = useState(true);
  const [chattList, setChatList] = useState(null);
  const [showFakeViews, setShowFakeViews] = useState(false);
  const dispatch = useDispatch();
  const [selectedChannelFakeViews, setSelectedChannelFakeViews] = useState(0);
  const selectedChannelIdRef = useRef("");
  let totalItems = 0;

  async function getAllChannels() {
    setIsLoading(true);
    try {
      const response = await getChannels();
      console.log(response);
      setChatList([...response.data.data.channel]);
      totalItems = response.data.data.count;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function removeChannel(channelId){

    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No',
    });
if(!result.isConfirmed) return;
dispatch(loadingToggleAction(true))
    try{
      const response = await deleteChannel(channelId);
      console.log(response);
      setChatList(chattList.filter(item => item._id != channelId));
    }catch(error){
      toast.error(error?.response?.data?.message);
    }finally{
dispatch(loadingToggleAction(false))

    }
  }

  function handleViewViewModalState(state, channelId, fakeViews){
    selectedChannelIdRef.current = channelId;
    setSelectedChannelFakeViews(fakeViews);
    setShowFakeViews(state);
  }
async function updateFakeViews(viewCountValue){
  try {
    const response = await putFakeViews({channelId: selectedChannelIdRef.current, fakeViews: viewCountValue});
    toast.success(response?.data?.message);
    setShowFakeViews(false);
    setSelectedChannelFakeViews(0);
    getAllChannels()
    selectedChannelIdRef.current = 0;
  } catch (error) {
    console.error(error);
    toast.error(error?.response?.data?.message);
  }
  
}

useEffect(()=>{
  if(chattList){
    let eventId = eventBus.on("fetchChannels", getAllChannels);
    return () => {
      eventBus.removeListener(eventId);
    };
  }
},[chattList])

  useEffect(() => {
    getAllChannels();
  }, []);
  return (
    <>
    <FakeViewsModal show={showFakeViews} close={()=> setShowFakeViews(false)} viewsForSelectedChannel={selectedChannelFakeViews} handleSubmit={updateFakeViews}/>
    <Card className="chat-list shadow-md">
      <PerfectScrollbar className="conversation-scrollable-section">
        { !isLoading && chattList &&
          chattList.map((item) => (
            <ChatCard
              key={item._id}
              createdAt={item.createdAt}
              totalSubscribers={item.totalSubscriber}
              channelName={item.name}
              link={item.link}
              description={item.description}
              bots={item.fakeMember}
              tipperName={item?.tipper?.userName}
              tipperImage={item.tipper?.profileImage}
              image={item.image}
              channelId={item._id}
              tipperId={item?.tipper?._id}
              deleteChannel={removeChannel}
              setFakeViews={handleViewViewModalState}
              fakeViews={item?.fakeViews}
            />
          ))}
          {
            (!chattList || chattList.length == 0) && !isLoading &&
              <h5 className="text-center">No Data</h5>
          }
          {isLoading && <div className="d-flex align-items-center justify-content-center"><Spinner animation="border" variant="info" /> </div>  }
      </PerfectScrollbar>
    </Card>
    </>
  );
}

export default ConversationList;
