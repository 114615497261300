import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getDefaultCoins, rewardsGet, rewardsPost, updateDefaultCoins } from "../../services/asyncFunctions";
import { loadingToggleAction } from "../../store/actions/AuthActions";
import MultiInputRewardList from "../components/multiInputList/MultiInputRewardList";

function RewardsSettings() {
 const [defaultCoins, setDefaultCoins] = useState(null);
const [rewardsList, setRewardList] = useState(null);
const [successMessage, setSuccessMessage] = useState(false);
  const dispatch = useDispatch();


  async function postRewardClaim(payload) {
    dispatch(loadingToggleAction(true));
    let filteredList = filterKeys(payload)
    try {
      const response = await rewardsPost(changeKey(filteredList));
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }finally{
    dispatch(loadingToggleAction(false));
    }
  }

  function changeKey(arr) {
    return arr.map(obj => {
      if ('_id' in obj) {
        const newObj = { ...obj, id: obj._id };
        delete newObj._id;
        return newObj;
      } else {
        return obj;
      }
    });
  }

  function filterKeys(arr) {
    return arr
      .filter(obj => obj.claimDate && obj.requiredCoin && obj.name)
      .map(obj => {
        let newObj = {};
        ["claimDate", "requiredCoin", "name", "image", "_id"].forEach(key => {
          if (key in obj) {
            newObj[key] = obj[key];
          }
        });
        return newObj;
      });
  }

function handleInputChange(event){
   setDefaultCoins(prevData => ({ ...prevData, [event.target.name] : event.target.value}))
   console.log(defaultCoins)
}

async function updateDefaultCoinsLocal(){
  dispatch(loadingToggleAction(true));
  try{
  let res = await updateDefaultCoins({...defaultCoins});
  setSuccessMessage(true);
  getDefaultCoinDetails();
  }catch(e){
      
  }finally{
    dispatch(loadingToggleAction(false));
  }
} 



async function getDefaultCoinDetails(){
  dispatch(loadingToggleAction(true));
try{
let res = await getDefaultCoins();
setDefaultCoins(res.data.data);
}catch(e){

}finally{
  dispatch(loadingToggleAction(false));
}
 } 

useEffect(()=>{
   getDefaultCoinDetails();
},[])  
  
  
  return (
    <Card className="p-2">
      <h2>Coins</h2>
      {defaultCoins && <div className=" form-control h-100 d-flex align-items-center flex-column coin-input-container shadow-sm">
        <div className="d-flex flex-column w-100 mr-2">
          <label htmlFor="signupCoins" className="font-weight-bold">Sign up Coins</label>
          <input className="form-control" value={defaultCoins?.joiningCoins} type="number" name="joiningCoins" id="signupCoins" placeholder="" onChange={handleInputChange}/>
        </div>
        <div className="d-flex flex-column w-100 mr-2">
          <label htmlFor="signupCoins" className="font-weight-bold">Sign up diamonds</label>
          <input className="form-control" value={defaultCoins?.joiningDiamond} type="number" name="joiningDiamond" id="signupCoins" placeholder="" onChange={handleInputChange}/>
        </div>
        <div className="d-flex flex-column w-100 mr-2">
          <label htmlFor="dailyDiamonds" className="font-weight-bold">Daily bonus diamonds</label>
          <input className="form-control" value={defaultCoins?.dailyBonusDiamond} type="number" name="dailyBonusDiamond" id="dailyDiamonds" placeholder="" onChange={handleInputChange}/>
        </div>
        <div className="d-flex flex-column w-100">
          <label htmlFor="loginCoins" className="font-weight-bold">Daily Bonus coins</label>
          <input className="form-control mb-3" value={defaultCoins?.dailyBonus} type="number" name="dailyBonus" id="loginCoins" placeholder="" onChange={handleInputChange}/>
          <button className="btn btn-success" onClick={updateDefaultCoinsLocal}>Submit</button>
          {successMessage && <p className="text-center text-success">Default coins were updated!</p>}
        </div>
      </div>}
<hr />
      <h3>Rewards</h3>
      <div className="m-auto">
       <MultiInputRewardList updateHandler={postRewardClaim}/>
      </div>
        
    </Card>
  );
}

export default RewardsSettings;
