import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const SELECT_PACKAGE_ID = "packageid selected";
export const AGENT_MATCH_ID = "agent id selected";
export const REMOVE_TOKEN_FROM_STORE = "remove token from store";
export const ADD_ROLES_FOR_USER= "addroles";
export const UPDATE_USERTYPE = "updateUsertype";
export const SET_USER_NAME = "set user name"

export function signupAction(email, password, history) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        // runLogoutTimer(dispatch, response.data.expiresIn * 1000, history);
        dispatch(confirmedSignupAction(response.data));
        history.push("/");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}



export function loginAction(email, password, history) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        const responseData = response.data.data.token;
        saveTokenInLocalStorage(responseData);
        dispatch(setUserRoles(response.data.data.isSubAdmin ? response.data.data.roles : ["*"]))
        dispatch(setUserType(response.data.data.isSubAdmin ? "subAdmin" : "admin"))
        dispatch(setUserName(response.data.data?.name));
        dispatch(loginConfirmedAction(responseData));
        dispatch(loadingToggleAction(false));
        history.push("/");
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = formatError(error?.response?.data?.message ? "ERROR" : error?.response?.data?.message);
        dispatch(loginFailedAction(errorMessage));
        dispatch(loadingToggleAction(false));
      });
  };
}
// async function loginAction(email, password, history){
//   try{

//   }cat
// }

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function logout(history) {
  localStorage.removeItem("userDetails");
  history.push("/login");
  return {
    type: LOGOUT_ACTION,
  };
}

export function loginConfirmedAction(data) {
  console.log("action confirmed")
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

export function setSelectedPackageId(id) {
  return {
    type: SELECT_PACKAGE_ID,
    payload: id,
  };
}

export function setAgentMatchId(id){
  return {
    type: AGENT_MATCH_ID,
    payload: id,
  }
}

export function toggleIsAuthenticated(){
  return {
    type: REMOVE_TOKEN_FROM_STORE,
  }
}

export function setUserType(data){
  return {
    type: UPDATE_USERTYPE,
    payload: data
  }
}
export function setUserRoles(data){
  return {
    type: ADD_ROLES_FOR_USER,
    payload: data
  }
}

export function setUserName(data){
  return{
    type: SET_USER_NAME,
    payload: data
  }
}