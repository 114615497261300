import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getMatchSquads,
  postPlayingEleven,
  resetMatchToss,
  toggleMatchApiAndSocket,
} from "../../services/asyncFunctions";
import { loadingToggleAction } from "../../store/actions/AuthActions";
import PlayerSelectionCard from "../components/PlayerSelectionCard";
import Select from "react-select";
import { change } from "redux-form";
import { toastError, toastSuccess } from "../components/PluginsMenu/Nestable/utils";
import Swal from "sweetalert2";

const matchFormatArray = [
  {
    value: 60,
    label: "T10-10 over 60 balls P/N",
  },
  {
    value: 120,
    label: "T20-20 over 120 balls P/N",
  },
  {
    value: 300,
    label: "Odi-50 over 300 balls P/N",
  },
  {
    value: 100,
    label: "Hundred 100 balls P/N",
  },
  // {
  //   value: "",
  //   label: "5day test-90 over per day",
  // },
];

function PlayerSelection() {
  const [selectedPlayersTeamA, setSelectedPlayersTeamA] = useState([]);
  const [selectedPlayersTeamB, setSelectedPlayersTeamB] = useState([]);
  const [maxLimitErrorTeamA, setMaxLimitErrorTeamA] = useState("");
  const [maxLimitErrorTeamB, setMaxLimitErrorTeamB] = useState("");
  const [globalError, setGlobalError] = useState("");
  const [teamsCount, setTeamsCount] = useState(1);
  const [teamA, setTeamA] = useState(null);
  const [teamB, setTeamB] = useState(null);
  const [totalBalls, setTotalBalls] = useState(0);
  const [matchFormat, setMatchFormat] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const matchId = localStorage.getItem("agentMatchId");
  const [resetIsLoading, setResetIsLoading] = useState(false);

  //   localStorage.setItem("agentMatchId", "60968");

  async function getSquadForThisMatch() {
    dispatch(loadingToggleAction(true));
    try {
      let result = await getMatchSquads(matchId);
      console.log(result);
      // if (result?.data?.data?.status == "playOnGoing") {
      //   history.push("/agent-match-screen");
      //   return;
      // }
      setTeamA(result?.data?.data?.teama);
      setTeamB(result?.data?.data?.teamb);
      setSelectedPlayersTeamA(
        filterPlayingElevenFromApi(result?.data?.data?.teama?.playingEleven)
      );
      setSelectedPlayersTeamB(
        filterPlayingElevenFromApi(result?.data?.data?.teamb?.playingEleven)
      );
      setTotalBalls(result?.data?.data?.totalBalls);
      updateMatchFormat(result?.data?.data?.totalBalls ?? 0);
      dispatch(loadingToggleAction(false));
    } catch (error) {
      console.error(error);
      dispatch(loadingToggleAction(false));
    }
  }

  function filterPlayingElevenFromApi(playingEleven) {
    if (!playingEleven?.length) return [];
    let temp = playingEleven.map((item) => {
      return {
        playerId: item.playerId,
        name: item.name,
        isSubstitute: item.isSubstitute,
        _id: item._id,
        hasbatted: item.hasbatted,
      };
    });
    return temp;
  }

  function isPlayerSelected(player, selectedList) {
    return selectedList.some((item) => item.playerId == player);
  }

  function isPlayerSubstitute(playerId, selectedList) {
    let item = selectedList.filter((item) => item.playerId == playerId);
    if (item[0]?.isSubstitute == true) return true;
    else return false;
  }

  async function submitTeamsData() {
    if (!matchFormat) return;
    let payload = {
      matchId: matchId,
      teams: [
        {
          teamId: teamA.team_id + "",
          playingEleven: selectedPlayersTeamA,
        },
        {
          teamId: teamB.team_id + "",
          playingEleven: selectedPlayersTeamB,
        },
      ],
      totalBalls: totalBalls,
    };
    console.log(payload);
    if (selectedPlayersTeamA.length < 4 || selectedPlayersTeamB.length < 4) {
      setGlobalError("Please select a minimum of 4 players in both teams");
      return;
    } else {
      setGlobalError("");
      dispatch(loadingToggleAction(true));
      try {
        let result = await postPlayingEleven(payload);
        console.log(result);
        if (result.status === 200) {
          history.push("/agent-match-screen");
        }
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(loadingToggleAction(false));
      }
    }
  }

  const handlePlayerRemove = (id, type) => {
    console.log("inside remove", id, type);
    if (type == "a") {
      let updatedPlayers = selectedPlayersTeamA.filter(
        (player) => player.playerId !== id
      );
      setSelectedPlayersTeamA(updatedPlayers);
    } else {
      let updatedPlayers = selectedPlayersTeamB.filter(
        (player) => player.playerId !== id
      );
      setSelectedPlayersTeamB(updatedPlayers);
    }
  };
  const handlePlayerSelection = (player, type) => {
    if (type == "a") {
      if (isPlayerSelected(player.playerId, selectedPlayersTeamA)) {
        setMaxLimitErrorTeamA("");
        handlePlayerRemove(player.playerId, type);
      } else {
        if (selectedPlayersTeamA.length < 11) {
          // setMaxLimitErrorTeamA("")
          setSelectedPlayersTeamA([
            ...selectedPlayersTeamA,
            { ...player, isSubstitute: false },
          ]);
        } else if (
          selectedPlayersTeamA.length >= 11 &&
          selectedPlayersTeamA.length < 15
        ) {
          // setMaxLimitErrorTeamA("Now select 4 substitute players")
          setSelectedPlayersTeamA([
            ...selectedPlayersTeamA,
            { ...player, isSubstitute: true },
          ]);
        } else if (selectedPlayersTeamA.length >= 15) {
          // setMaxLimitErrorTeamA("Max limit reached!");
          return;
        }
      }
    } else {
      if (isPlayerSelected(player.playerId, selectedPlayersTeamB)) {
        setMaxLimitErrorTeamB("");
        handlePlayerRemove(player.playerId, type);
      } else {
        if (selectedPlayersTeamB.length < 11) {
          // setMaxLimitErrorTeamA("")
          setSelectedPlayersTeamB([
            ...selectedPlayersTeamB,
            { ...player, isSubstitute: false },
          ]);
        } else if (
          selectedPlayersTeamB.length >= 11 &&
          selectedPlayersTeamB.length < 15
        ) {
          // setMaxLimitErrorTeamA("Now select 4 substitute players")
          setSelectedPlayersTeamB([
            ...selectedPlayersTeamB,
            { ...player, isSubstitute: true },
          ]);
        } else if (selectedPlayersTeamB.length >= 15) {
          // setMaxLimitErrorTeamA("Max limit reached!");
          return;
        }
      }
    }
  };
  const isSelectedPlayer = (playerId, type) => {
    if (type == "a") {
      if (isPlayerSelected(playerId, selectedPlayersTeamA)) return true;
      else return false;
    } else {
      if (isPlayerSelected(playerId, selectedPlayersTeamB)) return true;
      else return false;
    }
  };

  const isSubstitutePlayer = (playerId, type) => {
    if (type == "a") {
      if (isPlayerSubstitute(playerId, selectedPlayersTeamA)) return true;
      else return false;
    } else {
      if (isPlayerSubstitute(playerId, selectedPlayersTeamB)) return true;
      else return false;
    }
  };
  function handleNextStep() {
    if (teamsCount < 2) {
      // setSelectedPlayers([]);
      setTeamsCount(2);
    } else {
      submitTeamsData();
    }
  }
  function goback() {
    setTeamsCount(1);
  }

  function updateMatchFormat(balls) {
    
      let item = matchFormatArray.filter((item) => item.value === balls);
      setMatchFormat(item);
      console.log(matchFormat);
    
  }

  async function resetMatchTossLocal(){
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No',
    });

    if(!result.isConfirmed) return;
    setResetIsLoading(true);
    try{
      let response = await resetMatchToss(matchId);
      toastSuccess("Match was reset!");
      if(response?.status == 200){
        history.push("/agent-match-listing");
      }
    }catch(error){
      toastError(error?.response?.data?.message);
    }finally{
      setResetIsLoading(false)
    }
  }

  async function switchMatchApiToggle(){
    setResetIsLoading(true);
    try{
      let response = await toggleMatchApiAndSocket(matchId);
      toastSuccess("Match was reset!");
      if(response?.status == 200){
        history.push("/agent-match-listing");
      }
    }catch(error){
      toastError(error?.response?.data?.message);
    }finally{
      setResetIsLoading(false)
  }
}

  async function switchMatchToApi(){
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No',
    });

    if(!result.isConfirmed) return;
    switchMatchApiToggle();
  }

  useEffect(() => {
    if (matchId) {
      getSquadForThisMatch();
    } else {
      history.push("/agent-match-listing");
    }
  }, []);

  useEffect(() => {
    if (selectedPlayersTeamA.length < 11) {
      setMaxLimitErrorTeamA("");
    } else if (
      selectedPlayersTeamA.length >= 11 &&
      selectedPlayersTeamA.length < 16
    ) {
      setMaxLimitErrorTeamA("Now select 5 substitute players");
    } else {
      setMaxLimitErrorTeamA("Max limit reached!");
    }
  }, [selectedPlayersTeamA]);

  useEffect(() => {
    if (setSelectedPlayersTeamB.length < 11) {
      setMaxLimitErrorTeamB("");
    } else if (
      selectedPlayersTeamB.length >= 11 &&
      selectedPlayersTeamB.length < 16
    ) {
      setMaxLimitErrorTeamB("Now select 5 substitute players");
    } else {
      setMaxLimitErrorTeamB("Max limit reached!");
    }
  }, [selectedPlayersTeamB]);
  return (
    <Card className="p-2 player-selection-container">
      <div className="w-100 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center justify-content-between mb-2 w-100">
          <div>
            <h1 className="mr-2">
              {teamsCount == 1
                ? teamA?.name ?? "Team A"
                : teamB?.name ?? "Team B"}
            </h1>
            <img
              src={teamsCount == 1 ? teamA?.logo_url : teamB?.logo_url}
              alt=""
              width={50}
              height={50}
            />
            {/* {totalBalls} */}
          </div>
          {teamsCount == 1 && (
            <div className="form-group w-50" >
            <label className="ml-auto">Match Format</label>
            <select
                        name="emailstatus"
                        className="form-control"
                        onChange={(e) => {
                          setTotalBalls(e.target.value);
                          updateMatchFormat(e.target.value);
                        }}
                        style={{borderColor: "black", background: "#e9e6e6", width: "300px"}}
                      >
                        <option value={totalBalls}></option>
                        {matchFormatArray.map(item => <option value={item.value}>{item.label}</option>)} 
                      </select>
            </div>
          )}
        </div>
        {teamsCount == 2 && (
          <button className="btn btn-secondary" onClick={goback}>
            Back
          </button>
        )}
        
        <button className="btn btn-warning p-2 px-3 fs-12" onClick={resetMatchTossLocal}>
           {resetIsLoading ? "Loading.." : "Reset Match"}
        </button>
        {/* <button
                className={`btn p-2 px-3 fs-12 shadow-sm btn-secondary ml-2 `}
                onClick={switchMatchToApi}
              >
                <div>Match Via </div>
                <span>({isMatchViaSocket ? "Admin" : "Api"})</span>
              </button> */}
        
      </div>
      {teamsCount == 1 && (
        <>
          <h3 className="mb-3">
            Select playing 11 (
            <span
              className={`${
                selectedPlayersTeamA.length == 11 && "text-success"
              }`}
            >
              {selectedPlayersTeamA.length} selected
            </span>
            )
          </h3>
          <h5
            className={` ${
              selectedPlayersTeamA.length >= 11 &&
              selectedPlayersTeamA.length < 15
                ? "text-success"
                : "text-danger"
            }`}
          >
            {maxLimitErrorTeamA}
          </h5>
        </>
      )}
      {teamsCount == 2 && (
        <>
          <h3 className="mb-3">
            Select playing 11 (
            <span
              className={`${
                selectedPlayersTeamB.length == 11 && "text-success"
              }`}
            >
              {selectedPlayersTeamB.length} selected
            </span>
            )
          </h3>
          <h5
            className={` ${
              selectedPlayersTeamB.length >= 11 &&
              selectedPlayersTeamB.length < 15
                ? "text-success"
                : "text-danger"
            }`}
          >
            {maxLimitErrorTeamB}
          </h5>
        </>
      )}
      {teamsCount == 1 && (
        <div className="w-100 d-flex align-items-center flex-wrap ">
          {teamA &&
            teamA?.squads?.map((item) => (
              <PlayerSelectionCard
                key={item.player_id}
                data={item}
                type="a"
                isSelected={isSelectedPlayer}
                isSubstitute={isSubstitutePlayer}
                togglePlayer={handlePlayerSelection}
              />
            ))}
        </div>
      )}

      {teamsCount == 2 && (
        <div className="w-100 d-flex align-items-center flex-wrap ">
          {teamB &&
            teamB?.squads?.map((item) => (
              <PlayerSelectionCard
                key={item.player_id}
                data={item}
                type="b"
                isSelected={isSelectedPlayer}
                isSubstitute={isSubstitutePlayer}
                togglePlayer={handlePlayerSelection}
              />
            ))}
        </div>
      )}
      <h4 className="text-danger">{globalError}</h4>
      <button
        className="btn w-50 m-auto bg-warning text-white"
        onClick={handleNextStep}
      >
        Next
      </button>
    </Card>
  );
}

export default PlayerSelection;
