import React from 'react'
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Switch from "react-switch";


function SessionHistoryPostCard({teams, addSession}) {
  const [switchState, setSwitchState] = useState(false);
  const [cardtext, setCardText] = useState("text");
  const [selectedInning, setSelectedInning] = useState("");
  const [sessionMin, setSessionMin] = useState("");
  const [sessionMax, setSessionMax] = useState("");
  const [sessionOpen, setSessionOpen] = useState("");
  const [sessionOdds, setSessionOdds] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [passedAt, setPassedAt] = useState("");

 function handleSessionStateUpdate(){
     addSession(switchState, cardtext, selectedInning, sessionMin, sessionMax, sessionOpen, sessionOdds,selectedTeam, passedAt)
 }


  return (
    <div className="d-flex flex-column history-card shadow rounded p-2 mb-3 mt-3">
    <div className="text-center">
      <div
        className="text-black text-bold mb-2 d-flex w-100 align-items-center justify-content-between"
        
      >
        {/* <span style={{ textDecoration: "underline" }}>{t</span> */}
        <Button variant='warning light' className="fs-12 p-2 px-4" onClick={handleSessionStateUpdate}>Submit</Button>
      </div>
    </div>
    <div className="d-flex align-items-center w-100 justify-content-between mb-3">
      <select
        className="mr-2"
        name=""
        id=""
        value={selectedInning}
        onChange={(e) => setSelectedInning(e.target.value)}
      >
        <option value="not selected">Select inning</option>
        <option value="1st Inning">1st Inning</option>
        <option value="2nd Inning">2nd Inning</option>
          <option value="3rd Inning">3rd Inning</option>
          <option value="4th Inning">4th Inning</option>
      </select>
      <select
        className="mr-2"
        name=""
        id=""
        value={selectedTeam}
        onChange={(e) => setSelectedTeam(e.target.value)}
      >
        <option value="not selected">Select team</option>
        {teams.map(team => <option value={team.teamId}>{team.name}</option> )}
        
      </select>
      <Switch
        checked={switchState}
        onChange={(value) => setSwitchState(value)}
        inputProps={{ "aria-label": "Color switch demo" }}
        color="warning"
      />
    </div>
    <div className="d-flex align-items-center w-100 justify-content-between">
    <fieldset>
    <legend className="input-label">Text</legend>
      <input
        type="text"
        className="session-text mr-2"
        value={cardtext}
        // placeholder="text"
        onChange={(e) => setCardText(e.target.value)}
      />
</fieldset>
       <fieldset>
    <legend className="input-label">Min</legend>
      <input
        type="text"
        className="session-text mr-2"
        value={sessionMin}
        // placeholder="min"
        onChange={(e) => setSessionMin(e.target.value)}
      />
</fieldset>
       <fieldset>
    <legend className="input-label">Max</legend>
      <input
        type="text"
        className="session-text mr-2"
        value={sessionMax}
        // placeholder="max"
        onChange={(e) => setSessionMax(e.target.value)}
      />
</fieldset>
       <fieldset>
    <legend className="input-label">Odds</legend>
      <input
        type="text"
        className="session-text mr-2"
        value={sessionOdds}
        // placeholder="odds"
        onChange={(e) => setSessionOdds(e.target.value)}
      />
</fieldset>
       <fieldset>
    <legend className="input-label">Open</legend>
      <input
        type="text"
        className="session-text mr-2"
        value={sessionOpen}
        // placeholder="open"
        onChange={(e) => setSessionOpen(e.target.value)}
      />
</fieldset>
<fieldset>
    <legend className="input-label">Passed At</legend>
      <input
        type="text"
        className="session-text mr-2"
        value={passedAt}
        // placeholder="open"
        onChange={(e) => setPassedAt(e.target.value)}
      />
</fieldset>
    </div>
  </div>
  )
}

export default SessionHistoryPostCard;