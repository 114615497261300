import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { getMatchSquad, getTopPicksForFantasyMatch, postTopPicks } from '../../../services/asyncFunctions';
import ReactSelect from "react-select";


function TopPicksForFantasyMatchModal({ show, onHide, matchId}) {
  const [users, setUsers] = useState([]);
  const [playersSelectData, setPlayersSelectData] = useState([]);
  const [select2Key, setSelect2Key]  = useState(5);
  const [picksData, setpicksData] = useState([]);



  function handlePlayerPickUpdate(value){
    console.log(value)
    if(!value){
      setpicksData([]);
      return
  }
    setpicksData(value);
  }



  async function getTopPicks(){
    try{
      let result = await getTopPicksForFantasyMatch(matchId);
      console.log(result)
      let selectTempData = result.data.data?.topPicks?.team.map((item) => ({
        label: item.name,
        value: {
          pid: String(item.pid),
          name: item.name,
          role: item.role,
          teamName: item.teamName,
          teamId: String(item.teamId)
        },
      }));
      setpicksData(selectTempData)
    }catch(error){
      console.log(error)
    }
  }


  async function addTopPicks(){
    let filteredData = picksData.map(item => item.value);
    let payload = {
      matchId,
      team:[
        ...filteredData
      ]
    }
    try{
      let result = await postTopPicks(payload);
      setSelect2Key(prev => prev + 1);
      onHide();
    }catch(error){
      console.log(error)
    }
  }



  useEffect(() => {
    async function getSquad() {
      try {
        let result = await getMatchSquad(matchId);
        let selectTempData = result.data.data?.players.map((item) => ({
          label: item.name,
          value: {
            pid: String(item.pid),
            name: item.name,
            role: item.role,
            teamName: item.teamName,
            teamId: String(item.teamId)
          },
        }));
        setPlayersSelectData(selectTempData);
        // setUsers(temp);
      } catch (error) {
        console.log(error);
      }
    }
    if (show) {
      getTopPicks();
      getSquad();
    }
  }, [show]);
  return (
    <Modal className="fade" show={show} size="xl">
    <Modal.Header>
      <Modal.Title>Top Picks</Modal.Title>
      <Button variant="" className="close" onClick={() => onHide()}>
        <span>&times;</span>
      </Button>
    </Modal.Header>
    <Modal.Body className="">
    <div className="preview-right_bottom">
                <div className="preview-right-header shadow-md rounded">
                  Top Picks
                </div>
                <div className="d-flex align-items-center mt-1">
                  <ReactSelect
                    key={select2Key}
                    options={playersSelectData}
                    className="w-100"
                    value={picksData}
                    isMulti
                    onChange={(value) => handlePlayerPickUpdate(value)}
                  />
                  <button className="btn btn-primary p-0 py-2 px-3 ml-2" onClick={addTopPicks}>
                    Send
                  </button>
                </div>
                <div className="picks-content">
                        {/* {displayPicksData.riskPicks.map(item => <span className="">{item.name}</span>)} */}
                </div>
              </div>
    </Modal.Body>
  </Modal>
  )
}

export default TopPicksForFantasyMatchModal