import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { getBallsFromOverString, getOverString } from "../../../constants/agentEvents";
export default function BowlerStatsModal({
  show,
  onHide,
  overs,
  updatePlayer,
  currentBowler,
  sendUpdatedPlayerData,
  title
}) {
  const [totalBalls, setTotalBalls] = useState(overs);
  const [bowlerData, setBowlerData] = useState({
    runs: currentBowler?.runs,
    wkts: currentBowler?.wkts,
    name: currentBowler?.name,
    playerId: currentBowler?.playerId,
  });


  console.log(currentBowler, "line 1")


  function handleOversChange(e){
    setTotalBalls(e.target.value)
  }

  function updateBowlerStats(e){
        setBowlerData({
            ...bowlerData,
            [e.target.name] : e.target.value,
        })
   }
   

function submitData(){
   let overStringToBalls = getBallsFromOverString(totalBalls);
    sendUpdatedPlayerData(
  {
      ...bowlerData,
      overs: overStringToBalls,
  }
 );

     updatePlayer({
        ...bowlerData,
        overs: overStringToBalls
     })

 
   onHide();
}  

useEffect(()=>{
 setTotalBalls(overs);
},[currentBowler])    

useEffect(()=>{
  setBowlerData({
    runs: currentBowler?.runs,
    wkts: currentBowler?.wkts,
    name: currentBowler?.name,
    playerId: currentBowler?.playerId,
  });
 },[currentBowler])    
  return (
    <>
      <Modal
        size="sm"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => onHide()}
      >
        <Modal.Header>
          <Modal.Title>Edit {title} Data</Modal.Title>
          <Button variant="" className="close" onClick={() => onHide()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center flex-wrap form-group">
          <label htmlFor="">Overs</label>
            <input
              type="text"
              className="custom-text-input"
              placeholder="Overs"
              value={totalBalls}
              name="balls"
              onChange={handleOversChange}
            />
            <label htmlFor="">Runs</label>
             <input
              type="text"
              className="custom-text-input"
              placeholder="Runs"
              name="runs"
              value={bowlerData?.runs}
              onChange={updateBowlerStats}
            />
            <label htmlFor="">Wickets</label>
             <input
              type="text"
              className="custom-text-input"
              placeholder="Wickets"
              value={bowlerData?.wkts}
              name="wkts"
              onChange={updateBowlerStats}
            />
            <button
              className="btn btn-warning m-auto"
              type="button"
              onClick={submitData}
            >
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
