import React from "react";
import { Button, Modal } from "react-bootstrap";
import MultiInputListComponent from "../multiInputList/MultiInputListComponent";

function MatchSettingsModal({ show, onHide, matchName, defaultSettings }) {
  return (
    <Modal
      size="md"
      className="fade vertically-centered"
      show={show}
      centered
      onHide={onHide}
    >
      <Modal.Header>
        <Modal.Title>India vs Pak leaderboard settings</Modal.Title>
        <Button variant="" className="close" onClick={() => onHide()}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center">
            <div>
          <label className="mb-2 ">
            <strong className="">Total Winners</strong>
          </label>
          <input
            type="text"
            className="custom-text-input"
            //   value={""}
          />
            </div>
        </div>
        <div className="reward-list-container">
         <MultiInputListComponent />
        </div>
          <button className="btn btn-warning w-100 m-auto" type="button">
            Submit
          </button>
      </Modal.Body>
    </Modal>
  );
}

export default MatchSettingsModal;
