import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { notifyError, notifySuccess } from "../../../constants/testTeams";
import { updateUserCoins } from "../../../services/asyncFunctions";

export default function UserCoinsModal({ show, close, userCoins, userType, userId, refetchUser }) {
  const [editToggle, setEditToggle] = useState(false);
  const [operator, setOperator] = useState("-");
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);


async function updateUserCoinsLocal(){
   setIsLoading(true);
   let amountValue = operator == "-" ? -(amount) : amount
   try{
    let res = await updateUserCoins(userId, userType, amountValue)
    console.log(res)
    setAmount(0);
    notifySuccess();
    close();
    refetchUser();
   }catch(e){
    console.log(e)
    notifyError();
   }finally{
    setIsLoading(false);
   }
}

  return (
    <>
      <Modal
        size="md"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => close()}
      >
        <Modal.Header>
          <Modal.Title>Edit User Coins</Modal.Title>
          <Button variant="" className="close" onClick={() => close()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex h-100 flex-column form-control">
            <strong className="p-2 rounded shadow-md">
              SportEx Coins: {userCoins}{" "}
            </strong>
            <button className="btn btn-warning p-2 mb-2 w-25" onClick={()=> setEditToggle(!editToggle)}>Edit</button>
            {editToggle && (
              <>
                <strong className="mb-2">Select Operation</strong>
                <div className="d-flex align-items-center mb-2">
                  <button
                    className={`btn shadow-sm mr-2 ${
                      operator == "+" && "btn-secondary"
                    }`}
                    onClick={()=> setOperator("+")}
                  >
                    Add Coins
                  </button>
                  <button
                    className={`btn shadow-sm ${
                      operator == "-" && "btn-secondary"
                    }`}
                    onClick={()=> setOperator("-")}
                  >
                    Subtract Coins
                  </button>
                </div>
                <input type="number" className="form-control" value={amount} onChange={(e)=> setAmount(e.target.value)}/>
                {!isLoading ? <button className="btn shadow-sm btn-success mt-2" onClick={updateUserCoinsLocal}>Submit</button> : <h3>Loading...</h3>}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
