import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const UpcomingMatches = () => {
  // Active data
  let dummyArray = [
    {
      tName: "world \n cup",
      mName: "ind vs pak",
      teams: "India \n Pak",
    },
    {
      tName: "MPL",
      mName: "Uganda vs pak t20",
      teams: "Uganda \n Pak",
    },
    {
      tName: "IPL",
      mName: "aus vs pak",
      teams: "Aus \n Pak",
    },
  ];

  const [data, setData] = useState(dummyArray);

  const getItemStyle = (isDragging) => {
    return isDragging ? "drag-active" : "";
  };

  const getListStyle = (isDraggingOver) => {
    // return isDraggingOver ? "drag-active-list" : "";
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = list;
    const [removed] = result.splice(startIndex, 1);
    console.log(removed, "remove");
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(result) {
    console.log(result);
    if (!result.destination) return;
    if (result.destination.index == result.source.index) return;
    let { source, destination } = result;
    const items = reorder(data, source.index, destination.index);
    setData(items);
  }

  // use effect

  return (
    <Fragment>
      <Card>
        <Card.Header>
          <Card.Title>Upcoming Matches</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-12">
              <h1></h1>
              <div className="table-responsive">
                <DragDropContext onDragEnd={onDragEnd}>
                  <div id="order_lis" className="dataTables_wrapper no-footer">
                    <table
                      id="example5"
                      className="display mb-4 dataTable no-footer w-100 "
                      style={{ minWidth: 845 }}
                      role="grid"
                      aria-describedby="example5_info"
                    >
                      <thead>
                        <tr role="row">
                          <th
                            className="sorting_asc"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="ascending"
                            aria-label="Order ID: activate to sort column descending"
                            style={{ width: "71.3333px" }}
                          >
                            Tournament Name
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Date: activate to sort column ascending"
                            style={{ width: "74.6667px" }}
                          >
                            Match Name
                          </th>

                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "98.6667px" }}
                          >
                            Teams
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: 68 }}
                          >
                            Starting
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "89.3333px" }}
                          >
                            Tournament Category
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "89.3333px" }}
                          >
                            Match Category
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "89.3333px" }}
                          >
                            Action
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "89.3333px" }}
                          >
                            Remove
                          </th>
                        </tr>
                      </thead>
                      <Droppable droppableId="drop-context-table">
                        {(provided, snapshot) => {
                          return (
                            <tbody
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              className={`${getListStyle(
                                snapshot.isDraggingOver
                              )}`}
                            >
                              {data.map((dummy, index) => (
                                <Draggable
                                  draggableId={dummy.tName}
                                  index={index}
                                  className={`${getItemStyle(
                                    snapshot.isDragging
                                  )}`}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <tr
                                        role="row"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={`${
                                          snapshot.isDragging && "drag-active"
                                        }`}
                                      >
                                        <td>{dummy.tName}</td>

                                        <td>{dummy.mName}</td>
                                        <td>{dummy.teams}</td>
                                        <td>10/02/2022 10:00:00</td>
                                        <td>
                                          <select
                                            name="emailstatus"
                                            className="form-control"
                                          >
                                            <option value="selectall">
                                              Select All
                                            </option>
                                            <option value="india">India</option>
                                            <option value="bet">
                                              option 2
                                            </option>
                                          </select>
                                        </td>
                                        <td>
                                          <select
                                            name="emailstatus"
                                            className="form-control"
                                          >
                                            <option value="selectall">
                                              T20
                                            </option>
                                            <option value="india">Test</option>
                                            <option value="bet">One Day</option>
                                          </select>
                                        </td>
                                        <td>
                                          <span className="btn btn-sm light btn-warning">
                                            Add
                                          </span>
                                        </td>
                                        <td>
                                          <span className="btn btn-sm light btn-warning">
                                            Remove
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  }}
                                </Draggable>
                              ))}

                              {provided.placeholder}
                            </tbody>
                          );
                        }}
                      </Droppable>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between">
                      <div className="dataTables_info">
                        Showing of 5 entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="example5_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="/order-list"
                        >
                          Previous
                        </Link>
                        <span>
                          <Link
                            to="/order-list"
                            className="paginate_button"
                          ></Link>
                        </span>
                        <Link className="paginate_button next" to="">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </DragDropContext>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default UpcomingMatches;
