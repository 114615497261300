import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";

import { Input, Label } from "reactstrap";
import addPhoto, {
  AWS_PHOTO_BASE_URL,
  AWS_S3_BASE_URL_EMPTY,
  DUMMY_IMAGE,
  S3_LATEST_URL,
} from "../../pages/test";
import { useDispatch } from "react-redux";
import { loadingToggleAction } from "../../../store/actions/AuthActions";
import Swal from "sweetalert2";
import { putChannel } from "../../../services/asyncFunctions";
import eventBus from "../../../services/Eventbus";

export default function ChannelDetails({
  show,
  onHide,
  image,
  channelName,
  tipperName,
  description,
  totalSubscribers,
  bots,
  link,
  channelId,
  tipperId
}) {
  const [channelData, setChannelData] = useState({
    name: "",
    tipperName: "",
    description: "",
    totalSubscribers: 0,
    bots: 0,
    image: "",
  });
  const [imageFile, setImageFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();
  const [imagePickerKey, setImagePickerKey] = useState(Math.random());
  const [enableImageEdit, setEnableImageEdit] = useState(false);

  function handleDetailsUpdate(e) {
    setChannelData({
      ...channelData,
      [e.target.name]: e.target.value,
    });
  }

  async function handleChannelDataUpdate() {
    setSuccessMessage(null);
    setErrorMessage(null);

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      cancelButtonText: "No",
    });
    if (!result.isConfirmed) return;
    dispatch(loadingToggleAction(true));
    try {
      let imageUrl = "";
      if (imageFile) {
        const response = await addPhoto(imageFile, "channelImages");
        imageUrl = response.imageName;
      }
      const response = await putChannel({
        channelId: channelId,
        name: channelData.name,
        description: channelData.description,
        fakeMember: channelData.bots ? channelData.bots : 0,
        image: imageUrl ? imageUrl : channelData.image,
        link: link,
      });

      setSuccessMessage("Updated Successfully");
      eventBus.emit("fetchChannels");
      onHide();
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
    } finally {
      dispatch(loadingToggleAction(false));
    }
  }

  function handleFileUpdate(e) {
    if (e.target.files.length > 0) {
      setImageFile(e.target.files[0]);
    }
  }

  function removeImage(){
    setChannelData({
       ...channelData,
        image: "",
      });
  }

 async function goToUserDetails(userId){
  dispatch(loadingToggleAction(true));
    localStorage.setItem("userDetailsId", JSON.stringify({userId, userType: "tipper"}));
    await new Promise(r => setTimeout(r, 1000));
  dispatch(loadingToggleAction(false));
    window.location.href = `/user-details`
  }

  useEffect(() => {
    setChannelData({
      name: channelName,
      tipperName: tipperName,
      description: description,
      totalSubscribers: totalSubscribers,
      bots: bots,
      image: image,
    });
  }, [channelName]);


  useEffect(()=>{
    if(enableImageEdit){
      setImageFile(null);
      setImagePickerKey(Math.random());
    }
  }, [enableImageEdit])


  useEffect(()=>{
    document.body.classList.add("no-scroll");
    return () => document.body.classList.remove("no-scroll");
  },[])

  return (
    <>
      <Modal
        size="lg"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => onHide()}
      >
        <Modal.Header>
          <Modal.Title>Channel Details</Modal.Title>
          <Button variant="" className="close" onClick={() => onHide()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body scrollable={true}>
          <div className="d-flex align-items-start">
            <div>
              <img
                src={channelData.image ? S3_LATEST_URL + image : DUMMY_IMAGE}
                alt=""
                width={200}
                height={200}
                className="rounded"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="ml-3  w-100">
            <div className="d-flex align-items-start justify-content-between">
              <div>
                <h1 className="mb-0">{channelName}</h1>
                <p className="text-secondary ">
                  {totalSubscribers} Subscribers
                </p>
              </div>
              <div className="d-flex align-items-center">
                <button className="btn btn-secondary px-3 py-2" onClick={()=>setEnableImageEdit(true)}>{channelData.image ? "Update Image" : "Add Image"}</button>
                {channelData.image && !enableImageEdit && <button className="btn btn-light outline px-3 py-2 ml-2" onClick={removeImage}>Remove</button>}
              </div>
            </div>
            {enableImageEdit && <div className="d-flex justify-content-between align-items-center  rounded-lg border p-3 ">
            <Label className="text-dark w-100 ml-2">
              <Input
                type="file"
                className="rounded-lg mt-1"
                onChange={handleFileUpdate}
                key={imagePickerKey}
              />
            </Label>
            <button
              className="btn btn-secondary p-2  ml-2"
              onClick={() => {
                setImageFile(null);
                setImagePickerKey(Math.random());
              }}
            >
              Clear
            </button>
          </div>}
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mt-5">
            <Label className="text-dark w-100">
              Channel Name
              <Input
                type="text"
                value={channelData.name}
                name="name"
                onChange={handleDetailsUpdate}
                className="rounded-lg shadow-sm border"
              />
            </Label>
            <Label className="text-dark w-100 ml-2 pointer">
              Expert Name
              <Input
                type="text"
                value={tipperName}
                readOnly
                onClick={()=>goToUserDetails(tipperId)}
                className="rounded-lg shadow-sm border pointer"
              />
            </Label>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <Label className="text-dark w-100">
              Total Subscribers
              <Input
                type="text"
                readOnly
                value={totalSubscribers}
                className="rounded-lg shadow-sm border"
              />
            </Label>
            <Label className="text-dark w-100 ml-2">
              Total Bots
              <Input
                type="number"
                value={channelData.bots}
                name="bots"
                onChange={handleDetailsUpdate}
                className="rounded-lg shadow-sm border"
              />
            </Label>
          </div>
          <div className="d-flex justify-content-between align-items-center">
          <Label className="text-dark w-100">
              Real subscribers
              <Input
                type="text"
                value={totalSubscribers - bots}
                name="link"
                readOnly
                className="rounded-lg mt-1 shadow-sm border"
              />
            </Label>
          <Label className="text-dark w-100 ml-2">
              Channel Link
              <Input
                type="text"
                value={"sportex.app.link/" +link}
                name="link"
                readOnly
                onChange={handleDetailsUpdate}
                className="rounded-lg mt-1 shadow-sm border"
              />
            </Label>
          </div>
     
            <Label className="text-dark w-100">
              Channel Desciption
              <textarea
                type="text"
                value={channelData.description}
                name="description"
                onChange={handleDetailsUpdate}
                rows={5}
                className="rounded-lg form-control shadow-sm border"
              />
            </Label>
     
          {/* <div className="d-flex justify-content-between align-items-center  rounded-lg shadow-sm border p-3 ">
            <Label className="text-dark w-100 ml-2">
              Channel Image
              <Input
                type="file"
                className="rounded-lg mt-1"
                onChange={handleFileUpdate}
                key={imagePickerKey}
              />
            </Label>
            <button
              className="btn btn-secondary p-2  ml-2"
              onClick={() => {
                setImageFile(null);
                setImagePickerKey(Math.random());
              }}
            >
              Clear
            </button>
          </div> */}

          <div className="d-flex justify-content-between align-items-center mt-3">
            <button
              className="btn btn-secondary w-100"
              onClick={handleChannelDataUpdate}
            >
              Save Changes
            </button>
            <button className="btn btn-danger w-100 ml-2" onClick={onHide}>
              Cancel
            </button>
          </div>

          <p className="text-success">{successMessage}</p>
          <p className="text-danger">{errorMessage}</p>
        </Modal.Body>
      </Modal>
    </>
  );
}
