export const GET_PACKAGES = "admin/tips/package";

export const GET_MATCHES = "admin/tips/matches";

export const GET_AGENT_LIVE_MATCHES = "admin/match/liveMatches/agent";

export const GET_TIPS = "admin/tips";

export const POST_PACKAGE = "admin/tips/package";

export const POST_TIP = "admin/tips/";

export const DELETE_TIP = "admin/tips/";

export const DELETE_PACKAGE = "admin/tips/package";

export const SEND_NOTIFICATIONS = "admin/notification";

export const UPDATE_TIPPER_STATUS = "admin/user/tipperStatus";

export const GET_SOCKET_DATA = "user/match/liveMatchData";

export const GET_MATCH_SQUADS = "admin/match/matchSquad";

export const POST_PLAYING_ELEVEN = "admin/match/playingEleven";

export const PLAYER_IMAGE_BASE_URL =
  "https://sportex-bucket.s3.ap-south-1.amazonaws.com/playerImages/";

export const GET_OVER_SESSION = "admin/match/overSessions";

export const GET_MANAGEMENT_MATCHES = "admin/match/liveMatches";

export const TOGGLE_MATCH = "admin/match/showLiveMatch";

export const GET_SESSION_HISTORY = "admin/match/sessionHistory";

export const PUT_SESSION_HISTORY = "admin/match/sessionHistory";

export const POST_SESSION_HISTORY = "admin/match/sessionHistory";

export const DELETE_SESSION_HISTORY = "admin/match/sessionHistory";

export const GET_LEADERBOARD_LIVE_MATCHES = "admin/match/liveMatches";

export const GET_RECENT_LEADERBOARD_MATCHES =
  "/admin/match/leaderBoard/recentMatches";

export const PUT_MATCH_LEADERBOARD_STATUS = "admin/match/leaderBoard/status";

export const REMOVE_LAMBI_KHADO = "admin/match/removeLambiKhado";

export const UPDATE_REWARD_LIST = "admin/rewards";

export const GET_REWARD_LIST = "admin/rewards";

export const DELETE_REWARD = "admin/rewards";

export const GET_SCORE_MATCHES = "admin/match/getEntityMatches";

export const GET_ODDS_MATCHES = "admin/match/getOddsMatches";

export const POST_ODDS_CONNECTION = "admin/match/addOddsEventId"

export const GET_GEMS_PACKAGES = "admin/gems/package";

export const EDIT_GEMS_PACKAGE = "admin/gems/package";

export const DELETE_GEMS_PACKAGE = "admin/gems/package";

export const ADD_GEMS_PACKAGE = "admin/gems/package";

export const GET_ENTITY_MATCHES = "admin/match/getEntityMatches"

export const SET_TEAM_ODDS = "admin/match/teamNameOdds"

export const UPDATE_USER_ACCOUNT = "admin/user/addCoinDiamonds"

export const GET_GLOBAL_COINS = "admin/dailyBonusCoins"

export const PUT_GLOBAL_COINS = "admin/dailyBonusCoins"

export const FLAG_USER = "admin/teamMember"

export const GET_USER_DETAILS = "admin/user"

export const GET_USER_BET_HISTORY = "admin/user/Bets"

export const GET_USER_BET_MATCHES = "admin/user/BetMatches"

export const UPDATE_USER_COINS = "admin/user/coins"

export const GET_MATCHES_FOR_LEADERBOARD = "admin/match/leaderBoard/matches"

export const GET_MATCH_LOAD = "admin/match/load"

export const TOGGLE_BET_STATUS = "admin/match/betStatus"

export const SUBADMIN = "admin/subAdmin"

export const RESET_SUBADMIN_PASSWORD = "admin/subAdmin/password"

export const GET_PROFILE = "admin/subAdmin/profile"

export const CUSTOM_MESSAGE_FOR_MATCHID = "admin/match/screenText";

export const MATCH_REVIEW = "admin/match/review";

export const LAMBI_KHADO = "admin/match/lambiKhado";

export const FANTASY_TIPS = "admin/fantasy/tips";

export const HOT_PICKS = "admin/fantasy/hotpicks"

export const RISKY_PICKS = "admin/fantasy/riskyPicks";

export const GET_SOCIAL_LINKS = "admin/socialLinks";

export const GET_CHANNELS = "admin/channel/list";

export const CHANNEL= "admin/channel";

export const FAKE  = "admin/channel/fakemessageCount";



//football
export const GET_LEAGUE_LIST = "admin/football/league/list";

export const GET_APP_CONTROLS = "user/auth/appControls"

export const PUT_APP_CONTROLS = "admin/appControls"

export const ADS_BANNER = "admin/banner"

export const GET_BANNER_LIST = "admin/banner/list"

export const SORT_BANNER_ORDER = "admin/banner/order"

export const GET_BANNER_ID = "admin/banner"

export const APP_TOP_BANNER = "admin/appBanner"

export const UPDATE_TOTAL_VOTING= "admin/match/totalVoting"

export const GET_CRICKET_COMPETITIONS = "admin/competition"

export const GET_BET_MATCHES = "admin/match/bets"

export const EXPORT_MATCH_BETS = "admin/match/exportBets"

export const RESET_MATCH_TOSS = "admin/match/resetMatch"



