

export const primary_event = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "0",
  "Ball Start",
  "Wide",
  "No Ball",
  "Wicket",
];

export const expression_events = [
  "delete",
  "enter",
  "d",
  "o",
  "b",
  "s",
  "a",
  "h",
  "c",
  "/",
  "x",
];

export const primaryEventCodes = [49, 50, 51, 52,53, 54, 48, 32, 119, 110, 118,];
export const expressionEventCodes = [
  13, 100, 111, 98, 115, 97, 104, 99, 47, 120, 96,
];

export const wicketEvents = [
  { screenText: "Bowled", ballText: "W" },
  { screenText: "Catch out", ballText: "W" },
  { screenText: "Stumps", ballText: "W" },
  { screenText: "Hit wicket", ballText: "W" },
  { screenText: "LBW", ballText: "W" },
  { screenText: "Run out", ballText: "W" },
  { screenText: "Run out + 1", ballText: "W+1" },
  { screenText: "Run out + 2", ballText: "W+2" },
  { screenText: "Run out + 3", ballText: "W+3" },
];

export const wideEvents = [
  { screenText: "Wide + Stumps", ballText: "Wd+W" },
  { screenText: "Wide + wicket", ballText: "Wd+W" },
  { screenText: "Wide + 1", ballText: "Wd+1" },
  { screenText: "Wide + 2", ballText: "Wd+2" },
  { screenText: "Wide + 3", ballText: "Wd+3" },
  { screenText: "Wide + 4", ballText: "Wd+4" },

];

export const nbEvents = [
  { screenText: "No ball + 1", ballText: "Nb+1" },
  { screenText: "No ball + 2", ballText: "Nb+2" },
  { screenText: "No ball + 3", ballText: "Nb+3" },
  { screenText: "No ball + 4", ballText: "Nb+4" },
  { screenText: "No ball + 6", ballText: "Nb+6" },
];

export const lbEvents = [
  { screenText: "1 run leg byes", ballText: "1LB", run: 1 },
  { screenText: "2 run leg byes", ballText: "2LB", run: 2 },
  { screenText: "3 run leg byes", ballText: "3LB", run: 3 },
  { screenText: "4 run leg byes", ballText: "4LB", run: 4 },
];

//events without balltext
export const matchEvents = [
  "1st Inning",
  "2nd Inning",
  "Match finished",
  "Match start",
  "Drinks break/Timeout",
  "Toss",
  "Match tie",
  "Players on field",
  "Innings break",
  "Power play start",
  "Power play end",
];

export const injuryEvents = [
  "Batsman Injured",
  "Bowler Injured",
  "Fielder Injured",
];

export const checkEvents = [
  "Stumps check",
  "DRS",
  "LBW Check",
  "Bowling review",
  "Batting review",
  "Review Retained",
  "Review Lost",
  "Decision Pending",
  "3rd Umpire",
  "No Ball Check",
  "Catch check",
  "Wide Ball Review",
  "No Ball Review",
  "Run out check",
];

export const extraEvents = [
  "Bowler Stopped",
  "Fielder Niche",
  "Fast bowler",
  "Spinner",
  "MISFIELD",
  "IMPACT PLAYER",
];


//uitility function for panel logic

function getLegbyScore(type) { //get score for legby event and type
  if (type == "LB") return 0;
  else {
    const item = lbEvents.filter((item) => item.screenText == type);
    console.log(item);
    return item[0].run;
  }
}

function getBallText(event, type) { //getting ball text for events and their types
  if (event == "wide") {
    let temp = wideEvents.filter((item) => item.screenText == type);
    return temp[0]?.ballText;
  } else if (event == "wicket") {
    let temp = wicketEvents.filter((item) => item.screenText == type);
    return temp[0]?.ballText;
  } else if (event == "no ball") {
    let temp = nbEvents.filter((item) => item.screenText == type);
    return temp[0]?.ballText;
  } else {
    let temp = lbEvents.filter((item) => item.screenText == type);
    return temp[0]?.ballText;
  }
}

function getScoreForEventWithRuns(event, type, playerType) { //get runs on the event that has events of type "xyz + 1"
  console.log(event, type, "score from event with runs")
  if (event == "legby") {
    return getLegbyScore(type);
  } else if (type == "WD" || type == "NB") {
    return 1;
  } else if (
    type.includes("1") ||
    type.includes("2") ||
    type.includes("3") ||
    type.includes("4") ||
    type.includes("6")
  ) {
    let splitTypeString = type.split("+");
    if ( event == "wide") return Number(splitTypeString[1]) + 1;
    else if (event == "no ball" ){
      if(playerType == "batsman"){
        return Number(splitTypeString[1])
      }else{
        return Number(splitTypeString[1]) + 1;
      }
    }
    else return Number(splitTypeString[1]);
  } else {
    return 0;
  }
}

export function getEventData(event, type) { //get data for particular event like balltext, screentext, etc
  if (event == "wicket") {
    if (type == "") {
      return { ballText: "W", screenText: "Wicket", run: 0 };
    } else {
      return {
        ballText: getBallText("wicket", type),
        screenText: type,
        run: getScoreForEventWithRuns(event, type),
      };
    }
  } else if (event == "run") {
    return {
      ballText: type,
      screenText: type,
      run: Number(type),
    };
  } else if (event == "match") {
    return {
      screenText: type,
      ballText: "",
    };
  } else if (event == "injury") {
    return {
      screenText: type,
      ballText: "",
    };
  } else if (event == "check") {
    return {
      screenText: type,
      ballText: "",
    };
  } else if (event == "extra") {
    return {
      screenText: type,
      ballText: "",
    };
  } else if (event == "wide") {
    return {
      screenText: type == "WD" ? "Wide" : type,
      ballText: type == "WD" ? type : getBallText("wide", type),
      run: getScoreForEventWithRuns(event, type),
    };
  } else if (event == "no ball") {
    return {
      screenText: type == "NB" ? "No Ball" : type,
      ballText: type == "NB" ? type : getBallText("no ball", type),
      run: getScoreForEventWithRuns(event, type),
    };
  } else if (event == "legby") {
    return {
      screenText: type == "LB" ? "Leg By" : type,
      ballText: type == "LB" ? type : getBallText("legby", type),
      run: getScoreForEventWithRuns(event, type),
    };
  } else if (event == "confirming") {
    return {
      screenText: event,
      ballText: "",
    };
  } else if (event == "not out") {
    return {
      screenText: "Not Out",
      ballText: "-",
    };
  } else if (event == "overEnd") {
    return {
      screenText: "Over End",
      ballText: "",
    };
  }
}

export function getScoreFromEvent(event, type, playerType) { //get score for primary events and forwars secondary types for score 
  console.log(event, type, "from score function");
  if (event == "run") {
    return Number(type);
  } else if (
    event == "wide" ||
    event == "no ball" ||
    event == "legby" ||
    event == "wicket"
  ) {
    if (type === "WB") return 1;
    else if (type === "NB") return 1;
    else if ( type === "Wide + Stumps" || type === "Wide + wicket") return 1;
    else return getScoreForEventWithRuns(event, type, playerType);
  } else return 0;
}

export function isWicketLost(event, type) { //checking for loss of wicket returns boolean
  console.log(event, type);
  if (event == "wicket") {
    return true;
  } else if (event == "wide" && (type == "Wide + Stumps" || type == "Wide + wicket")) {
    return true;
  } else {
    return false;
  }
}

export function getOverString(balls) { // convert balls to strings to display
  if (Number(balls) < 6) {
    return "0." + balls;
  } else {
    let extraBalls = Number(balls) % 6;
    let ballsDivisibleBy6 = Number(balls) - extraBalls;
    return Number(ballsDivisibleBy6) / 6 + "." + extraBalls;
  }
}


export function getBallsFromOverString(overString){
  let stringArray = overString.split(".");
  if(stringArray[0] == "0") return stringArray[1];
  else {
    let totalBalls = (Number(stringArray[0]) * 6) + Number(stringArray[1]);
    return `${totalBalls}`;
  }
}

export function isNotOut(type) { // boolean for checking if the event was "not out"
  if (type == "not out") {
    return true;
  } else {
    return false;
  }
}

export function isTrueBall(event) { // function returning boolean if the ball is true or not
  if (
    event == "wide" ||
    event == "no ball" ||
    event == "overEnd" 
    // event == "legby"
  ) {
    return false;
  } else return true;
}

export function getOverNumber(balls) {// extracting the current over number from the over string
  let overString = getOverString(balls);
  let overStringArr = overString.split(".");
  return Number(overStringArr[0]) + 1;
}

export function getCurrentOverTotal(commentaries) { //get the recent over total for display
  let overTotal = 0;
  commentaries = JSON.parse(JSON.stringify(commentaries));
  commentaries = commentaries.reverse();
  const event = "overEnd";
  let f = 1;
  const filteredArray = commentaries.filter((d) => {
    if (d.event == "overEnd") {
      f = 0;
    }
    if (f) {
      return d;
    }
  });
  // console.log(filteredArray);
  filteredArray.forEach((item) => (overTotal = overTotal + item.run));
  return overTotal;
}

export function shouldBallAdded(isNewBall, isTrueBall, eventName, type) { // boolean check that adds a new ball to total balls if true
  console.log({isNewBall, isTrueBall, eventName, type}, "should ball added batsman")
  if(type == "batsman"){
    if (isNewBall && !isTrueBall && eventName == "no ball") return true;
    else if (!isNewBall && !isTrueBall && eventName == "no ball") return true;
    else if (isNewBall && isTrueBall) return true;
    else if (!isNewBall && isTrueBall) return true;
    else if (!isNewBall && !isTrueBall) return false;
    else if (isNewBall && !isTrueBall) return false;
    else return true;
  }else{
    if (isNewBall && !isTrueBall) return false;
    else if (isNewBall && isTrueBall) return true;
    else if (!isNewBall && isTrueBall) return true;
    else return false;
  }
}

export function shouldWicketAddedToBowler(isWicketTrue, notOut, eventName){
   if(isWicketTrue && !notOut && !eventName.includes("Run out")) return true;
   else if(!isWicketTrue || notOut) return false;
   else return false;

}

export function getBatsmanData(playerToBeReplaced, newPlayer, playerNumber) {//getting new batsman data accoring to strike
  if (playerToBeReplaced) {
    let upcomingPlayerData = {
      ...playerToBeReplaced,
      name: newPlayer.name,
      playerId: newPlayer.playerId,
      balls: "0",
      runs: "0",
      fours: "0",
      sixes: "0",
      sr: "0",
    };
    return upcomingPlayerData;
  } else {
    let upcomingPlayerData = {
      onStrike: playerNumber == 1 ? true : false,
      name: newPlayer.name,
      playerId: newPlayer.playerId,
      balls: "0",
      runs: "0",
      fours: "0",
      sixes: "0",
      sr: "0",
    };
    return upcomingPlayerData;
  }
}

export function getNextBowlerObject(bowler) { // getting new bowler data
  console.log(bowler, "bowler from functions")
  let bowlerObject = {
    name: bowler.name,
    playerId: bowler.playerId,
    overs: bowler?.bowlingData?.overs ?? 0,
    runs: bowler?.bowlingData?.runs ?? 0,
    wkts: bowler?.bowlingData?.wkts ?? 0,
  };
  return bowlerObject;
}

export function getSlicedPlayerName(playerName){
  if(playerName && playerName.length > 7){
    return playerName.slice(0, 8) + "..."
  }else{
    return "____ "
  }
}

export function getLastballObject(commentaries){
  commentaries = JSON.parse(JSON.stringify(commentaries));
  if(commentaries[commentaries.length - 1].event == "overEnd") {
     let lastBallObject = commentaries[commentaries.length - 2];
     return lastBallObject;
  }else return undefined;
}


export function getBatsmanOnStrike(currentBatsmen, runOnLastBall){
  let objectCopy = JSON.parse(JSON.stringify(currentBatsmen));
  console.log(currentBatsmen, "run from function file")
  objectCopy.batsman1.onStrike = !currentBatsmen?.batsman1?.onStrike;
  objectCopy.batsman2.onStrike = !currentBatsmen?.batsman2?.onStrike;
  console.log(objectCopy, "after strike reverse");
  if(runOnLastBall == 0 || runOnLastBall == 2 || runOnLastBall == 4 || runOnLastBall == 6){
     if(objectCopy?.batsman1?.onStrike) return objectCopy?.batsman1;
     else return objectCopy?.batsman2;
  }
  else if(runOnLastBall == 1 || runOnLastBall == 3){
    if(objectCopy?.batsman1?.onStrike) return objectCopy?.batsman2;
     else return objectCopy?.batsman1;
  }
}

export function shouldRunAddedToBatsman(isTrueBall, event, type){
  if(isTrueBall && event == 'legby'){
    return false;
  } else if(!isTrueBall && event == "no ball"){
    if(type == "NB") return false;
    else return true;
  }else{
    return isTrueBall;
  }
}