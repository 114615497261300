import React from 'react'
import { Card } from 'react-bootstrap'
import { AWS_COMPETITION_BASE_URL } from '../../pages/test'

function CompetitionCard({title, dateStart, dateEnd, status, telecast, logo, id, handleSelection}) {


    function handleEditCompetition(){
      handleSelection({
        logo,
        telecast,
        id
      })
    }
  return (
    <Card className='competition-card row p-3 d-flex flex-row align-items-center justify-content-between'>
       <div className='d-flex align-items-center col-4'>
        {!logo && <img src="https://static.vecteezy.com/system/resources/previews/027/210/359/non_2x/shield-logo-vector.jpg" alt="" width={80} height={80} />}
        {logo && <img src={AWS_COMPETITION_BASE_URL + logo} width={80} height={80}/>}
        <div>{title}</div> 
       </div>
       
       <div className='d-flex flex-column align-items-start col-2'>
        <label>Schedule</label>
        <div>{dateStart} to {dateEnd}</div>
       </div>
       <div className='d-flex flex-column align-items-start col-2'>
        <label>Status</label>
        <div>{status}</div>
       </div>
       <div className='d-flex flex-column align-items-start col-2'>
        <label>Telecast</label>
        <div>{telecast ? telecast : "N/A"}</div>
       </div>
       <button className='btn btn-dark p-2 px-3' onClick={handleEditCompetition}>Edit</button>
    </Card>
  )
}

export default CompetitionCard