import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import MultiInputListComponent from "../multiInputList/MultiInputListComponent";

function MatchResultModal({ show, onHide, matchName, defaultSettings, showUserModal }) {
  return (
    <Modal
      size="md"
      className="fade vertically-centered"
      show={show}
      centered
      onHide={onHide}
    >
      <Modal.Header>
        <Modal.Title>Result</Modal.Title>
        <Button variant="" className="close" onClick={() => onHide()}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        {/* <div className="d-flex align-items-center"> */}
        <div className="w-100 d-flex align-items-center justify-content-between mb-3">
        <div><strong>Rank</strong></div>
        <div><strong>User</strong></div>
        </div>
        <div className="w-100 d-flex align-items-center justify-content-between mb-3">
        <div>1</div>
        <div className="pointer" onClick={showUserModal}><Link>user_133</Link></div>
        </div>
        <div className="w-100 d-flex align-items-center justify-content-between mb-3">
        <div>2</div>
        <div className="pointer"><Link>user_135</Link></div>
        </div>
        <div className="w-100 d-flex align-items-center justify-content-between mb-3">
        <div>3</div>
        <div>user_135</div>
        </div>
        <div className="w-100 d-flex align-items-center justify-content-between mb-3">
        <div>4</div>
        <div>user_136</div>
        {/* </div> */}

        
          
        </div>
      </Modal.Body>
    </Modal>
  );
}


export default MatchResultModal