import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
export default function ScoreInputModal({
  show,
  onHide,
  type,
  balls,
  score,
  updatePlayer,
  currentBatsmen,
  sendUpdatedPlayerData,
  title
}) {
  function handleEventSend() {}

  function updatePlayerBalls(value){
    if(type == 1){
       updatePlayer({
          ...currentBatsmen,
          batsman1:{
           ...currentBatsmen?.batsman1,
           balls: value
          }
       })
    }else{
       updatePlayer({
           ...currentBatsmen,
           batsman2:{
            ...currentBatsmen?.batsman2,
            balls: value
           }
        })
    }
   }
   function updatePlayerScore(value){
       if(type == 1){
          updatePlayer({
             ...currentBatsmen,
             batsman1:{
              ...currentBatsmen?.batsman1,
              runs: value
             }
          })
       }else{
          updatePlayer({
              ...currentBatsmen,
              batsman2:{
               ...currentBatsmen?.batsman2,
               runs: value
              }
           })
       }
   }

function submitData(){
console.log("calling function")
   sendUpdatedPlayerData();
   onHide();
}      
  return (
    <>
      <Modal
        size="sm"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => onHide()}
      >
        <Modal.Header>
          <Modal.Title>Edit {title}</Modal.Title>
          <Button variant="" className="close" onClick={() => onHide()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center flex-wrap form-group">
            <input
              type="text"
              className="custom-text-input"
              placeholder="score"
              value={type == 1 ? currentBatsmen?.batsman1?.runs : currentBatsmen?.batsman2?.runs}
              onChange={(e) => {
                updatePlayerScore(e.target.value);
              }}
            />
            <input
              type="text"
              placeholder="balls"
              className="custom-text-input"
              value={type == 1 ? currentBatsmen?.batsman1?.balls : currentBatsmen?.batsman2?.balls} 
              onChange={(e) => {
                updatePlayerBalls(e.target.value);
              }}
            />
            <button
              className="btn btn-warning m-auto"
              type="button"
              onClick={submitData}
            >
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
