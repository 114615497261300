import { DatePicker } from "@y0c/react-datepicker";
import React, { useEffect } from "react";
import { useState } from "react";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { useFetchData } from "../../constants/hooks";
import {
  getLeaderBoardLiveMatches,
  getLeaderBoardMatches,
  getLeaderBoardRecentMatches,
} from "../../services/asyncFunctions";
import ActiveBannerImageTable from "../components/ActiveBannerImageTable";
import DefaultMatchLeaderboard from "../components/DefaultMatchLeaderboard";
import MultiInputListComponent from "../components/multiInputList/MultiInputListComponent";
import PoolingStatisticsTable from "../components/PoolingStatisticsTable";
import RecentMatchesLeaderBoardTable from "../components/RecentMatchesLeaderBoardTable";

export default function MatchLeaderboard() {
  const [liveMatchData, setLiveMatchData] = useState(null);
  const [upcomingData, setUpcomingData] = useState(null);
  const [resultData, setResultData] = useState(null);
  const [selectedMatchTab, setSelectedMatchTab] = useState("live");
  const [isLoading, setIsLoading] = useState(false);

  function handleTabChange(tab) {
    setSelectedMatchTab(tab);
    if (tab == "live") getLiveMatches();
    else if (tab == "upcoming") getUpcomingMatches();
    else getRecentMatches();
  }
  async function getLiveMatches() {
    try {
      let result = await getLeaderBoardMatches("live");
      console.log(result.data.data);
      setLiveMatchData(result.data.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getUpcomingMatches() {
    try {
      let result = await getLeaderBoardMatches("upcomming");
      console.log(result.data.data);
      setUpcomingData(result.data.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getRecentMatches() {
    try {
      let result = await getLeaderBoardMatches("recent",);
      console.log(result.data.data, "results");
      setResultData(result.data.data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getLiveMatches();
  }, []);

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>Leaderboard</Card.Title>
        </Card.Header>
        <Card.Body>
          <Tab.Container defaultActiveKey="matches">
            <Nav as="ul" className="nav-pills mb-4 light">
              <Nav.Item as="li">
                <Nav.Link eventKey="matches">Matches</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="matchSettings">Settings</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="pt-2">
              <Tab.Pane eventKey="matches">
                <div className="d-flex align-items-center">
                  <div
                    className={`btn ${
                      selectedMatchTab == "live" && "btn-secondary"
                    } border mr-1`}
                    onClick={() => handleTabChange("live")}
                  >
                    Live
                  </div>
                  <div
                    className={`btn ${
                      selectedMatchTab == "upcoming" && "btn-secondary"
                    } border mr-1`}
                    onClick={() => handleTabChange("upcoming")}
                  >
                    Upcoming
                  </div>
                  <div
                    className={`btn ${
                      selectedMatchTab == "result" && "btn-secondary"
                    } border mr-1`}
                    onClick={() => handleTabChange("result")}
                  >
                    Result
                  </div>
                </div>
                {selectedMatchTab == "live" && (
                  <div className="animate__animated animate__fadeIn">
                    {isLoading ? (
                      <h2 className="text-center">Loading...</h2>
                    ) : (
                      <DefaultMatchLeaderboard
                        refetch={getLiveMatches}
                        tableData={liveMatchData}
                      />
                    )}
                  </div>
                )}

                {selectedMatchTab == "upcoming" && (
                  <div className="animate__animated animate__fadeIn">
                    {isLoading ? (
                      <h2 className="text-center">Loading...</h2>
                    ) : (
                      <DefaultMatchLeaderboard
                        refetch={getUpcomingMatches}
                        tableData={upcomingData}
                      />
                    )}
                  </div>
                )}

                {selectedMatchTab == "result" && (
                  <div>
                    <div className="row mt-4">
                      <div className="w-100 d-flex align-items-center ml-3">
                        <div className="d-flex align-items-center form-group mr-3 ">
                          <strong className="mr-3">Date</strong>
                          <input type="date" className="form-control" />
                        </div>
                        <div className="form-group d-flex align-items-center">
                          <label className="mb-2 mr-2">
                            <strong className="">Search</strong>
                          </label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                    </div>
                    <div className="animate__animated animate__fadeIn">
                      {isLoading ? (
                        <h2 className="text-center">Loading...</h2>
                      ) : (
                        <RecentMatchesLeaderBoardTable
                          tableData={resultData}
                        />
                      )}
                    </div>
                  </div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="matchSettings">
                <div className="w-100">
                  <div className="d-flex align-items-center">
                    <div>
                      <label className="mb-2 ">
                        <strong className="">Total Winners</strong>
                      </label>
                      <input type="text" className="custom-text-input" />
                    </div>
                  </div>
                  <div className="reward-list-container">
                    <h4>Create Rewards for different ranks</h4>
                    <MultiInputListComponent />
                  </div>
                  <div className="w-100 d-flex align-item-center justify-content-center">
                    <button className="btn btn-warning w-50" type="button">
                      Submit
                    </button>
                  </div>
                </div>
                {/* <ViewMatchLeaderboardeTable /> */}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>
    </>
  );
}
