import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import { getAgentLiveMatches, getMatches } from "../../services/asyncFunctions";
import { setAgentMatchId } from "../../store/actions/AuthActions";

function AgentMatchList() {
  const [matchList, setMatchList] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const dispatch = useDispatch();

  async function fetchMatches() {
    console.log("first");
    // setLoadingMatches(true);
    let result = await getAgentLiveMatches();
    console.log(result);
    let matchList = result?.data?.data.map((item) => {
      return {
        label: item.title,
        value: item.matchId,
      };
    });
    setMatchList(matchList);
    // setLoadingMatches(false);
  }


 

  function handleMatchSelect(matchId) {
    dispatch(setAgentMatchId(matchId));
    localStorage.setItem("agentMatchId", matchId);
  }

  useEffect(() => {
    localStorage.removeItem("agentMatchId");
    // localStorage.setItem('agentMatchId', '60947');

    fetchMatches();
  }, []);
  return (
    <Card>
      <Card.Header>
        <Card.Title>Match Agent</Card.Title>
      </Card.Header>
      <Card.Body>
        <form
          onSubmit={(e) => e.preventDefault}
          className="d-flex flex-column align-items-start pb-1 w-100"
        >
          <div className="w-75 mb-2">Select Match</div>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Select
              options={matchList}
              className="w-100 flex-grow-1"
              onChange={(match) => handleMatchSelect(match.value)}
              required
            />
            <Link to="agent-match-screen">
              <button
                className="form-control btn bg-primary text-white ml-3"
                style={{ maxWidth: "200px" }}
              >
                Enter
              </button>
            </Link>
          </div>
        </form>
      </Card.Body>
    </Card>
  );
}

export default AgentMatchList;
