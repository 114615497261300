
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { getAgentLiveMatches, toggleBetStatusForMatch } from '../../services/asyncFunctions';
import BetsToggleCard from '../components/cards/BetsToggleCard';

function MarkMatchComplete() {
    const [matchData, setMatchData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);



async function fetchLiveMatches(){
    setIsLoading(true);
    try{
        let result = await getAgentLiveMatches();
        console.log(result);
        setMatchData(result?.data?.data);
    }catch(error){
        toast.error(error?.response?.data?.message);
    }finally{
        setIsLoading(false);
    }
}


async function toggleMatchbetStatus(matchId, status){
    setIsLoading(true);
    try{
      let response = await toggleBetStatusForMatch({matchId,isBetActive: status})
      if(response.status == 200){
        toast.success("Status updated");
      }
      fetchLiveMatches();
    }catch(error){
        toast.error(error?.response?.data?.message);
    }finally{
        setIsLoading(false);
    }
}

useEffect(()=>{
    fetchLiveMatches();
},[])
  return (
    <Card className='p-3'>
     <h1 className='mb-2'>Match Status</h1>

     {isLoading && <h3 className='text-center'>Loading...</h3>}
     {!isLoading && matchData && matchData.map(item => {
       return <BetsToggleCard key={item?._id} toggleFunction={toggleMatchbetStatus} betStatus={item?.isBetActive} date={item?.date_start} title={item?.title} matchId={item?._id}/>
     }) }
    </Card>
  )
}

export default MarkMatchComplete