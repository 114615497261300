import React from "react";
import style from "./loader.module.css";

function Loader({ isLoading }) {
  return (
    <div
      className={`${style["overlay"]}`}
      style={{ display: isLoading ? "block" : "none" }}
    >
      <div className={`${style["overlay__inner"]}`}>
        <div className={`${style["overlay__content"]}`}>
          <span className={`${style["spinner"]}`}></span>
        </div>
      </div>
    </div>
  );
}

export default Loader;
