import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { CSVLink } from "react-csv";
import moment from "moment";
import { getAllBetsForExport } from "../../../services/asyncFunctions";


const headers = [
    { label: "USERTYPE", key: "userType" },
    { label: "USERNAME", key: "userName" },
    { label: "IS_SESSION_BET", key: "isSessionBet" },
    { label: "TEAM/SESSION TITLE", key: "title" },
    { label: "STAKE", key: "stake" },
    { label: "DATE", key: "date" },
    { label: "PROFIT/LOSS", key: "profit" },
    {label: "ODD SCORE", key: "oddScore"},
    { label: "TYPE", key: "type" },
    { label: "RESULT", key: "result" },
  ];



export default function ExportMatchBets({ show, close, matchId }) {
const [data, setData] = useState(null);
const [isLoading, setIsLoading] = useState(true);



async function getAllMatchBetsToExport(){
    try{
        const response = await getAllBetsForExport(matchId);
        console.log(response);
        setData(response.data.data);
    }catch(error){
        console.log(error);
    }finally{
        setIsLoading(false);
    }
}


useEffect(()=>{
  if(!show){
    setData(null);
    setIsLoading(true);
  }
  if(show){
    getAllMatchBetsToExport();
  }
},[show])

  return (
    <>
      <Modal
        size="lg"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => close()}
      >
        <Modal.Header>
          <Modal.Title>Exporting Users</Modal.Title>
          <Button variant="" className="close" onClick={() => close()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center flex-column">
            {isLoading && <h4>Loading...</h4>}
            {!isLoading && data && data.length === 0 && <p>There is no data to export</p>}
            {!isLoading && data && data.length > 0 && <p>Exporting might take some time</p>}
            {data && data.length > 0 &&<button className="btn btn-primary" onClick={close}>
             <CSVLink data={data} filename="users-listing" headers={headers} className="text-white" > EXPORT</CSVLink>
            </button>}
            {(!data || data.length === 0) && <button className="btn btn-primary" disabled>Export</button>}

            </div>
          </Modal.Body>
      </Modal>
    </>
  );
}
