import React, { useState } from 'react'
import TextMessage from './TextMessage';
import moment from 'moment';

function MessageComponent({ type, text, isMyMessage, createdAt, isLocalMessage, senderType }) {
    const [isHovering, setIsHovering] = useState(false);
    function getMessageElementForType() {
      if (type == "Text") {
        return <TextMessage text={text} />;
      }else{
        return null;
      }
    }
    return (
      <div className={`d-flex align-items-center mb-3`}>
      {/* {} */}
      <div
        className={`message-base shadow-sm  ${
          isMyMessage
            ? "my-message-border-radius"
            : "message-border-radius"
        } animate__animated ${!isLocalMessage && 'animate__zoomI'} animate__faster `}
        style={{ backgroundColor: isMyMessage ? "white" : "#D8F7FF" }}
      >
      <div className="text-gray-500 message-time text-right font-semibold font-6 mb-1 animate__animated animate__fadeIn">{moment(createdAt).format('LT')}</div>
        {getMessageElementForType()}
      </div>
        {/* {isMyMessage && isLocalMessage && <img src={Clock} alt="" width={10} />} */}
      </div>
    );
  }
  
  export default MessageComponent;