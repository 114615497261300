import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import addPhoto from "../../pages/test";
export default function EditGemsModal({
  show,
  onHide,
  submitFunction,
  selectedPackage
}) {
    const [formData, setFormData] = useState({
        name: '',
        gems: '',
        packageId: '',
        amount:'',
        image:""
    })
    const [imageFile, setImageFile] = useState([]);


    function handleFormChange(e){
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }
   

async function uploadPhoto(e){
    e.preventDefault();
    if(imageFile.length == 0) submitData();
    try{
      let response = await addPhoto(imageFile[0], "packageImages");
      console.log(response);
      if (response.imageName) {
        submitData(response.imageName);
      }else{
        return;
      }
    }catch(e){
console.log(e)
    }
    
  }      

function submitData(imageUrl = ""){
  if(imageUrl ){
    submitFunction({...formData,image: imageUrl});
  }else{
    submitFunction(formData);
  }
   onHide();
} 

useEffect(()=>{
  setFormData({
    name: selectedPackage?.name,
    gems: selectedPackage?.gems,
    packageId: selectedPackage?._id,
    amount:selectedPackage?.amount,
    image: selectedPackage?.image
})
},[show])
  return (
    <>
      <Modal
        size="sm"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => onHide()}
      >
        <Modal.Header>
          <Modal.Title>Package management</Modal.Title>
          <Button variant="" className="close" onClick={() => onHide()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={uploadPhoto} className="d-flex align-items-center flex-wrap form-group">
            <input
              type="text"
              className="custom-text-input"
              placeholder="Package Name"
              value={formData.name}
              name="name"
              onChange={handleFormChange}
            />
            <input
              type="number"
              placeholder="Number of Diamonds"
              className="custom-text-input"
              value={formData.gems}
              name="gems"
              onChange={handleFormChange}
            />
            <input
              type="number"
              placeholder="Cost"
              className="custom-text-input"
              value={formData.amount}
              name="amount"
              onChange={handleFormChange}
            />
            <input type="file" className="mt-3 mb-3" accept="image/*" onChange={(e) => setImageFile(e.target.files)}/>

            <button
              className="btn btn-warning m-auto"
            >
              Submit
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
