import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container, NavItem } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { injuryEvents } from "../../../constants/agentEvents";

export default function InjuryEvents({ injuryShow, injuryClose, actionFunction }) {
  let errorsObj = { userName: "", email: "", phoneNumber: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [apiError, setApiError] = useState("");
  const [loadingMatches, setLoadingMatches] = useState(false);
  const [matchList, setMatchList] = useState([]);
 
  function handleItemSelect(data){
    actionFunction(data);
    injuryClose();
  }
  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}

      <Modal
        size="lg"
        className="fade vertically-centered"
        show={injuryShow}
        centered
        onHide={() => injuryClose()}
      >
        <Modal.Header>
          <Modal.Title>Injury Events</Modal.Title>
          <Button variant="" className="close" onClick={() => injuryClose()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        {loadingMatches === false && (
          <Modal.Body>
            <div className="d-flex align-items-center flex-wrap form-group">
              {injuryEvents.map((item) => (
                <div key={item} className="modal-event btn btn-secondary" 
          
                onClick={()=> handleItemSelect(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}
