import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { getNextBowlerObject } from "../../../constants/agentEvents";
import { PLAYER_IMAGE_BASE_URL } from "../../../services/endpoints";
export default function BowlerModal({
  show,
  onHide,
  playerList,
  handleBowlerSelection,
  lastBowler
}) {

  function getFilteredBowlerList(){
    if(playerList?.length){
        let filteredList = playerList.filter(item => item.playerId != lastBowler?.playerId);
        return filteredList;
    }else return [];
    
  }
    
  function getPlayerImage(playerName){
    return PLAYER_IMAGE_BASE_URL + playerName.toLowerCase().split(" ").join("") + ".pngs"
  }

  function handlePlayerSelect(player){
    let nextBowlerObject = getNextBowlerObject(player)
    handleBowlerSelection(nextBowlerObject)
    onHide();
  }

  return (
    <>
      <Modal
        size="md"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => onHide()}
      >
        <Modal.Header>
          <Modal.Title>Pick A Bowler</Modal.Title>
          <Button variant="" className="close" onClick={() => onHide()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body scrollable={true}>
          {getFilteredBowlerList().map(item => <div className={`player-select-button ${item.isSubstitute == true && 'substitute-border'}`} onClick={() => handlePlayerSelect(item)}>
            {/* <img src={getPlayerImage(item.name)} alt="" width={50} height={50} className="rounded"  /> */}
         <div className='d-flex flex-column align-items-center'>
          <p className={`m-0 player-name `}>{item.name}</p>
          <p className={`m-0 player-role `}>{item.role}</p>
         </div>
          </div>)}
         
        </Modal.Body>
      </Modal>
    </>
  );
}
