import { connect, useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import MessageBox from "../components/MessageBox";
import { loadingToggleAction } from "../../store/actions/AuthActions";
import ImageIcon from "../../images/image.png";
import AudioIcon from "../../images/sound-bars.png";
import { AudioRecorder } from "react-audio-voice-recorder";

import {
  createTip,
  deleteTip,
  getPackageDetails,
} from "../../services/asyncFunctions";
import addPhoto, { addAudio } from "./test";

function PerTipperRoom(props) {
  const dispatch = useDispatch();
  let packageId = localStorage.getItem("packageId");
  const [tipsList, setTipsList] = useState([]);
  const [packageData, setPackageData] = useState({});
  const [tipText, setTipText] = useState("");
  const imageRef = useRef();
  // const audioRef = useRef();

  async function fetchPackageData() {
    dispatch(loadingToggleAction(true));
    try {
      let result = await getPackageDetails(packageId);
      console.log(result);
      setPackageData(result.data.data);
      setTipsList(result.data.data.tips);
      dispatch(loadingToggleAction(false));
    } catch (e) {
      dispatch(loadingToggleAction(false));
    }
  }

  async function createTipLocal(tipImage = "", tipAudio = "") {
    let payload = {
      packageId,
      tip: tipText,
      image: tipImage,
      audio: tipAudio,
    };
    console.log(payload);
    let result = await createTip(payload);
    console.log(result);
    setTipText("");
    fetchPackageData();
  }

  async function deleteTipLocal(tipId) {
    let result = await deleteTip(packageId, tipId);
    console.log(result);
    fetchPackageData();
  }

  async function uploadImage(file) {
    dispatch(loadingToggleAction(true));
    let response = await addPhoto(file, "tipsImages");
    console.log(response);
    if (response.imageName) {
      createTipLocal(response.imageName);
    }
    dispatch(loadingToggleAction(false));
  }

  function clickImagePicker() {
    imageRef.current.click();
  }

  const uploadAudio = async (blob) => {
    dispatch(loadingToggleAction(true));
    let result = await addAudio(blob, "tipsAudios");
    if (result?.fileName) {
      createTipLocal("", result.fileName);
    }
    dispatch(loadingToggleAction(false));
    console.log(result);
  };

  useEffect(() => {
    fetchPackageData();
  }, []);
  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <Link to={"user-management"} className="pointer">
            {packageData?.tipper?.userName}
          </Link>
        </Card.Title>
      </Card.Header>
      <Card.Body className="p-2">
        <div className="chat-container d-flex align-items-center flex-lg-row flex-column">
          <div className="pertipper-left flex-grow-1 border-right border-dark p-2 h-100">
            <div className="d-flex flex-column  align-items-start">
              <div className="row mb-3 w-100">
                <div className="col font-weight-bold">Match Title</div>
                <div className="col text-right">{packageData?.matchTitle}</div>
              </div>
              <div className="row mb-3 w-100">
                <div className="col font-weight-bold">Price</div>
                <div className="col text-right">{packageData?.dimonds}</div>
              </div>
              <div className="row">
                <div className="col-12 font-weight-bold mb-3">Description</div>
                <textarea
                  className="form-control ml-3 flex-grow-1 border-dark w-100"
                  rows={10}
                  value={packageData?.description}
                  readOnly
                  placeholder=""
                ></textarea>
              </div>
            </div>
          </div>
          <div className="pertipper-right flex-grow-1 h-100 position-relative">
            <div className="tips-container w-100">
              {tipsList.map((item) => (
                <MessageBox
                  message={item?.tip}
                  image={item?.image}
                  audioURL={item?.audio}
                  handleDelete={deleteTipLocal}
                  tipId={item?._id}
                />
              ))}
            </div>
            <div className="d-flex align-items-center chat-input-container p-2">
              <input
                type="text"
                placeholder="Enter tip"
                className=" form-control border-dark chat-input"
                onChange={(e) => setTipText(e.target.value)}
              ></input>
              <input
                type="file"
                name="imageFile"
                accept="image/*"
                ref={imageRef}
                className="d-none"
                onChange={(e) => uploadImage(e.target.files[0])}
              />
              <AudioRecorder onRecordingComplete={uploadAudio} />

              <button
                type="button"
                className=" btn border"
                onClick={clickImagePicker}
              >
                <img src={ImageIcon} width={20} />
              </button>
              {/* <button type="button" className=" btn border">
                <img src={AudioIcon} width={20} />
              </button> */}
              <button
                className=" btn btn-primary"
                onClick={() => createTipLocal()}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    currentPackageId: state.auth.currentPackageId,
  };
};

// export default PerTipperRoom;

export default withRouter(connect(mapStateToProps)(PerTipperRoom));
