import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function UserBetDetailsModal({ show, onHide, userId, matchId }) {
  return (
    <Modal
      size="lg"
      className="fade vertically-centered"
      show={show}
      centered
      onHide={onHide}
    >
      <Modal.Header>
        <Modal.Title>Bets By User user_1234</Modal.Title>
        <Button variant="" className="close" onClick={() => onHide()}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 mb-3 border shadow-sm p-3 rounded">
          <div>Team:    India</div>
          <div>Type:    Lay</div>
          <div>Profit:    2000</div>
          <div>coins:     10000</div>
        </div>
        <div className="w-100 mb-3 border shadow-sm p-3 rounded">
          <div>Team:    India</div>
          <div>Type:    Lay</div>
          <div>Profit:    2000</div>
          <div>coins:     10000</div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UserBetDetailsModal