import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import toast from "react-hot-toast";
import { getOddsMatches } from "../../../services/asyncFunctions";
import Select from "react-select";

export default function MatchTeamOdds({
  show,
  onHide,
  teams,
  oddsData,
  submitFunc,
}) {
  const [loadingMatches, setLoadingMatches] = useState(false);
  const [oddsMatches, setOddsMatches] = useState([]);
  const [payload, setPayload] = useState([
    { selectionId: "", teamId: "", teamName: "" },
    { selectionId: "", teamId: "", teamName: "" },
  ]);

  function handleClick(e) {
    e.preventDefault();
    submitFunc(payload);
    onHide();
  }

  function handleTeamSelect(selectionId, teamName, teamId, index) {
    const newPayload = [...payload];
    newPayload[index] = { selectionId, teamId, teamName };
    setPayload(newPayload);
  }
    
  return (
    <>
      <Modal
        size="lg"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => onHide()}
      >
        <Modal.Header>
          <Modal.Title>Match Odds to teams</Modal.Title>
          <Button variant="" className="close" onClick={() => onHide()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        {loadingMatches === false && (
          <Modal.Body className="w-100">
            <form
              onSubmit={handleClick}
              className="d-flex align-items-center flex-wrap form-group w-100"
            >
              <div className="d-flex align-items-center w-100 mb-2">
                <div className="w-100">{teams ? teams[0].name : "----"}</div>
                {oddsData && oddsData[0]?.checkValue ? 
                "Team already matched" :
                <Select
                  options={oddsData}
                  className="w-100 flex-grow-1"
                  onChange={(odd) =>
                    handleTeamSelect(odd.value + "", teams[0].name, teams[0].teamId, 0)
                  }
                  required
                />
              }
              </div>
              <div className="d-flex align-items-center w-100">
                <div className="w-100">{teams ? teams[1].name : "----"}</div>
                {oddsData && oddsData[1]?.checkValue ? 
                "Teams already matched" :
                <Select
                  options={oddsData}
                  className="w-100 flex-grow-1"
                  onChange={(odd) =>
                    handleTeamSelect(odd.value + "", teams[1].name, teams[1].teamId, 1)
                  }
                  required
                />
}
              </div>
              {oddsData && !oddsData[0]?.checkValue && <button className="btn btn-warning w-100 mt-3">Submit</button>}
            </form>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}
