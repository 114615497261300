import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { wideEvents } from "../../../constants/agentEvents";
import { CSVLink } from "react-csv";
import moment from "moment";




export default function ConfirmDataExport({ show, close, data, headers }) {

const [filteredData, setFilteredData] = useState(data);

  useEffect(()=>{
if(show){
   let filtered = data.map((item)=> {
    return {
      userName: item?.userName,
      email: item?.email,
      phoneNumber: item?.phoneNumber,
      creationDate: moment(item?.createdAt).format('DD-MM-YYYY'),
      creationTime: moment(item?.createdAt).format('LT'),
      stripeCustomerId: item?.stripeCustomerId,
      diamonds: item?.diamonds,
      userType: item?.userType == "user" ? "Fan" : "Tipper",
      isVerified: item?.isVerified,
      countryCode: item?.countryCode,
      sportExCoins: item?.sportExCoins,
      profileImage: item?.profileImage
    }
   })
   setFilteredData([...filtered]);
}
  },[show])
  return (
    <>
      <Modal
        size="lg"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => close()}
      >
        <Modal.Header>
          <Modal.Title>Exporting Users</Modal.Title>
          <Button variant="" className="close" onClick={() => close()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center flex-column">
            <div>This might take a few seconds, please stay on the page!</div>
            <button className="btn btn-primary" onClick={close}>
            <CSVLink data={filteredData} filename="users-listing" headers={headers} className="text-white" > EXPORT</CSVLink>
            </button>
            </div>
          </Modal.Body>
      </Modal>
    </>
  );
}
