import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import Select from 'react-select';
import { tabCodes, toastError } from '../PluginsMenu/Nestable/utils';
// import './CustomModal.css'; // Import CSS file for styling

const ResetSubadminPassword = ({ show, onHide, updateUserPassword }) => {



  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
        ...formData,
    }
    updateUserPassword(payload, "password");
    onHide();
    setError(null);
    setFormData({
        password: '',
      })
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update Password</Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-monospace'>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="name">
            <Form.Label className='text-dark font-weight-bold'>New Password</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter new password"
              name="password"
              required
              value={formData.password}
              onChange={handleInputChange}
            />
          </Form.Group>
{/* { error &&  <Alert key={"danger"} variant={"danger"}>
          {error}
        </Alert>} */}
          <Button variant="outline-secondary" className='w-100 ' type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ResetSubadminPassword;
