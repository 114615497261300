import React, { useEffect, useRef } from 'react'
import PerfectScrollbar from "react-perfect-scrollbar";
import MessageComponent from './MessageComponent';
import { Spinner } from 'react-bootstrap';


function MessageListScroll({messageList, isChatFetching}) {

  const bottomRef = useRef(null);

    function getMessageStyles(sender, senderType = "") {
        if (senderType === "doctor") {
          return {
            styles: {
              justifyContent: "flex-end"
            },
            isMyMessage: true,
          };
        } else {
          return {
            styles: {
              justifyContent: "flex-start",
            },
            isMyMessage: false,
          };
        }
      }
      function isProfilePicShown(fromId, senderType) {
        if (!getMessageStyles(fromId, senderType).isMyMessage) {
          return true;
        } else return false;
      }


      function checkIsFirstInTheSeries(
        fromIdLastMessage,
        index,
        senderType
      ) {
        if (index === 0) return true;
        else {
          let secondLastMessage = messageList[index == 0 ? index : index - 1];
          if (secondLastMessage.sender === fromIdLastMessage) {
            if (secondLastMessage?.senderType == senderType) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      }

    // useEffect(()=>{
    //   bottomRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    // },[messageList])

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        bottomRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 0);
  
      return () => clearTimeout(timeoutId);
    }, [messageList]);
  return (
    <div className='message-list-scroll'>
           {messageList?.length > 0 &&
                      [...messageList].map((item, index) => (
                        <>
                          {/* { <ChatTimeSeperator timeString={HelperFunctions.checkIsMessageFromSameDate(item.createdAt)}/>} */}
                          <div
                            key={item._id}
                            style={
                              getMessageStyles(item.sender, item?.senderType)
                                .styles
                            }
                            className={`d-flex align-items-start  gap-2 ${
                              isProfilePicShown(
                                item.sender,
                                item?.senderType
                              ) &&
                              !checkIsFirstInTheSeries(
                                item.sender,
                                index,
                                item?.senderType
                              ) &&
                              "ml-5"
                            }`}
                          >
                            {isProfilePicShown(
                              item.sender,
                              item?.senderType
                            ) &&
                              checkIsFirstInTheSeries(
                                item.sender,
                                index,
                                item?.senderType
                              ) && (
                                <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png" alt="" className='message-header_image'/>
                              )}
                            <MessageComponent
                              key={item?._id || item?.localMessageId}
                              type={item.type}
                              text={item.message}
                              isMyMessage={
                                getMessageStyles(
                                  item.sender,
                                  item?.senderType
                                ).isMyMessage
                              }
                              createdAt={item.messageTime}
                              isLocalMessage={item?._id ? false : true}
                            />
                          </div>
                        </>
                      ))}
          {
            isChatFetching && <div className="d-flex align-items-center justify-content-center"><Spinner animation="border" variant="info" /> </div>
          }
           <div className="pb-3" ref={bottomRef}></div>
    </div>
  )
}

export default MessageListScroll