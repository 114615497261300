import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { wicketEvents } from "../../../constants/agentEvents";

export default function WicketEvents({ wicketShow, wicketClose, actionFunction }) {
  let errorsObj = { userName: "", email: "", phoneNumber: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [apiError, setApiError] = useState("");
  const [loadingMatches, setLoadingMatches] = useState(false);
  const [matchList, setMatchList] = useState([]);
  function handleItemSelect(event, data){
    actionFunction(event, data);
    wicketClose();
  }
  return (
    <>
    

      <Modal
        size="xl"
        className="modal fade vertically-centered"
        show={wicketShow}
        centered
        onHide={() => wicketClose()}
      >
        <Modal.Header>
          <Modal.Title>Wicket Events</Modal.Title>
          <Button variant="" className="close" onClick={() => wicketClose()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        {loadingMatches === false && (
          <Modal.Body>
            {apiError && (
              <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                {apiError}
              </div>
            )}
            <div className="d-flex align-items-center flex-wrap form-group">
              {wicketEvents.map((item) => (
                <div key={item.screenText} className="modal-event btn btn-secondary" 
                // onTouchStart={()=> handleItemSelect("wicket",item.screenText)}
                onClick={()=> handleItemSelect("wicket", item.screenText)}
                >
                  {item.screenText}
                </div>
              ))}
            </div>
          </Modal.Body>
        )}

        {loadingMatches && (
          <Modal.Body>
            <h3 className="text-center">Loading...</h3>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}
