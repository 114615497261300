import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import ReactSelect from "react-select";
import { useDispatch } from "react-redux";
import { loadingToggleAction } from "../../../store/actions/AuthActions";
import {
  editBanner,
  getBannerById,
  updateStatus,
} from "../../../services/asyncFunctions";
import addPhoto from "../../pages/test";
import toast from "react-hot-toast";

export default function EditBanner({
  show,
  close,
  bannerId,
  toastSuccess,
  toastFailure,
  refetch,
}) {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imageLink, setImageLink] = useState("");
  const dispatch = useDispatch();

  async function uploadBannerImage() {
    dispatch(loadingToggleAction(true));
    let imageResponse = null;
    if (imageFile) {
      imageResponse = await addPhoto(imageFile, "banner");
      editBannerById(imageResponse.imageName);
      console.log(imageResponse);
    } else {
      editBannerById("");
    }
  }

  async function editBannerById(image) {
    let payload = {
      title,
      url,
      image: image ? image : imageLink,
      bannerId,
      type: "fsdf",
    };
    try {
      let result = await editBanner(payload);
      console.log(result);
      toast.success("Edited banner successfully!");
      close();
      refetch();
    } catch (e) {
      toast.error("Couldn't save changes");
    } finally {
      dispatch(loadingToggleAction(false));
    }
  }

  async function getBannerDetailsById(id) {
    try {
      let result = await getBannerById(id);
      console.log(result.data.data);
      setTitle(result.data.data.title);
      setUrl(result.data.data.url);
      setImageLink(result.data.data.image);
    } catch (e) {
      console.log(e);
    }
  }

  function handleInputChange(e) {
    if (e.target.name == "name") {
      setTitle(e.target.value);
    } else {
      setUrl(e.target.value);
    }
  }
  function handleImageFileChange(e) {
    setImageFile(e.target.files[0]);
  }

  useEffect(() => {
    if (show) {
      console.log(bannerId);
      getBannerDetailsById(bannerId);
    }
  }, [show]);

  return (
    <>
      <Modal className="fade" show={show}>
        <Modal.Header>
          <Modal.Title>Add Banner</Modal.Title>
          <Button variant="" className="close" onClick={() => close()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>

        <Modal.Body>
          <form
            className="form-control h-100"
            onSubmit={(e) => {
              e.preventDefault();
              uploadBannerImage();
            }}
          >
            <input
              type="text"
              placeholder="Enter Banner Title"
              name="name"
              className="form-control mb-3"
              value={title}
              required
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="url"
              placeholder="Enter Redirect URl"
              className="form-control mb-3"
              value={url}
              required
              onChange={handleInputChange}
            />
            <input
              type="file"
              name=""
              id=""
              accept="image/*"
              className="mb-3"
              onChange={handleImageFileChange}
            />
            <button className="btn btn-success shadow w-100">Add Banner</button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
