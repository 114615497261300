import Select from "react-select";
import React, { useEffect, useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { loadingToggleAction } from "../../store/actions/AuthActions";
import {
  getUserBetHistoryById,
  getUserBetMatchesByUserId,
  getUserDetailsById,
} from "../../services/asyncFunctions";
import { AWS_PHOTO_BASE_URL } from "./test";
import UserBetsModal from "../components/modals/UserBetsModal";
import UserBetMatchesTable from "../components/UserBetMatchesTable";
import UserCoinsModal from "../components/modals/UserCoinsModal";
import ReactPaginate from "react-paginate";
const tabData = [
  {
    name: "Bet History",
  },
  {
    name: "Diamonds",
  },

  {
    name: "Packages",
  },
];

let userDetailsString = localStorage.getItem("userDetailsId");
let userDetails = JSON.parse(userDetailsString);

function UserDetails() {
  const [userData, setUserData] = useState(null);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [betsData, setBetsData] = useState(null);
  const [betMatches, setBetMatches] = useState(null);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [showBetsModal, setShowBetsModal] = useState(false);
  const [betMatchesPageCount, setBetMatchesPageCount] = useState(0);
  const [betMatchesTotalCount, setBetMatchesTotalCount] = useState(1);

  const [totalBetsPages, setTotalBetsPages] = useState(1);
  const [betsCurrentPageNumber, setBetsCurrentPageNumber] = useState(0);
  const [showCoinsModal, setShowCoinsModal] = useState(false);
  const [selectedMatchId, setSelectedMatchId] = useState(null);
  const dispatch = useDispatch();
  const userDetailsFromStorage = JSON.parse(
    localStorage.getItem("userDetailsId")
  );

  async function getUserDetails() {
    const userDetailsString = localStorage.getItem("userDetailsId");
    const userDetails = JSON.parse(userDetailsString);
    dispatch(loadingToggleAction(true));
    try {
      let res = await getUserDetailsById(
        userDetails.userId,
        userDetails.userType
      );
      console.log(res);
      setUserData(res.data.data);
      getUserBetMatches(
        userDetails.userId,
        userDetails.userType,
        betMatchesPageCount
      );
    } catch (e) {
    } finally {
      dispatch(loadingToggleAction(false));
    }
  }

  const handlePageClick = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected;
    setBetMatchesPageCount(currentPage);
  };

  async function getUserBetHistory(matchId, currentPageNumber) {
    let userDetailsString = localStorage.getItem("userDetailsId");
    let userDetails = JSON.parse(userDetailsString);
    setIsTableLoading(true);
    setShowBetsModal(true);
    setSelectedMatchId(matchId);
    try {
      let res = await getUserBetHistoryById(
        userDetails.userId,
        userDetails.userType,
        matchId,
        currentPageNumber
      );
      console.log(res);
      setBetsData(res.data.data.bets);
      setTotalBetsPages(Math.ceil(res.data.data.count / 10));
    } catch (e) {
      console.log(e);
    } finally {
      setIsTableLoading(false);
    }
  }

  async function getUserBetMatches(userId, userType) {
    setIsTableLoading(true);

    try {
      let res = await getUserBetMatchesByUserId(
        userId,
        userType,
        betMatchesPageCount
      );
      console.log(res, "user bet matches");
      setBetMatches(res.data.data.matches);
      setBetMatchesTotalCount(Math.ceil(res.data.data.count / 5));
    } catch (e) {
      console.log(e);
    } finally {
      setIsTableLoading(false);
    }
  }

  useEffect(() => {
    if (selectedMatchId && betsCurrentPageNumber >= 1) {
      getUserBetHistory(selectedMatchId, betsCurrentPageNumber);
    }
  }, [betsCurrentPageNumber]);

  useEffect(() => {
    if (userDetailsFromStorage) {
      getUserBetMatches(
        userDetailsFromStorage.userId,
        userDetailsFromStorage.userType,
        betMatchesPageCount
      );
    }
  }, [betMatchesPageCount]);

  useEffect(() => {
    getUserDetails();
  }, []);
  if (userData) {
    return (
      <>
        <UserCoinsModal
          show={showCoinsModal}
          close={() => setShowCoinsModal(false)}
          userId={userDetails.userId}
          userType={userDetails.userType}
          userCoins={userData?.sportExCoins ?? 0}
          refetchUser={getUserDetails}
        />
        <UserBetsModal
          show={showBetsModal}
          close={() => setShowBetsModal(false)}
          betsData={betsData}
          isLoading={isTableLoading}
          totalPages={totalBetsPages}
          pageNumber={betsCurrentPageNumber}
          setCurrentPage={setBetsCurrentPageNumber}
        />
        <Card className="user-details-container">
          <Card.Header className="d-flex align-items-center justify-content-start">
            {!userData.profileImage && (
              <img
                src="https://i.pinimg.com/originals/7d/34/d9/7d34d9d53640af5cfd2614c57dfa7f13.png"
                alt=""
                width={100}
                height={100}
                className="rounded-circle mr-3"
              />
            )}
            {userData.profileImage && (
              <img
                src={AWS_PHOTO_BASE_URL + userData.profileImage}
                alt=""
                width={100}
                height={100}
                className="rounded-circle mr-3"
              />
            )}
            <div>
              <h1>{userData.userId}</h1>
              <div className="d-flex flex-col flex-column align-items-start ">
                <div className="d-flex flex-col align-items-center mb-2">
                  <div className="mr-3">
                    <span>User name : </span>
                    <span className="text-black">{userData.userName}</span>
                  </div>
                  <div>
                    <span>Email : </span>
                    <span className="text-black">
                      {userData?.email ?? "--"}
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-col align-items-center justify-content-between mb-2">
                  <div className="mr-3">
                    <span>Phone : </span>
                    <span className="text-black">{userData.phoneNumber}</span>
                  </div>
                  <div>
                    <span>User Type : </span>
                    <span className="text-black">
                      {userData.userType == "user" ? "Fan" : "Tipper"}
                    </span>
                  </div>
                </div>
                <div
                  className="p-2 px-3 bg-warning text-white rounded shadow-sm pointer"
                  onClick={() => setShowCoinsModal(true)}
                >
                  Coins
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
              <Nav as="ul" className="nav-pills mb-4 light">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link eventKey={data.name.toLowerCase()}>
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="pt-4">
                <Tab.Pane eventKey={tabData[0].name.toLowerCase()}>
                  <UserBetMatchesTable
                    tableData={betMatches}
                    openBetsModal={() => setShowBetsModal(true)}
                    onMatchView={getUserBetHistory}
                    totalPages={totalBetsPages}
                    pageNumber={betsCurrentPageNumber}
                    setCurrentPage={setBetsCurrentPageNumber}
                  />
                  <div className="mt-4">
                    {betMatchesTotalCount > 1 && (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={betMatchesTotalCount}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        forcePage={betMatchesPageCount}
                      />
                    )}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey={tabData[1].name.toLowerCase()}>
                  <table class="table-primary w-100 rounded-lg border border-dark-subtle content-box">
                    <thead className="">
                      <tr className="text-center">
                        <th>Last Diamond Purchase Date </th>
                        <th>First Diamond Purchase Date </th>
                        <th>Total Diamond Bought </th>
                        <th>Total Amount Spent </th>
                        <th>Current Diamond Balance </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <td>20 march, 2013</td>
                        <td>20 march, 2013 </td>
                        <td>1000 </td>
                        <td>10,000 Inr </td>
                        <td>700 </td>
                      </tr>
                    </tbody>
                  </table>
                  <select
                    defaultValue={"all"}
                    name="emailstatus"
                    className="form-control mt-2 mb-3 mx-auto"
                    style={{ maxWidth: "300px" }}
                  >
                    <option value="user">All</option>
                    <option value="tipper">Bonas</option>
                    <option value="user">Game history</option>
                  </select>
                  <table class=" border border-dark-subtle w-100 rounded-lg text-dark">
                    <thead className=" content-box">
                      <tr className="text-center">
                        <th>Date </th>
                        <th>Transaction ID </th>
                        <th>Diamond Purchased </th>
                        <th>Amt Spent </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <td>20 march, 2013</td>
                        <td>3942094092384902 </td>
                        <td>100 </td>
                        <td>200 </td>
                      </tr>
                      <tr className="text-center">
                        <td>20 march, 2013</td>
                        <td>3942094092384902 </td>
                        <td>100 </td>
                        <td>200 </td>
                      </tr>
                      <tr className="text-center">
                        <td>20 march, 2013</td>
                        <td>3942094092384902 </td>
                        <td>100 </td>
                        <td>200 </td>
                      </tr>
                      <tr className="text-center">
                        <td>20 march, 2013</td>
                        <td>3942094092384902 </td>
                        <td>100 </td>
                        <td>200 </td>
                      </tr>
                    </tbody>
                  </table>
                </Tab.Pane>
                <Tab.Pane eventKey={tabData[2].name.toLowerCase()}>
                  <table class="table-primary w-100 rounded-lg border border-dark-subtle content-box">
                    <thead className="">
                      <tr className="text-center">
                        <th>Total Packages Purchased </th>
                        <th>Total Diamond Spent </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <td>20 </td>
                        <td>800 </td>
                      </tr>
                    </tbody>
                  </table>
                  <h3 className=" p-3 rounded border-bottom text-black text-center mt-3  mb-3">
                    {/* Detailed View */}
                  </h3>
                  <table class=" border border-dark-subtle w-100 rounded-lg text-dark">
                    <thead className=" content-box">
                      <tr className="text-center">
                        <th>Date </th>
                        <th>Match </th>
                        <th>Diamond Spend </th>
                        <th>Packages purchased </th>
                        <th>No of tippers </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <td>20 march, 2013</td>
                        <td>IND vs PAK </td>
                        <td>300 </td>
                        <td>2 </td>
                        <td>10 </td>
                      </tr>
                    </tbody>
                  </table>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </>
    );
  } else {
    return <></>;
  }
}

export default UserDetails;
