import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { matchEvents } from "../../../constants/agentEvents";

export default function MatchEvents({ matchShow, matchClose, actionFunction }) {
  let errorsObj = { userName: "", email: "", phoneNumber: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [apiError, setApiError] = useState("");
  const [loadingMatches, setLoadingMatches] = useState(false);
  const [matchList, setMatchList] = useState([]);
  function handleItemSelect(data){
    actionFunction(data);
    matchClose();
  }
  return (
    <>
      <Modal
        size="xl"
        className="fade vertically-centered"
        show={matchShow}
        centered
        onHide={() => matchClose()}
      >
        <Modal.Header>
          <Modal.Title>Match Events</Modal.Title>
          <Button variant="" className="close" onClick={() => matchClose()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        {loadingMatches === false && (
          <Modal.Body>
            <div className="d-flex align-items-center flex-wrap form-group">
              {matchEvents.map((item) => (
                <div key={item} className="modal-event btn btn-secondary" 
                onClick={()=> handleItemSelect(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}
