import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import FantasyTipCard from "../cards/FantasyTipCard";
import {
  deleteFancyTip,
  deleteHotpick,
  deleteRiskypick,
  getFantasyData,
  getMatchSquad,
  postFantasyHotPicks,
  postFantasyRiskyPicks,
  postFantasyTips,
} from "../../../services/asyncFunctions";
import { Mention, MentionsInput } from "react-mentions";
import { display } from "@mui/system";
import { Select } from "@mui/material";
import ReactSelect from "react-select";

function FantasyModal({ show, onHide, matchId }) {
  const [selectedMatchTab, setSelectedMatchTab] = useState("preview");
  const [text, setText] = useState("");
  const [users, setUsers] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [mentionIndex, setMentionIndex] = useState(null);
  const [tipsListing, setTipsListing] = useState([]);
  const [select1Key, setSelect1Key]  = useState(0);
  const [select2Key, setSelect2Key]  = useState(5);

  const [displayPicksData, setDisplayPicksData] = useState({
    hotPicks: [],
    riskPicks: [],
  });
  const [playersSelectData, setPlayersSelectData] = useState([]);
  const [picksData, setPicksData] = useState({
    hotPicks: [],
    riskPicks: [],
  });
  const [tipData, setTipData] = useState({
    text: "",
    players: [],
  });
  const inputRef = useRef(null);
  function handleTabChange(tab) {
    setSelectedMatchTab(tab);
  }

  const handleMentionTextChange = (
    event,
    newValue,
    newPlainTextValue,
    mentions
  ) => {
    // console.log(newValue, newPlainTextValue, mentions);
    setText(newValue);
    if (mentions.length) {
      let temp = mentions.map((item) => ({
        pid: item.id,
        name: item.display,
      }));
      setTipData({
        text: newPlainTextValue,
        players: [...temp],
      });
      return
    }
    setTipData({
        text: newPlainTextValue,
        players: [],
      });


    
  };

  async function getFantacyMatchData() {
    try {
      let result = await getFantasyData(matchId);
      console.log(result);
      setTipsListing(result.data.data?.tips ? result.data.data.tips : []);
      setDisplayPicksData({
        hotPicks: result.data.data?.hotPicks ? result.data.data?.hotPicks  :[],
        riskPicks: result.data.data?.riskyPicks ? result.data.data?.riskyPicks  :[]
      })
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async function  deleteTip(tipId){
      try {
        const response = await deleteFancyTip(matchId, tipId)
        getFantacyMatchData();
      } catch (error) {
        console.log(error)
      }
  }

async function deletePicks(type, pickId){
  try {
    let response;
    if(type == "hot"){
      response = await deleteHotpick(matchId, pickId)
    }else{
      response = await deleteRiskypick(matchId, pickId)
    }
    getFantacyMatchData();
  } catch (error) {
    console.log(error)
  }
}

  async function addTip() {
    try {
      let result = await postFantasyTips(matchId, tipData);
      console.log(result);
      setTipData({
        text: "",
        players: [],
      });
      setText("");
      getFantacyMatchData();
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async function addHotRiskyPicks(type) {
    try {
      let result;
      if (type == "hot") {
        result = await postFantasyHotPicks(matchId, picksData.hotPicks);
        console.log(result);
        setSelect1Key(prev => prev + 1)
      } else {
        result = await postFantasyRiskyPicks(matchId, picksData.riskPicks);
        console.log(result);
        setSelect2Key(prev => prev + 1)

      }
      getFantacyMatchData();
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  const handlePicksUpdate = (type, data)=>{
    console.log(type, data)
    if(!data){
        if(type == "hot") setPicksData(prev => ({...prev, hotPicks: []}))
        else setPicksData(prev => ({...prev, riskPicks: []}))
    }
    else{
        let filteredData = data.map(item => item.value);
        if(type == "hot") {
            setPicksData(prev => ({...prev, hotPicks: filteredData}))
        }
         else {
            setPicksData(prev => ({...prev, riskPicks: filteredData}))
        }
    }
    }
  

  useEffect(() => {
    async function getSquad() {
      try {
        let result = await getMatchSquad(matchId);
        let temp = result.data.data?.players.map((player) => ({
          id: player.pid,
          display: player.name,
          teamName: player.teamName,
          teamId: player.teamId
        }));
        let selectTempData = temp.map((item) => ({
          label: item.display,
          value: {
            pid: String(item.id),
            name: item.display,
            teamName: item.teamName,
            teamId: String(item.teamId)
          },
        }));
        setPlayersSelectData(selectTempData);
        setUsers(temp);
      } catch (error) {
        console.log(error);
      }
    }
    if (show) {
      getFantacyMatchData();
      getSquad();
    }
  }, [show]);


  useEffect(()=>{
    console.log(tipData)
  },[tipData])

  return (
    <Modal className="fade" show={show} size="xl">
      <Modal.Header>
        <Modal.Title>Fantasy</Modal.Title>
        <Button variant="" className="close" onClick={() => onHide()}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body className="">
        {selectedMatchTab == "preview" && (
          <div className="preview-container shadow-md">
            <div className="preview-left">
              <div className="preview-left_top">
                {tipsListing.map((item) => (
                  <FantasyTipCard key={item._id} text={item.text} tipId={item._id} removeTip={deleteTip}/>
                ))}
              </div>
              <div className="preview-left_bottom">
                <div className="input-area">
                  <div
                    style={{ position: "relative", flex: ".9" }}
                    className=""
                  >
                    {users && (
                      <MentionsInput
                        value={text}
                        onChange={handleMentionTextChange}
                        markup="@{{__type__||__id__||__display__}}"
                        placeholder="Type anything, use the @ symbol to tag other users."
                        className="mentions"
                        forceSuggestionsAboveCursor
                      >
                        <Mention
                          type="user"
                          trigger="@"
                          data={users}
                          className="mentions__mention"
                          
                        />
                      </MentionsInput>
                    )}
                  </div>
                  <button className="btn btn-warning" onClick={addTip}>
                    Send
                  </button>
                </div>
              </div>
            </div>
            <div className="preview-right">
              <div className="preview-right_top">
                <div className="preview-right-header shadow-md rounded">
                  Hot Picks
                </div>
                <div className="d-flex align-items-center mt-1">
                  <ReactSelect
                  key={select1Key}
                    options={playersSelectData}
                    className="w-100"
                    isMulti
                    onChange={(value) => handlePicksUpdate('hot', value)}
                  />
                  <button className="btn btn-primary p-0 py-2 px-3 ml-2" onClick={()=>addHotRiskyPicks('hot')}>
                    Send
                  </button>
                </div>
                <div className="picks-content">
                {displayPicksData.hotPicks.map(item => <div className="picks-content_badge"><span className="">{item.name}</span><span className="pointer" onClick={()=> deletePicks("hot", item._id)}> X</span></div>)}
                </div>
              </div>
              <div className="preview-right_bottom">
                <div className="preview-right-header shadow-md rounded">
                  Risky Picks
                </div>
                <div className="d-flex align-items-center mt-1">
                  <ReactSelect
                  key={select2Key}
                    options={playersSelectData}
                    className="w-100"
                    isMulti
                    onChange={(value) => handlePicksUpdate('risky', value)}
                  />
                  <button className="btn btn-primary p-0 py-2 px-3 ml-2" onClick={()=>addHotRiskyPicks('risky')}>
                    Send
                  </button>
                </div>
                <div className="picks-content">
                  {displayPicksData.riskPicks.map(item => <div className="picks-content_badge"><span className="">{item.name}</span><span className="pointer" onClick={()=> deletePicks("risky", item._id)}> X</span></div>)}
                </div>
              </div>
            </div>
          </div>
        )}
        {selectedMatchTab == "edit" && (
          <div className="edit-container shadow-md"></div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default FantasyModal;
