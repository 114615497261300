import React, { useEffect, useRef } from "react";


function OverSlider({ data }) {
  const containerRef = useRef(null);

  function isFirstBall(previousElement, index){
      if(index == 0) return true;
      else if(previousElement.event == "overEnd") return true;
      else return false;
  }
  useEffect(()=>{
    const scrollToRecentBall = () => {
      if (containerRef.current && data.length > 0) {
        const recentBall = containerRef.current.lastChild;
        recentBall.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
      }
    };

    setTimeout(scrollToRecentBall, 0);
  },[data])
  return (
    <div className="over-slider" ref={containerRef}>
      {data.map((item, index) => {
        if (
          item.event == "run" ||
          item.event == "wide" ||
          item.event == "no ball" ||
          item.event == "wicket" ||
          item.event == "legby" ||
          item.event == "not out"
        ) {
          return (
            <>
            {isFirstBall(data[index == 0 ? 0 : index - 1], index) && <div className="over-end-view" key={item._id + "dfsf"}>over {item.overNumber}</div>}
            <div
              key={item._id}
              className={`ball-box shadow ${item.event == "wicket" && "out"} ${item.ballText == "4" && "four"} ${item.ballText == "6" && "six"}`}
            >
              {item.ballText}
            </div>
            </>
          );
        } else if (item.event == "overEnd") {
          return  <div className="over-end-view" key={item._id + "dfsdfds"}>= {item.overTotal}</div>
          
        }
      })}
    </div>
  );
}

export default OverSlider;
