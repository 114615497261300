import moment from "moment";
import  toast  from "react-hot-toast";

const sortObjectKeys = (obj) =>
   Object.entries(obj)
      .sort()
      .reduce((o, [k, v]) => ((o[k] = v), o), {});

export const isEqual = (val1, val2) => {
   if (typeof val1 === "number" && typeof val2 === "number")
      return val1 === val2;
   if (typeof val1 === "string" && typeof val2 === "string")
      return val1 === val2;
   if (Array.isArray(val1) && Array.isArray(val2)) {
      return JSON.stringify(val1) === JSON.stringify(val2);
   }
   if (typeof val1 === "object" && typeof val2 === "object") {
      return (
         JSON.stringify(sortObjectKeys(val1)) ===
         JSON.stringify(sortObjectKeys(val2))
      );
   }
   return false;
};


export const toastSuccess = (message) => {
   toast.success(`✅ ${message}`, {
     position: "top-right",
     autoClose: 3000,
     hideProgressBar: true,
     closeOnClick: true,
     pauseOnHover: false,
     draggable: true,
   });
 };

 export const toastError = (message) => {
   toast.error(message, {
     position: "top-right",
     autoClose: 3000,
     hideProgressBar: true,
     closeOnClick: true,
     pauseOnHover: false,
     draggable: true,
   });
 };


 export const formatDate = (isoDate, format="") => {
   if(!format){
     const parts = isoDate.split('-');
     return `${parts[1]}/${parts[2]}/${parts[0]}`;
   }else{
     return moment(isoDate).format(format);
   }
};

export function getTypeStyles(type) {
   if (!type) return "";
   else if (type == "yes" || type == "back")
     return "px-2 py-1 btn btn-primary ";
   else if (type == "no" || type == "lay") return "px-2 py-1 btn btn-danger ";
   else return "";
 }

 export function getProfitAndLoss(betObject) {
   if (getResult(betObject) == "Pending")
     return `Expected +${betObject?.profit}`;
   else if (getResult(betObject) == "Lost") return `-${betObject?.profit}`;
   else return `+${betObject?.profit}`;
 }

 export function getResult(bet) {
   if (bet?.result === undefined) return "Pending";
   else {
     if (bet?.result?.isWin) return "Won";
     else return "Lost";
   }
 }


 export const tabCodes = [
   { value: "user-management/user-details", label: "User Management" },
   { value: "match-to-odds", label: "Match to odds" },
   { value: "gems-management", label: "Shop Section" },
   { value: "match-controls", label: "Match Controls" },
   { value: "ads-management", label: "Ads Manager" },
   { value: "matchleaderboard", label: "Match Leaderboard" },
   { value: "match-management", label: "Match Management" },
   { value: "matchBets", label: "Match Bets" },
   { value: "session-bet-management/:matchId", label: "Session Management" },
   { value: "fantasy-management", label: "Fantasy Management" },
   { value: "mark-match-complete", label: "Active Bet Matches" },
   { value: "competitions", label: "Competitions" },
   { value: "voting-management", label: "Voting Management" },
   { value: "rewards-management", label: "Coins/Rewards" },
  { value: "tiproom/perTipperRoom", label: "Tipper Room" },
  { value: "unsettled-matches/:matchId", label: "Unsettled Matches" },

  // { value: "perTipperRoom", label: "Tipper Room Details" },
  { value: "notifications", label: "Notifications" },
  // { value: "user-details", label: "User Details" },
  { value: "agent-match-listing/agent-match-screen/player-selection", label: "Agent" },
  // { value: "agent-match-screen", label: "Agent Match Screen" },
  // { value: "player-selection", label: "Player Selection" },
  // { value: "mark-match-complete", label: "Session Management" },



  // { value: "admin-settings", label: "Admin Settings" },
];



export const authorizedRoles = [
  "user-management",
  "user-details"
]