import React, { useEffect, useState } from "react";
/// React router dom
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
import Home from "./components/Dashboard/Home";
//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import Dashboard from "./pages/Dashboard";
import UserManagement from "./pages/UserManagement";
import Transactions from "./pages/Transactions";
import PoolingSystem from "./pages/PoolingSystem";
import PromoBanner from "./pages/PromoBanner";
import Setting from "./pages/Setting";
import TipRoom from "./pages/TipRoom";
import MatchLeaderboard from "./pages/MatchLeaderboard";
import UpcomingMatches from "./pages/UpcomingMatches";
import InAppPurchase from "./pages/InAppPurchase";
import PerTipperRoom from "./pages/PerTipperRoom";
import Notifications from "./pages/Notifications";
import Loader from "./components/overlaySpinner/Loader";
import { connect } from "formik";
import UserDetails from "./pages/UserDetails";
import AgentMatchList from "./pages/AgentMatchList";
import AgentMatchScreen from "./pages/AgentMatchScreen";
import PlayerSelection from "./pages/PlayerSelection";
import MatchManagement from "./pages/MatchManagement";
import RewardsSettings from "./pages/RewardsSettings";
import OddsToMatch from "./pages/OddsToMatch";
import GemsManagement from "./pages/GemsManagement";
import MatchControls from "./pages/MatchControls";
import AdsManagement from "./pages/AdsManagement";
import VotingManagement from "./pages/VotingManagement";
import Competitions from "./pages/Competitions";
import MatchBetsLive from "./pages/MatchBetsLive";
import MarkMatchComplete from "./pages/MarkMatchComplete";
import AdminSettings from "./pages/AdminSettings";
import {authorizedRoles} from "../jsx/components/PluginsMenu/Nestable/utils"
import SessionsManagement from "./pages/SessionsManagement";
import SessionsForMatch from "./pages/SessionsForMatch";
import FantasyManagement from "./pages/FantasyManagement";
import SocialLinks from "./pages/SocialLinks";
import TipperChannels from "./pages/TipperChannels";
import UnsetteledMatches from "./pages/UnsettledMatches";
import UnsettledMatchSessions from "./pages/UnsettledMatchSessions";

const Markup = (props) => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);
  console.log(path, "path object");
  const routes = [
    /// Dashboard
    // { url: "analytics", component: Analytics },
    // { url: "reviews", component: Reviews },
    // { url: "order", component: Order },
    // { url: "order-list", component: OrderList },
    // { url: "general-customers", component: CustomerList },
    // { url: "ecom-product-detail", component: EcomProductDetails },
    // { url: "task", component: Task },

    /// Apps
    // { url: "app-profile", component: AppProfile },
    // { url: "post-details", component: PostDetails },
    // { url: "email-compose", component: Compose },
    // { url: "email-inbox", component: Inbox },
    // { url: "email-read", component: Read },
    // { url: "app-calender", component: Calendar },

    /// Chart
    // { url: "chart-sparkline", component: SparklineChart },
    // { url: "chart-chartjs", component: ChartJs },
    // { url: "chart-chartist", component: Chartist },
    // { url: "chart-apexchart", component: ApexChart },
    // { url: "chart-rechart", component: RechartJs },

    /// Bootstrap
    // { url: "ui-alert", component: UiAlert },
    // { url: "ui-badge", component: UiBadge },
    // { url: "ui-button", component: UiButton },
    // { url: "ui-modal", component: UiModal },
    // { url: "ui-button-group", component: UiButtonGroup },
    // { url: "ui-accordion", component: UiAccordion },
    // { url: "ui-list-group", component: UiListGroup },
    // { url: "ui-media-object", component: UiMediaObject },
    // { url: "ui-card", component: UiCards },
    // { url: "ui-carousel", component: UiCarousel },
    // { url: "ui-dropdown", component: UiDropDown },
    // { url: "ui-popover", component: UiPopOver },
    // { url: "ui-progressbar", component: UiProgressBar },
    // { url: "ui-tab", component: UiTab },
    // { url: "ui-pagination", component: UiPagination },
    // { url: "ui-typography", component: UiTypography },
    // { url: "ui-grid", component: UiGrid },

    /// Plugin
    // { url: "uc-select2", component: Select2 },
    // { url: "uc-nestable", component: Nestable },
    // { url: "uc-noui-slider", component: MainNouiSlider },
    // { url: "uc-sweetalert", component: MainSweetAlert },
    // { url: "uc-toastr", component: Toastr },
    // { url: "map-jqvmap", component: JqvMap },
    // { url: "uc-lightgallery", component: Lightgallery },

    /// Widget
    // { url: "widget-basic", component: Widget },

    /// Shop
    // { url: "ecom-product-grid", component: ProductGrid },
    // { url: "ecom-product-list", component: ProductList },
    // { url: "ecom-product-detail", component: ProductDetail },
    // { url: "ecom-product-order", component: ProductOrder },
    // { url: "ecom-checkout", component: Checkout },
    // { url: "ecom-invoice", component: Invoice },
    // { url: "ecom-product-detail", component: ProductDetail },
    // { url: "ecom-customers", component: EcomCustomers },

    /// Form

    // { url: "form-redux", component: ReduxForm },
    // { url: "form-redux-wizard", component: WizardForm },
    // { url: "form-element", component: Element },
    // { url: "form-wizard", component: Wizard },
    // { url: "form-wizard", component: Wizard },
    // { url: "form-editor-summernote", component: SummerNote },
    // { url: "form-pickers", component: Pickers },
    // { url: "form-validation-jquery", component: jQueryValidation },

    /// table
    // { url: "table-datatable-basic", component: DataTable },
    // { url: "table-bootstrap-basic", component: BootstrapTable },
    // { url: "table-filtering", component: FilteringTable },
    // { url: "table-sorting", component: SortingTable },

    /// pages
    // { url: "page-register", component: Registration },
    // { url: "page-lock-screen", component: LockScreen },
    // { url: "page-error-400", component: Error400 },
    // { url: "page-error-403", component: Error403 },
    // { url: "page-error-404", component: Error404 },
    // { url: "page-error-500", component: Error500 },
    // { url: "page-error-503", component: Error503 },
    // { url: "page-login", component: Login },
    { url: "dashboard", component: Home },
    { url: "", component: Home },
    { url: "dashboardhome", component: Dashboard },
    { url: "user-management", component: UserManagement },
    { url: "transactions", component: Transactions },
    { url: "poolingsystem", component: PoolingSystem },
    { url: "promobanner", component: PromoBanner },
    { url: "tiproom", component: TipperChannels },
    { url: "perTipperRoom", component: PerTipperRoom },
    { url: "matchleaderboard", component: MatchLeaderboard },
    { url: "upcomingmatches", component: UpcomingMatches },
    { url: "inapppurchase", component: InAppPurchase },
    { url: "notifications", component: Notifications },
    { url: "user-details", component: UserDetails },
    { url: "agent-match-listing", component: AgentMatchList },
    { url: "agent-match-screen", component: AgentMatchScreen },
    { url: "player-selection", component: PlayerSelection },
    { url: "match-management", component: MatchManagement },
    { url: "rewards-management", component: RewardsSettings },
    { url: "match-to-odds", component: OddsToMatch },
    { url: "gems-management", component: GemsManagement },
    { url: "match-controls", component: MatchControls },
    { url: "ads-management", component: AdsManagement },
    { url: "voting-management", component: VotingManagement },
    { url: "competitions", component: Competitions },
    { url: "matchBets", component: MatchBetsLive },
    { url: "mark-match-complete", component: MarkMatchComplete },
    { url: "admin-settings", component: AdminSettings },
    {url: "session-bet-management", component: SessionsManagement},
    {url: "session-bet-management/:matchId", component: SessionsForMatch},
    {url: "fantasy-management", component: FantasyManagement},
    {url: "socials-management", component: SocialLinks},
    {url: "unsettled-matches", component: UnsetteledMatches},
    {url: "unsettled-matches/:matchId", component: UnsettledMatchSessions},






  ];

useEffect(()=>{
  if(path  == 'agent-match-screen'){
    document.body.classList.add('no-scroll');
  }else{
    document.body.classList.remove('no-scroll');
  }
  console.log(props)

},[path])

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
       
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 90 }}
          >
            {<Switch>
              
              {routes.map((data, i) => {
                if(props.userType == "admin"){
                  return <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                  />
                }
               else if((JSON.stringify(props.roles)).includes(data.url) ){
                  return <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                  />
                }else return null
              }
              )}
            </Switch>}
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup
// const mapStateToProps = (state) => {
//   return {
//     isLoading: state.auth.showLoading,
//     roles: state.auth.roles,
//     userType: state.auth.userType,
//   };
// };

// export default connect(mapStateToProps)(Markup);

