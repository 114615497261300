import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import {
  deleteGemsPackages,
  editGemsPackage,
  getGemsPackages,
  postGemsPackage,
} from "../../services/asyncFunctions";
import EditGemsModal from "../components/modals/EditGemsModal";
import GemsModal from "../components/modals/GemsModal";
import { AWS_BANNER_PHOTO_BASE_URL, AWS_PACKAGES_BASE_URL } from "./test";

function GemsManagement() {
  const [showAddGemsModal, setShowAddGemsModal] = useState(false);
  const [showEditGemsModal, setShowEditGemsModal] = useState(false);
  const [packages, setPackages] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [deviceType, setDeviceType] = useState("android");
  const [isLoading, setIsLoading] = useState(true);


  const fetchPackages = async () => {
    setIsLoading(true)
    try {
      const response = await getGemsPackages();
      console.log(response);
      setPackages(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally{
    setIsLoading(false);
    }
  };

  const addPackage = async (formData) => {
    try {
      const response = await postGemsPackage(formData);
      console.log(response);
      notifyTopRight();
      fetchPackages();
    } catch (error) {
      notifyError();
      console.error("Error fetching data:", error);
    }
  };

  const editPackage = async (formData) => {
    try {
      const response = await editGemsPackage(formData);
      notifyTopRight();
      fetchPackages();
    } catch (error) {
      notifyError();
      console.error("Error fetching data:", error);
    }
  };

  const removePackage = async (packageId) => {
    try {
      const response = await deleteGemsPackages(packageId);
      notifyTopRight();
      fetchPackages();
    } catch (error) {
      notifyError();
      console.error("Error fetching data:", error);
    }
  };

  function handleEditClick(item){
    setSelectedPackage(item);
    setShowEditGemsModal(true);
  }

  function notifyTopRight() {
    toast.success("✅successfull!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  function notifyError() {
    toast.error("❌ Error !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  useEffect(() => {
    fetchPackages();
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <GemsModal
        show={showAddGemsModal}
        onHide={() => setShowAddGemsModal(false)}
        submitFunction={addPackage}
        deviceType={deviceType}
      />
      <EditGemsModal
        show={showEditGemsModal}
        onHide={() => setShowEditGemsModal(false)}
        submitFunction={editPackage}
        selectedPackage={selectedPackage}
        
      />
      <Card className="p-2">
        <div className="d-flex align-items-center justify-content-between">
        <h1>Diamonds management </h1>
        <select className="form-control w-25" name="" id="" defaultValue={"android"} onChange={(e)=> setDeviceType(e.target.value)}>
          <option value="android">Android/web</option>
          <option value="iPhone">iOS</option>
        </select>
        </div>
        
        <section className="mt-3">
         {deviceType == "android" && <div>
            {packages &&
              packages.map((item) => item?.deviceType == "android" && (
                <div
                  key={item._id}
                  className="gems-package-card rounded p-2 d-flex flex-row shadow-sm mb-3  align-items-center justify-content-between gap-2"
                >
                  <div className="d-flex flex-column align-items-start ">
                    <img src={AWS_PACKAGES_BASE_URL + item?.image} alt="" width={50}/>
                    <p>{item.name}</p>
                    <span className="font-weight-bolder">
                      {item.gems} Diamonds
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    <p className="font-weight-bolder">{item.amount}</p>
                    <div className="d-flex align-items-center">
                      <button className="btn btn-secondary mr-2" onClick={()=> handleEditClick(item)}>Edit</button>
                      <button
                        className="btn btn-danger"
                        onClick={() => removePackage(item._id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>}

          {deviceType == "iPhone" && <div>
            {packages &&
              packages.map((item) =>item?.deviceType == "iPhone" && (
                <div
                  key={item._id}
                  className="gems-package-card rounded p-2 d-flex flex-row shadow-sm mb-3  align-items-center justify-content-between gap-2"
                >
                  <div className="d-flex flex-column align-items-start ">
                    <p>{item.name}</p>
                    <span className="font-weight-bolder">
                      {item.gems} Diamonds
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    <p className="font-weight-bolder">{item.amount}</p>
                    <div className="d-flex align-items-center">
                      <button className="btn btn-secondary mr-2" onClick={()=> handleEditClick(item)}>Edit</button>
                      <button
                        className="btn btn-danger"
                        onClick={() => removePackage(item._id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>}

          {isLoading && <h3 className="w-100 text-center">Loading...</h3>}

          <button
            className="btn btn-warning shadow"
            onClick={() => setShowAddGemsModal(true)}
          >
            Add Package
          </button>
        </section>
      </Card>
    </>
  );
}

export default GemsManagement;
