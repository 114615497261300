export function getUserToken() {
  const data = localStorage.getItem("userDetails");
  return data;
}

export function deleteUserToken() {
  const data = localStorage.removeItem("userDetails");
  return data;
}

