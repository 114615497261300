import React, { useEffect, useState } from 'react'
import { addSession, deleteSession, getSessionOdds, toggleSessionOdds } from '../../services/asyncFunctions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Card, Spinner } from 'react-bootstrap';
import SessionHistoryCard from '../components/sessionHistoryCard/SessionHistoryCard';
import SessionHistoryPostCard from '../components/modals/SessionHistoryPostCard';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

function SessionsForMatch() {
    const {matchId} = useParams();
    const [sessionList, setSessionList] = useState(null);
    const [isLoading, setIsLoading]  = useState(true);
    const [teams, setTeams] = useState(null);
    const [showForm, setShowForm] = useState(false)
    const location = useLocation();


    console.log(location)




    async function fetchSessions() {
        setIsLoading(true);
        try {
          let response = await getSessionOdds(matchId);
          console.log(response);
          setSessionList(response?.data?.data.sessions);
          setTeams(response?.data?.data.teams);
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      }
      async function addNewSession(isPassed, session,inning, min, max, open, odds, teamId, passedAt){
        setIsLoading(true);
        try{
          let response = await addSession(matchId, isPassed,inning, session, min, max, open, odds, teamId, passedAt);
          console.log(response);
          setShowForm(false);
        // notifyTopRight();
          fetchSessions();
        }catch (e) {
          console.log(e);
        //   notifyError();
        } finally {
          setIsLoading(false);
        }
      }

      async function removeSession(sessionId){
        setIsLoading(true);
          try{
             let res = await deleteSession(matchId, sessionId);
            //  notifyTopRight();
             fetchSessions()
          }catch(e){
            // notifyError();
          }finally {
            setIsLoading(false);
          }
      }
      async function updateSessionPassFailStatus(sessionId, isPassed, text, inning, teamId, min, max, open, odds, passedAt){
        setIsLoading(true);
        try {
          let response = await toggleSessionOdds(matchId, sessionId, isPassed, text, inning, teamId, min, max, open, odds, passedAt);
          console.log(response);
        // notifyTopRight();
          // fetchSessions();
        } catch (e) {
          console.log(e);
        //   notifyError();
        } finally {
            setIsLoading(false);
        }
      }  

useEffect(()=>{
 fetchSessions();
},[])
  return (
    <Card>
        <Card.Header>
        <Card.Title>Session History</Card.Title>
        <Button variant='outline-primary' onClick={()=> setShowForm(prev => !prev)}>Add new</Button>
        </Card.Header>
        <Card.Body>
        {showForm && <div>
          <SessionHistoryPostCard teams={teams} addSession={addNewSession}/>
      </div>}
        {!isLoading && sessionList &&
        sessionList.map((item) => (
          <SessionHistoryCard
            key={item._id}
            handleStatusUpdate={updateSessionPassFailStatus}
            handleDelete={removeSession}
            sessionId={item._id}
            title={item.title}
            yes={item?.odds?.yes ?? 0}
            no={item?.odds?.no ?? 0}
            isPassed={Boolean(item?.isPassed) ?? false}
            inning={item?.inning ?? "not selected"}
            text = {item?.session ?? ""}
            open={item?.open ?? ""}
            min= {item?.min ?? ""}
            max = {item?.max ?? ""}
            odds={item?.odds ?? ""}
            teamId={item?.teamId ?? ""}
            teams={teams}
            type={'update'}
            passedAt={item?.passedAt ?? ""}
          />
        ))}
        {isLoading && <Spinner animation='border' variant='warning' className=''/>
        }
        {!isLoading && sessionList && sessionList.length == 0 && <h4 className='text-center'>No Data</h4>}
        </Card.Body>
    </Card>
  )
}

export default SessionsForMatch