import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import Select from 'react-select';
import { tabCodes, toastError } from '../PluginsMenu/Nestable/utils';
// import './CustomModal.css'; // Import CSS file for styling



const EditSubadmin = ({ show, onHide, updateUser, currentRoles, name }) => {
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: name,
    selectedOptions: currentRoles ?  [...getRoleObject(currentRoles)] : [],
  });


  function getRoleObject(roles){
    let temp = tabCodes.filter(item => roles.includes(item.value) );
    return temp;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (selectedOptions) => {
    setFormData({
      ...formData,
      selectedOptions,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Do something with form data, e.g., submit to server
    console.log(formData);
    let roles = formData?.selectedOptions?.map((item)=> item.value);
    if(!roles || roles.length == 0){
        setError("Select roles for the subAdmin");
        return
    }
    let payload = {
        ...formData,
        roles
    }
    delete payload.selectedOptions;
    updateUser(payload, "editUser");
    onHide();
    setError(null);
    setFormData({
        name: "",
        selectedOptions: []
    })
  };

  useEffect(()=>{
    setFormData({
    name: name,
    selectedOptions: currentRoles ?  [...currentRoles] : [],
  })
  },[currentRoles])


  useEffect(()=>{
  setError(null);
  },[show])

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-monospace'>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="name">
            <Form.Label className='text-dark font-weight-bold'>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              name="name"
              required
              value={formData.name}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="roles">
            <Form.Label className='text-dark font-weight-bold'>Roles</Form.Label>
            <Select
              options={tabCodes}
              isMulti
              onChange={handleSelectChange}
              value={formData.selectedOptions}
              placeholder="Select roles"
            />
          </Form.Group>
{ error &&  <Alert key={"danger"} variant={"danger"}>
          {error}
        </Alert>}
          <Button variant="outline-secondary" className='w-100 ' type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditSubadmin;
