import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { nbEvents } from "../../../constants/agentEvents";

export default function NoBallEvents({ noBallShow, noBallClose, actionFunction }) {
  let errorsObj = { userName: "", email: "", phoneNumber: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [apiError, setApiError] = useState("");
  const [loadingMatches, setLoadingMatches] = useState(false);
  const [matchList, setMatchList] = useState([]);

  function handleItemSelect(event, data){
    actionFunction(event, data);
    noBallClose();
  }
  return (
    <>

      <Modal
        size="lg"
        className="fade vertically-centered"
        show={noBallShow}
        centered
        onHide={() => noBallClose()}
      >
        <Modal.Header>
          <Modal.Title>No Ball Events</Modal.Title>
          <Button variant="" className="close" onClick={() => noBallClose()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        {loadingMatches === false && (
          <Modal.Body>
            <div className="d-flex align-items-center flex-wrap form-group">
              {nbEvents.map((item) => (
                <div key={item.screenText} className="modal-event btn btn-secondary" 
                // onTouchStart={()=> handleItemSelect("no ball",item.screenText)}
                onClick={()=> handleItemSelect("no ball", item.screenText)}
                >
                  {item.screenText}
                </div>
              ))}
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}
