import React, { useEffect } from "react";
import { removeLambiKhado } from "../../../services/asyncFunctions";

function SessionCard({
  selectedSessions,
  handleSelection,
  handleSessionTypeSelection,
  id,
  yes,
  no,
  title,
  isLambi,
  isKhado,
  matchId,
  refetch,
  removeSlots
}) {
  function isSelected() {
    if (selectedSessions.some((item) => item.id == id)) return true;
    else return false;
  }

  function getSessionTypeValue(id, keyName) {
    const session = selectedSessions.find((item) => item.id === id);

    if (session && session.type && session.type[keyName] !== undefined) {
      return session.type[keyName];
    }
    return null;
  }

   function removeSessionCard(){
    console.log(isLambi, isKhado)
    if(!isLambi && !isKhado) return;
    let sessionType = isLambi ? "lambi" : "khado";
    removeSlots(sessionType);
  }

  return (
    <div className="d-flex flex-column mt-2">
      <div
        className="odds-card shadow-sm rounded p-3 pointer"
        onClick={() => handleSelection(id, isLambi, isKhado)}
      >
        <div className="text-black">{title}</div>
        <div className="d-flex align-items-center ">
          <div className="btn btn-danger p-2 mr-2">{no}</div>
          <div className="btn btn-primary p-2 mr-2">{yes}</div>
        </div>
        <button className="btn btn-danger p-2" onClick={(e)=> {e.stopPropagation();removeSessionCard()}}>Remove</button>
      </div>
      {(isSelected() || (isLambi || isKhado)) && (
        <div className="d-flex justify-content-between align-items-center radio-container text-white pointer animate__animated animate__fadeInDown animate__faster">
           <div
            className={`w-100 shadow-sm p-3 d-flex align-items-center justify-content-center rounded khado-button ${
              (getSessionTypeValue(id, "isKhado") || isKhado) && "khado-selected"
            }`}
            onClick={() =>
              handleSessionTypeSelection(id, { isLambi: false, isKhado: true })
            }
          >
            Slot 1(current)
          </div>
          <div
            className={`w-100 shadow-sm p-3 d-flex align-items-center justify-content-center rounded lambi-button ${
              (getSessionTypeValue(id, "isLambi") || isLambi) && "lambi-selected"
            }`}
            onClick={() =>
              handleSessionTypeSelection(id, { isLambi: true, isKhado: false })
            }
          >
           Slot 2(lambi)
          </div>
         
        </div>
      )}
    </div>
  );
}

export default SessionCard;
