import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { loadingToggleAction } from "../../../store/actions/AuthActions";

import addPhoto, { AWS_COMPETITION_BASE_URL, AWS_PHOTO_BASE_URL } from "../../pages/test";
import { editCompetition } from "../../../services/asyncFunctions";
import { toastError, toastSuccess } from "../PluginsMenu/Nestable/utils";

export default function EditCompetition({
  show,
  close,
  selectedCompetition,
  refetch,
}) {
  const [data, setData] = useState(selectedCompetition);
  const [isLoading, setIsLoading] = useState(false);

  const [imageFile, setImageFile] = useState(null);

  async function uploadBannerImage() {
    setIsLoading(true);
    let imageResponse = null;
    if (imageFile) {
      try{
        imageResponse = await addPhoto(imageFile, "competition");
       
        editCompetitionById(imageResponse?.imageName, "upload");
      }catch(Error){
        console.log(Error)
      

      }finally{
        setIsLoading(false);
      }
    } else {
      editCompetitionById("", "upload");
    }
  }

  async function editCompetitionById(image, type) {
    let payload;
    if(type == "upload"){
       payload = {
        telecast: data?.telecast ? data?.telecast : selectedCompetition?.telecast,
        logo: image ? image : data?.logo,
        competitionId: data?.id,
      };
    }else{
      payload = {
        telecast: data?.telecast ? data?.telecast : selectedCompetition?.telecast,
        logo: image,
        competitionId: data?.id,
      };
    }
    console.log(payload);
    setIsLoading(true);

    try {
      let result = await editCompetition(payload);
      console.log(result);
      toastSuccess("Updated Competition successfully!");
      close();
      refetch();
    } catch (e) {
      toastError("Couldn't save changes");
    } finally {
      setIsLoading(false);
    }
  }

  function handleImageFileChange(e) {
    setImageFile(e.target.files?.[0]);
  }

  useEffect(() => {
    if(show){
      setData(selectedCompetition);
      console.log(selectedCompetition)
    }
  }, [show]);

  return (
    <>
      <Modal className="fade vertically-centered" show={show}
        centered
        onHide={() => close()}
      >
        <Modal.Header>
          <Modal.Title>Edit Competition</Modal.Title>
          <Button variant="" className="close" onClick={() => close()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <form
            className="form-control h-100"
            onSubmit={(e) => {
              e.preventDefault();
              uploadBannerImage();
            }}
          >
            <input
              type="text"
              placeholder="Telecast"
              name="name"
              className="form-control mb-3"
              value={data?.telecast}
              required
              onChange={(e) => setData({ ...data, telecast: e.target.value })}
            />
            <input
              type="file"
              name=""
              id=""
              accept="image/png"
              className="mb-3"
              onChange={handleImageFileChange}
            />
            {data && data?.logo && 
            <>
            <img src={AWS_COMPETITION_BASE_URL + data.logo} width={80} height={80} className="mb-3"/>
            
            </>
            }
            <div className="d-flex align-items-center">
            <button className="btn btn-success shadow w-100 mr-2" disabled={isLoading}>{isLoading ? "Loading..." : "Update"}</button>
            <button className="btn btn-warning shadow w-100" type="button" onClick={()=> editCompetitionById("", "other")}>Remove Image</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
