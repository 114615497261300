import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BsThreeDotsVertical } from "react-icons/bs";
import ChannelDetails from "../modals/ChannelDetails";
import { DUMMY_IMAGE, S3_LATEST_URL } from "../../pages/test";

function ChatCard({
  createdAt,
  totalSubscribers,
  type,
  conversationData,
  description,
  channelName,
  tipperName,
  bots,
  channelId,
  tipperImage,
  image,
  link,
  deleteChannel,
  tipperId,
  setFakeViews,
  fakeViews
}) {
  const dispatch = useDispatch();
  const selectedConversation = null;
  const [showDetails, setShowDetails] = useState(false);

  function handleSelectConversation() {
    // dispatch(
    //   selectConversationForChat({
    //     conversationId: conversationData?._id,
    //     name: conversationData?.receiver?.name,
    //     Id: conversationData?.receiver?._id,
    //     image: conversationData?.receiver?.image,
    //     email: conversationData?.receiver?.email,
    //   })
    // );
  }

  // useEffect(() => {
  //   console.log(selectedConversation);
  // }, [selectedConversation]);
  return (
    <>
    <ChannelDetails onHide={() => setShowDetails(false)} show={showDetails}
    bots={bots}
    channelName={channelName}
    description={description}
    image={image}
    totalSubscribers={totalSubscribers}
    tipperName={tipperName}
    key={channelId}
    link={link}
    channelId={channelId}
    tipperId={tipperId}
    />
    <Card
      className={`chat-card ${
        selectedConversation?.conversationId == conversationData?._id &&
        "selected"
      }`}
      onClick={handleSelectConversation}
    >
      <img
        src={image ? S3_LATEST_URL + image : DUMMY_IMAGE}
        alt=""
        className="message-header_image"
      />
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-start flex-column  ml-3">
          <span className="header_name">{channelName}</span>
          <span className="header_state">Total Subs: {totalSubscribers}</span>
        </div>
        <div className="d-flex align-items-center">
          <Dropdown>
            <Dropdown.Toggle
              variant="light"
              className="p-1 bg-transparent border-0"
            >
              <BsThreeDotsVertical color="black" size={25} className="ml-3 " />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={()=> setShowDetails(true)}>Details</Dropdown.Item>
              <Dropdown.Item onClick={()=> setFakeViews(true, channelId, fakeViews)}>Default Message Views</Dropdown.Item>
              <Dropdown.Item onClick={()=> deleteChannel(channelId)}>Delete Channel</Dropdown.Item>

            </Dropdown.Menu>
            {/* <Dropdown.Menu>
              <Dropdown.Item>Edit</Dropdown.Item>
            </Dropdown.Menu> */}
          </Dropdown>
        </div>
      </div>
      {/* <div className="d-flex align-items-end flex-column  ml-3">
          <span className="header_time">{moment(createdAt).format("DD-MM-YYYY")}</span>
          <span className="header_state">Fake Subs: {bots}</span>
        </div> */}
    </Card>
    </>
  );
}

export default ChatCard;
