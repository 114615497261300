import React, { useState } from 'react'

function ScoreInput({type, balls, score, updatePlayer, currentBatsmen, sendUpdatedPlayerData, hideInput}) {


    function updatePlayerBalls(value){
     if(type == 1){
        updatePlayer({
           ...currentBatsmen,
           batsman1:{
            ...currentBatsmen?.batsman1,
            balls: value
           }
        })
     }else{
        updatePlayer({
            ...currentBatsmen,
            batsman2:{
             ...currentBatsmen?.batsman2,
             balls: value
            }
         })
     }
    }
    function updatePlayerScore(value){
        if(type == 1){
           updatePlayer({
              ...currentBatsmen,
              batsman1:{
               ...currentBatsmen?.batsman1,
               runs: value
              }
           })
        }else{
           updatePlayer({
               ...currentBatsmen,
               batsman2:{
                ...currentBatsmen?.batsman2,
                runs: value
               }
            })
        }
       }

 function submitData(){
    sendUpdatedPlayerData();
    hideInput();
 }      

  return (
    <div className='score-edit-input-container shadow'>
        <div className='d-flex flex-column w-100'>
        <input type="text" placeholder='score' value={type == 1 ? currentBatsmen?.batsman1?.runs : currentBatsmen?.batsman2?.runs} className='score-edit_input-score' onChange={(e)=> updatePlayerScore(e.target.value)}/>
        <input type="text"  placeholder='balls' value={type == 1 ? currentBatsmen?.batsman1?.balls : currentBatsmen?.batsman2?.balls} className='score-edit_input-balls' onChange={(e)=> updatePlayerBalls(e.target.value)}/>
        </div>
        <div className='score-edit_send' onClick={submitData}> {`>`} </div>
    </div>
  )
}

export default ScoreInput