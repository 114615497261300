import axios from "axios";
import swal from "sweetalert";
import { loadingToggleAction, loginConfirmedAction, logout } from "../store/actions/AuthActions";
import { getUserToken } from "./utils/generalUtils";
const baseApiUrl = process.env.REACT_APP_API_BASE_URL;
export function signUp(email, password) {
  //axios call

  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
    // returnSecureToken: true,
  };

  return axios.post(`${baseApiUrl}/admin/auth/login`, postData);
}
export function changePasswoard(oldPassword, newPassword) {
  const data = getUserToken();
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  console.log(newPassword, oldPassword, " user change passwoard");
  const postData = {
    newPassword,
    oldPassword,
    // returnSecureToken: true,
  };

  return axios.put(`${baseApiUrl}/admin/auth/changePassword`, postData, {
    headers: myHeaders,
  });
}
export function createUserTipper(
  userName,
  email,
  phoneNumber,
  userType,
  countryCode,
  profileImage
) {
  const data = getUserToken();
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  console.log(
    userName,
    email,
    phoneNumber,
    userType,
    countryCode,
    profileImage,
    "createuser llll"
  );
  const postData = {
    userName,
    email,
    phoneNumber,
    userType,
    countryCode,
    profileImage,
    // returnSecureToken: true,
  };

  return axios.post(`${baseApiUrl}/admin/user`, postData, {
    headers: myHeaders,
  });
}

export function editUserTipper(
  userName,
  email,
  phoneNumber,
  userType,
  countryCode,
  profileImage,
  userId
) {
  const data = getUserToken();
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  console.log(profileImage, "createuser edit ");
  const postData = {
    userName,
    email,
    phoneNumber,
    userType,
    countryCode,
    profileImage,
    userId,
    // returnSecureToken: true,
  };

  return axios.put(`${baseApiUrl}/admin/user`, postData, {
    headers: myHeaders,
  });
}
export function user(
  userName,
  email,
  phoneNumber,
  userType,
  createDate,
  limit,
  pageNumber,
  tipperType
) {
  const data = getUserToken();
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return axios.get(
    `${baseApiUrl}/admin/user/list?page=${pageNumber}&email=${email}&createdDate=${createDate}&phoneNumber=${phoneNumber}&userType=${userType}&userName=${userName}&limit=${limit}&tipperType=${tipperType}`,
    {
      headers: myHeaders,
    }
  );
}
export function deleteUser(id, userType) {
  console.log(id, userType, "auth delete user");
  const data = getUserToken();
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return axios.delete(
    `${baseApiUrl}/admin/user?userId=${id}&userType=${userType}`,

    {
      headers: myHeaders,
    }
  );
}
export function blockUser(userId, userType) {
  console.log(userId, userType, "auth block user");
  const data = getUserToken();
  const postData = {
    userId,
    userType,

    // returnSecureToken: true,
  };
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return axios.put(`${baseApiUrl}/admin/user/blockUnblock`, postData, {
    headers: myHeaders,
  });
}
export function userById(id, userType) {
  const data = getUserToken();
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return axios.get(
    `${baseApiUrl}/admin/user?userId=${id}&userType=${userType}`,
    {
      headers: myHeaders,
    }
  );
}

export function createBannerApi(title, type, image, url) {
  const data = getUserToken();
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  console.log(image, " banner create authservice");
  const postData = {
    title,
    type,
    image,
    url,
    // returnSecureToken: true,
  };

  return axios.post(`${baseApiUrl}/admin/banner`, postData, {
    headers: myHeaders,
  });
}
export function editBannerApi(title, type, image, url, bannerId) {
  const data = getUserToken();
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  console.log(image, " banner create authservice");
  const postData = {
    title,
    type,
    image,
    url,
    bannerId,
    // returnSecureToken: true,
  };

  return axios.put(`${baseApiUrl}/admin/banner`, postData, {
    headers: myHeaders,
  });
}

export function bannerTable(limit, page) {
  const data = getUserToken();
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return axios.get(
    `${baseApiUrl}/admin/banner/list?page=${page}&limit=${limit}`,
    {
      headers: myHeaders,
    }
  );
}
export function deleteBannerData(id) {
  console.log(id, "auth delete banner");
  const data = getUserToken();
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return axios.delete(
    `${baseApiUrl}/admin/banner?bannerId=${id}`,

    {
      headers: myHeaders,
    }
  );
}
export function formatError(errorResponse) {
  switch (errorResponse) {
    case "Password is Incorrect":
      //return 'Email already exists';
      swal("Incorrect password", "error");
      break;
    case "Email not found":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "ERROR":
      //return 'Invalid Password';
      swal("Oops", "Please check the login details", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}
export function bannerById(id) {
  console.log(id, "banner by id api auth file");
  const data = getUserToken();
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return axios.get(`${baseApiUrl}/admin/banner?bannerId=${id}`, {
    headers: myHeaders,
  });
}
export function saveTokenInLocalStorage(tokenDetails) {
  // tokenDetails.expireDate = new Date(
  //   new Date().getTime() + tokenDetails.expiresIn * 1000
  // );
  localStorage.setItem("userDetails", tokenDetails);
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  const token = getUserToken();
  if (!token) {
    dispatch(logout(history));
    return;
  }
  dispatch(loginConfirmedAction(token));
}
