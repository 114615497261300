import moment from 'moment/moment';
import React, { useState } from 'react'
import { Card } from 'react-bootstrap'

function BetsToggleCard({betStatus, matchId, date, title, toggleFunction}) {
const [status, setStatus] = useState(betStatus);



  return (
    <Card className='rounded border shadow-sm p-2 w-100'>
    <div className='d-flex align-items-center justify-content-between text-dark font-weight-bold text-monospace'>
  <div className=''>{title}</div>
  <div>{moment(date).format('D, MMM yyyy')} <span className='fs-12'>({moment(date).format('LT')})</span></div>
    </div>
    <div className='d-flex align-items-center justify-content-between mt-2'>
        <div className='text-success font-weight-normal'>Bets Active?</div>
        <div className='yes-no-switch'>
        <div className="yes-no-switch_background-slider" style={{ left: betStatus ? '50%' : '0%' }} />
            <div className={`${!betStatus && "yes-no-switch__active"}`} onClick={()=> toggleFunction(matchId, false)}>No</div>
            <div className={`${betStatus && "yes-no-switch__active"}`} onClick={()=> toggleFunction(matchId, true)}>Yes</div>
        </div>
    </div>
 </Card>
  )
}

export default BetsToggleCard