/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import icon1 from "../../../images/icon1.png";
import MatchLeaderboard from "../../pages/MatchLeaderboard";
import userManagementImg from "../../../images/theme/user-management.svg";

import Dashboardimg from "../../../images/theme/Dashboard.svg";
import transactionimg from "../../../images/theme/transaction.svg";
import polling from "../../../images/theme/poll-1.svg";
import promo from "../../../images/theme/promo.svg";
import tip from "../../../images/theme/tip.svg";
import matchleaderboardimg from "../../../images/theme/match-leaderboard.svg";

import upcomingmatchimg from "../../../images/theme/upcoming-match.svg";

import settingimg from "../../../images/theme/settings-2.svg";
import { authorizedRoles } from "../../components/PluginsMenu/Nestable/utils";
import { connect } from "react-redux";


class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  constructor(props) {
    super(props); // Call the superclass constructor and pass props to it
    // You can initialize state or perform other setup here
  }
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

  }
  state ={ 
    loveEmoji: false,
    showSideBar: true
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];


    /// Active menu
    let dashBoard = [
        "",
        "analytics",
        "events",
        "order-list",
        "general-customers",
        "reviews",
        "task",
      ],
      dashBoardHome = ["dashboardhome"],
      usermanagement = ["user-management"],
      transactions = ["transactions"],
      poolingsystem = ["poolingsystem"],
      promobanner = ["promobanner"],
      setting = ["setting"],
      tiproom = ["tiproom"],
      featuredMatches = ["featuredMatches"],
      inapppurchase = ["inapppurchase"],
      matchLeaderboard = ["matchleaderboard"],
      upcomingmatches = ["upcomingmatches"],
      notification = ["notifications"],
      oddsToMatch = ["match-to-odds"],
      gemsManagement = ["gems-management"],
      rewards = ["rewards-management"],
      matchManagement = ["match-management"],
      agent=["agent-match-listing"],
      matchControls = ["match-controls"],
      adsManagement = ["ads-management"],
      votingManagement = ["voting-management"],
      competetions = ["competetions"],
      matchbets = ["matchBets"],
      activematchbets= ["mark-match-complete"],
      adminSettings= ["admin-settings"],
      sessionBetsManagement = ["session-bet-management"],
      fantasyManagement = ["fantasy-management"],
      socialLinks = ["socials-management"],
      unsettledMatches = ["unsettled-matches"],







      app = [
        "app-profile",
        "app-calender",
        "email-compose",
        "email-inbox",
        "email-read",
        "ecom-product-grid",
        "ecom-product-list",
        "ecom-product-list",
        "ecom-product-order",
        "ecom-checkout",
        "ecom-invoice",
        "ecom-customers",
        "post-details",
        "ecom-product-detail",
      ],
      email = ["email-compose", "email-inbox", "email-read"],
      shop = [
        "ecom-product-grid",
        "ecom-product-list",
        "ecom-product-list",
        "ecom-product-order",
        "ecom-checkout",
        "ecom-invoice",
        "ecom-customers",
        "ecom-product-detail",
      ],
      charts = [
        "chart-rechart",
        "chart-flot",
        "chart-chartjs",
        "chart-chartist",
        "chart-sparkline",
        "chart-apexchart",
      ],
      bootstrap = [
        "ui-accordion",
        "ui-badge",
        "ui-alert",
        "ui-button",
        "ui-modal",
        "ui-button-group",
        "ui-list-group",
        "ui-media-object",
        "ui-card",
        "ui-carousel",
        "ui-dropdown",
        "ui-popover",
        "ui-progressbar",
        "ui-tab",
        "ui-typography",
        "ui-pagination",
        "ui-grid",
      ],
      plugins = [
        "uc-select2",
        "uc-nestable",
        "uc-sweetalert",
        "uc-toastr",
        "uc-noui-slider",
        "map-jqvmap",
        //"post",
      ],
      redux = ["todo", "form-redux", "form-redux-wizard"],
      widget = ["widget-basic"],
      forms = [
        "form-element",
        "form-wizard",
        "form-editor-summernote",
        "form-pickers",
        "form-validation-jquery",
      ],
      table = [
        "table-bootstrap-basic",
        "table-datatable-basic",
        "table-sorting",
        "table-filtering",
      ],
      pages = [
        "page-register",
        "page-login",
        "page-lock-screen",
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ],
      error = [
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ];
      


const getPathForSubAdmin = (path)=>{
  if(this.props.userType == "admin") return true;
  else if(JSON.stringify(this.props.roles).includes(path)) return true;
  return false;
}



    return (
      <>
      {this.state.showSideBar && <div className="deznav" style={{ zIndex: 6 }}>
        {!this.props.showLoading && <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li
              className={`${dashBoardHome.includes(path) ? "mm-active" : ""}`}
            >
              <Link
                to="/dashboardhome"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img
                    src={Dashboardimg}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            {getPathForSubAdmin(usermanagement[0]) && <li
              className={`${usermanagement.includes(path) ? "mm-active" : ""}`}
            >
              <Link
                to="/user-management"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img
                    src={userManagementImg}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">User management</span>
              </Link>
            </li>}
            {getPathForSubAdmin(oddsToMatch[0]) && <li className={`${oddsToMatch.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="/match-to-odds"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img src={promo} style={{ width: "20px", height: "21px" }} />
                </i>
                <span className="nav-text">Match to Odds</span>
              </Link>
            </li>}
            {getPathForSubAdmin(gemsManagement[0]) &&<li className={`${gemsManagement.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="/gems-management"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img src={promo} style={{ width: "20px", height: "21px" }} />
                </i>
                <span className="nav-text">Shop Section</span>
              </Link>
            </li>}
            {getPathForSubAdmin(matchControls[0]) &&<li className={`${matchControls.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="/match-controls"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img src={promo} style={{ width: "20px", height: "21px" }} />
                </i>
                <span className="nav-text">Match controls</span>
              </Link>
            </li>}
            {getPathForSubAdmin(adsManagement[0]) && <li
              className={`${adsManagement.includes(path) ? "mm-active" : ""}`}
            >
              <Link
                to="/ads-management"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img
                    src={promo}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">Ads manager</span>
              </Link>
            </li>}

            {getPathForSubAdmin(matchLeaderboard[0]) &&<li
              className={`${
                matchLeaderboard.includes(path) ? "mm-active" : ""
              }`}
            >
              <Link
                to="/matchleaderboard"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img
                    src={matchleaderboardimg}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">Match Leaderboard</span>
              </Link>
            </li>}
            {getPathForSubAdmin(matchManagement[0]) &&<li className={`${matchManagement.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="/match-management"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img
                    src={upcomingmatchimg}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">Match Management</span>
              </Link>
            </li>}
           {getPathForSubAdmin(matchbets[0]) && <li className={`${matchbets.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="/matchBets"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img
                    src={upcomingmatchimg}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">Match Bets</span>
              </Link>
            </li>}
            {getPathForSubAdmin(sessionBetsManagement[0]) && <li
              className={`${sessionBetsManagement.includes(path) ? "mm-active" : ""}`}
            >
              <Link
                to="/session-bet-management"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img
                    src={userManagementImg}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">Session management</span>
              </Link>
            </li>}
            {getPathForSubAdmin(unsettledMatches[0]) && <li
              className={`${unsettledMatches.includes(path) ? "mm-active" : ""}`}
            >
              <Link
                to="/unsettled-matches"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img
                    src={userManagementImg}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">Unsettled Matches</span>
              </Link>
            </li>}
            {getPathForSubAdmin(fantasyManagement[0]) && <li
              className={`${fantasyManagement.includes(path) ? "mm-active" : ""}`}
            >
              <Link
                to="/fantasy-management"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img
                    src={upcomingmatchimg}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">Fantasy management</span>
              </Link>
            </li>}

            {/* {getPathForSubAdmin(fancyManagement[0]) && <li
              className={`${fancyManagement.includes(path) ? "mm-active" : ""}`}
            >
              <Link
                to="/fancy-management"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img
                    src={userManagementImg}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">Fancy management</span>
              </Link>
            </li>} */}
            {getPathForSubAdmin(activematchbets[0]) &&<li className={`${activematchbets.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="/mark-match-complete"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img
                    src={upcomingmatchimg}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">Active Bet Matches</span>
              </Link>
            </li>}
            {getPathForSubAdmin(competetions[0]) &&<li className={`${competetions.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="/competitions"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img
                    src={upcomingmatchimg}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">Competition</span>
              </Link>
            </li>}
            {getPathForSubAdmin(socialLinks[0]) &&<li className={`${socialLinks.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="/socials-management"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img
                    src={upcomingmatchimg}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">Social Links</span>
              </Link>
            </li>}
            {getPathForSubAdmin(votingManagement[0]) &&<li className={`${votingManagement.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="/voting-management"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img
                    src={upcomingmatchimg}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">Voting Management</span>
              </Link>
            </li>}

            {getPathForSubAdmin(rewards[0]) &&<li className={`${rewards.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="/rewards-management"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img
                    src={upcomingmatchimg}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">Coins/Rewards</span>
              </Link>
            </li>}
            {getPathForSubAdmin(tiproom[0]) &&<li className={`${tiproom.includes(path) ? "mm-active" : ""}`}>
              <Link to="/tiproom" className="ai-icon d-flex align-items-center">
                <i>
                  <img
                    src={upcomingmatchimg}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">Tipper Room</span>
              </Link>
            </li>}
            {getPathForSubAdmin(notification[0]) &&<li className={`${notification.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="/notifications"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img
                    src={upcomingmatchimg}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">Notification</span>
              </Link>
            </li>}
            {getPathForSubAdmin(agent[0]) &&<li className={`${agent.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="/agent-match-listing"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img
                    src={upcomingmatchimg}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">Agent</span>
              </Link>
            </li>}
            {getPathForSubAdmin(adminSettings[0]) &&<li className={`${adminSettings.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="/admin-settings"
                className="ai-icon d-flex align-items-center"
              >
                <i>
                  <img
                    src={upcomingmatchimg}
                    style={{ width: "20px", height: "21px" }}
                  />
                </i>
                <span className="nav-text">Admin Settings</span>
              </Link>
            </li>}
              
          </MM>
        </PerfectScrollbar>}
      </div>}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.auth.roles,
    userType: state.auth.userType,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(SideBar);