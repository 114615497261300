import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import addPhoto from "../../pages/test";
export default function GemsModal({ show, onHide, submitFunction, deviceType }) {
    const [formData, setFormData] = useState({
        name: '',
        gems: '',
        amount:'',
        iosPackageId: '',
        image: '',
    })
    const [imageFile, setImageFile] = useState([]);

    function handleFormChange(e){
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }

    function resetForm(){
        setFormData({
            name: '',
            gems: '',
            amount:'',
            iosPackageId: '',
        });
    }
  
  async function uploadPhoto(e){
    e.preventDefault();
    try{
      let response = await addPhoto(imageFile[0], "packageImages");
      console.log(response);
      if (response.imageName) {
        submitData(response.imageName);
      }else{
        return;
      }
    }catch(e){
console.log(e)
    }
    
  }  
    
  function submitData(imageUrl) {
    console.log(formData);
    submitFunction({...formData,image: imageUrl});
    resetForm();
    onHide();
  }



  useEffect(()=>{
    setFormData({
     ...formData,
      deviceType
  });
  },[deviceType])
  return (
    <>
      <Modal
        size="md"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => onHide()}
      >
        <Modal.Header>
          <Modal.Title>Package management</Modal.Title>
          <Button variant="" className="close" onClick={() => onHide()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={uploadPhoto}
            className="d-flex align-items-center flex-wrap form-group"
          >
            <label htmlFor="packageName">Package name</label>
            <input
              id="packageName"
              type="text"
              className="custom-text-input"
              placeholder="Package Name"
              name="name"
              required
              value={formData.name}
              onChange={handleFormChange}

            />
            <label htmlFor="numberOfDiamond">No. of diamonds</label>
            <input
              type="number"
              id="numberOfDiamond"
              placeholder="Number of Diamonds"
              name="gems"
              required
              className="custom-text-input"
              value={formData.gems}
              onChange={handleFormChange}
            />
            <label htmlFor="packageCost">Package Cost</label>
            <input
              type="number"
              id="packageCost"
              placeholder="Cost"
              name="amount"
              required
              className="custom-text-input"
              value={formData.amount}
              onChange={handleFormChange}

            />
            
           
            <input type="file" className="mt-3 mb-3" accept="image/*" onChange={(e) => setImageFile(e.target.files)}/>


            {deviceType == "iPhone" && 
            <>
            <label htmlFor="packageId">iOS Package ID</label>
            <input
              type="text"
              id="packageId"
              placeholder="packageId"
              name="iosPackageId"
              required
              className="custom-text-input"
              value={formData.iosPackageId}
              onChange={handleFormChange}

            />
            </>
            }

            <button className="btn btn-warning m-auto">Submit</button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
