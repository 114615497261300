import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { getBatsmanData } from "../../../constants/agentEvents";
import { PLAYER_IMAGE_BASE_URL } from "../../../services/endpoints";
export default function BatsmanModal({
  show,
  onHide,
  playerList,
  handleBatsmanSelection,
  batsmanNumber,
  currentBatsmen
}) {


  function getPlayersWhoHaveNotBattedYet(){
    if(playerList?.length){
      // let filteredPlayers = playerList.filter(item => !item.hasBatted);
      return playerList;
    }else {
      return [];
    }
  }
    
  function getPlayerImage(playerName){
    return PLAYER_IMAGE_BASE_URL + playerName.toLowerCase().split(" ").join("") + ".pngs"
  }

  function handlePlayerSelect(newPlayer){
    if(batsmanNumber == 1){
        let batsmanData = getBatsmanData(currentBatsmen?.batsman1 ?? null, newPlayer, 1)
        console.log("b1", batsmanData)
        handleBatsmanSelection({...currentBatsmen, batsman1: batsmanData})
    }else{
        let batsmanData = getBatsmanData(currentBatsmen?.batsman2 ?? null, newPlayer, 2)
        console.log("b2", batsmanData)
        handleBatsmanSelection({...currentBatsmen, batsman2: batsmanData})
    }
    onHide();
  }

  return (
    <>
      <Modal
        size="sm"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => onHide()}
      >
        <Modal.Header>
          <Modal.Title>Pick A Batsman</Modal.Title>
          <Button variant="" className="close" onClick={() => onHide()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body scrollable={true}>
          {getPlayersWhoHaveNotBattedYet().map(item => <div className={`player-select-button ${item.isSubstitute == true && 'substitute-border'}`} onClick={() => handlePlayerSelect(item)}>
            {/* <img src={getPlayerImage(item.name)} alt="" width={50} height={50} className="rounded"  /> */}
         <div className='d-flex flex-column align-items-center'>
          <p className={`m-0 player-name `}>{item.name}</p>
          <p className={`m-0 player-role `}>{item.role}</p>
         </div>
          </div>)}
         
        </Modal.Body>
      </Modal>
    </>
  );
}
