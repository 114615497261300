import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import {
  getMatchLoad,
  getMatcheBetsForAllUsers,
  getMatches,
} from "../../services/asyncFunctions";
import {
  getProfitAndLoss,
  getResult,
  getTypeStyles,
  toastError,
} from "../components/PluginsMenu/Nestable/utils";
import moment from "moment";
import ReactPaginate from "react-paginate";
import ExportMatchBets from "../components/modals/ExportMatchBets";

function MatchBetsLive() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchString, setSearchString] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isBetSectionVisible, setIsBetSectionVisible] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [betsListing, setBetsListing] = useState(null);
  const [totalBetPages, setTotalBetPages] = useState(1);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [showExportModal, setShowExportModal] = useState(false);
  const [isMatchLoadFetching, setIsMatchLoadFetching] = useState(false);
  const [matchLoadData, setMatchLoadData] = useState({
    teama:{
      load: 0,
      name: "",
      short_name: ""
    },
    teamb:{
      load: 0,
      name: "",
      short_name: ""
    }
  });

  async function fetchMatches() {
    setIsLoading(true);
    try {
      let result = await getMatches();
      setData(result?.data?.data);
      setFilteredMatches(result?.data?.data);
    } catch (e) {
      toastError("Could not get matches");
    } finally {
      setIsLoading(false);
    }
  }

  const filterMatches = (type) => {
    if (type == "date") {
      const results = data.filter(
        (match) =>
          moment(match.date_start).format("YYYY-MM-DD") === selectedDate
      );
      setFilteredMatches(results);
    } else {
      const results = data.filter((match) =>
        match.title.toLowerCase().includes(searchString)
      );
      setFilteredMatches(results);
    }
  };

  async function getLoadForMatch(){
    setIsMatchLoadFetching(true);
    try{
      let response = await getMatchLoad(selectedMatch);
      console.log(response);
      setMatchLoadData({
        teama: {
          load: response?.data?.data?.teama?.load,
          name: response?.data?.data?.teama?.name,
          short_name: response?.data?.data?.teama?.short_name
        },
        teamb: {
          load: response?.data?.data?.teamb?.load,
          name: response?.data?.data?.teamb?.name,
          short_name: response?.data?.data?.teamb?.short_name
        },
      })
    }catch(error){
      toastError(error?.response?.data?.message);
    }finally{
      setIsMatchLoadFetching(false);
    }
  }

  async function getBetsForMatch(type) {
    setIsBetSectionVisible(true);
    setIsLoading(true);
    try {
      let response = await getMatcheBetsForAllUsers(
        selectedMatch,
        type == "initial" ? 0 : page,
        type == "initial" ? 20 : limit
      );
      console.log(response);
      setBetsListing(response.data.data.bets);
      setTotalBetPages(Math.ceil(response.data.data.count / limit));
      // getLoadForMatch();
    } catch (e) {
      toastError("Could not get bets!");
    } finally {
      setIsLoading(false);
    }
  }

  const handlePageClick = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected;
    setPage(currentPage);
  };

  function goBackToMatches() {
    setPage(0);
    setLimit(20);
    setBetsListing(null);
    setTotalBetPages(1);
    setSelectedMatch(null);
    setIsBetSectionVisible(false);
  }

  function refreshBets(){
    getBetsForMatch("paginate");
    getLoadForMatch();
  }


  useEffect(() => {
    fetchMatches();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      filterMatches("date");
    }
  }, [selectedDate]);

  useEffect(() => {
    if (searchString) {
      filterMatches("search");
    }
  }, [searchString]);

  useEffect(() => {
    if (selectedMatch) {
      getBetsForMatch("initial");
    }
  }, [selectedMatch]);

  useEffect(() => {
    if (isBetSectionVisible) {
      getBetsForMatch("paginate");
    }
  }, [page, limit]);

  useEffect(()=>{
    if(isBetSectionVisible){
      getLoadForMatch();
    }

  },[isBetSectionVisible])
  return (
    <>
    <ExportMatchBets show={showExportModal} close={()=> setShowExportModal(false)} matchId={selectedMatch}/>
    <Card>
      <Card.Header>
        <Card.Title>Match Bets</Card.Title>
      </Card.Header>
      {!isBetSectionVisible && (
        <Card.Body>
          {isLoading && <h2 className="text-center">Loading...</h2>}
          <div className="d-flex align-items-center justify-content-between">
            <input
              type="search"
              className="form-control mr-3"
              placeholder="Search match title"
              onChange={(e) => setSearchString(e.target.value)}
              value={searchString}
            />
            <input
              type="date"
              className="form-control"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
          </div>
          <div className="table-responsive">
            <div id="order_lis" className="dataTables_wrapper no-footer">
              <table
                id="example5"
                className="display mb-4 dataTablesCard dataTable no-footer w-100 "
                style={{ minWidth: 845 }}
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      className="sorting_asc"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Match Title
                    </th>
                    <th
                      className="sorting"
                      tabIndex={1}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Date
                    </th>
                    <th
                      className="sorting"
                      tabIndex={2}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredMatches &&
                    filteredMatches.map((item) => (
                      <tr role="row" key={item._id}>
                        <td className="pointer match-name">{item.title}</td>
                        <td>{moment(item.date_start).format("DD-MM-YYYY")}</td>
                        <td>
                          <span
                            className="btn btn-sm light btn-warning"
                            onClick={() => setSelectedMatch(item._id)}
                          >
                            View
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </Card.Body>
      )}
      {isBetSectionVisible && (
        <Card.Body>
          {isLoading && <h2 className="text-center">Loading...</h2>}
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex flex-column">
              <label htmlFor="">Items per page</label>
              <select value={limit} onChange={(e)=> setLimit(e.target.value)}>
                {Array.from({ length: 10 }, (_, index) => (index + 1) * 10).map(
                  (value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className="d-flex align-items-center">
                <div className={`d-flex  mr-3 align-items-center border p-2 rounded text-white bg-secondary ${matchLoadData?.teama?.load < 0 && "bg-danger"} ${matchLoadData?.teama?.load > 0 && "bg-success"}`}>
                    <div className="mr-2">{matchLoadData?.teama?.short_name}</div>
                    <div>{matchLoadData?.teama?.load}</div>
                </div>
                <div className={`d-flex  align-items-center border p-2 rounded text-white bg-secondary ${matchLoadData?.teamb?.load < 0 && "bg-danger"} ${matchLoadData?.teamb?.load > 0 && "bg-success"}`}>
                    <div className="mr-2">{matchLoadData?.teamb?.short_name}</div>
                    <div>{matchLoadData?.teamb?.load}</div>
                </div>
            </div>
            <div className="d-flex align-items-center ">
            <button className="btn btn-primary mr-2" onClick={goBackToMatches}>
              Go Back
            </button>
            <button className="btn btn-warning mr-2" onClick={refreshBets}>
              Refresh
            </button>
            <button className="btn btn-warning" onClick={()=> setShowExportModal(true)} >
              Export All
            </button>
            </div>
          </div>
          <div className="table-responsive">
            <div id="order_lis" className="dataTables_wrapper no-footer">
              <table
                id="example5"
                className="display mb-4 dataTablesCard dataTable no-footer w-100 "
                style={{ minWidth: 845 }}
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      className="sorting_asc"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-sort="ascending"
                      aria-label="Order ID: activate to sort column descending"
                      style={{ width: "71.3333px" }}
                    >
                      User Type
                    </th>
                    <th
                      className="sorting_asc"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-sort="ascending"
                      aria-label="Order ID: activate to sort column descending"
                      style={{ width: "71.3333px" }}
                    >
                      User Name
                    </th>
                    <th
                      className="sorting_asc"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-sort="ascending"
                      aria-label="Order ID: activate to sort column descending"
                      style={{ width: "71.3333px" }}
                    >
                      Is Session Bet
                    </th>
                    <th
                      className="sorting_asc"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-sort="ascending"
                      aria-label="Order ID: activate to sort column descending"
                      style={{ width: "71.3333px" }}
                    >
                      Team/Session title
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Date: activate to sort column ascending"
                      style={{ width: "74.6667px" }}
                    >
                      Date
                    </th>

                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      style={{ width: "98.6667px" }}
                    >
                      Stake
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      style={{ width: "98.6667px" }}
                    >
                      Profit
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      style={{ width: "98.6667px" }}
                    >
                      Type
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      style={{ width: "98.6667px" }}
                    >
                      odds(Odds Score)
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      style={{ width: "98.6667px" }}
                    >
                      Result
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {betsListing &&
                    betsListing.map((item) => (
                      <tr key={item._id} className="text-left mb-2">
                        <td>{item?.user ? "FAN" : "TIPPER"}</td>
                        <td>
                          {item?.user
                            ? item?.user?.userName
                            : item?.tipper?.userName}
                        </td>
                        <td>{item?.isSession ? "Yes" : "No"}</td>
                        <td>{item?.team ? item?.team : item?.title}</td>
                        <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                        <td>{item?.coins}</td>
                        <td>{getProfitAndLoss(item)}</td>
                        <td>
                          <div className={`${getTypeStyles(item?.type)}`}>
                            {item?.type.toUpperCase()}
                          </div>
                        </td>
                        <td>
                          {item?.odd}({item?.oddScore})
                        </td>
                        <td>{getResult(item)}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          {totalBetPages > 1 && (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={totalBetPages}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              forcePage={page}
            />
          )}
        </Card.Body>
      )}
    </Card>
    </>
  );
}

export default MatchBetsLive;
