import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { checkEvents } from "../../../constants/agentEvents";

export default function CheckEvents({ checkShow, checkClose, actionFunction }) {
  let errorsObj = { userName: "", email: "", phoneNumber: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [apiError, setApiError] = useState("");
  const [loadingMatches, setLoadingMatches] = useState(false);
  const [matchList, setMatchList] = useState([]);



  function handleItemSelect(data){
    actionFunction(data);
    checkClose();
  }
  return (
    <>
    

      <Modal
        size="xl"
        className="fade vertically-centered"
        show={checkShow}
        centered
        onHide={() => checkClose()}
      >
        <Modal.Header>
          <Modal.Title>Check Events</Modal.Title>
          <Button variant="" className="close" onClick={() => checkClose()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        {loadingMatches === false && (
          <Modal.Body>
            <div className="d-flex align-items-center flex-wrap form-group">
              {checkEvents.map((item) => (
                <div key={item} className="modal-event btn btn-secondary" 
                // onTouchStart={()=> handleItemSelect(item)}
                onClick={()=> handleItemSelect(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}
