import React from 'react'
import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux';

function MessageHeader() {
  const selectedConversation = useSelector(state=> state.session.selectedConversationDetails);

  return (
    <Card className='message-header shadow-md'>
    {selectedConversation &&  <div className='d-flex align-items-center'>
      <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png" alt="" className='message-header_image'/>
       <div className='d-flex align-items-start flex-column  ml-3'>
        <span className='header_name'>{selectedConversation.otherPersonName}</span>
        <span className='header_state'>typing...</span>
      </div>
      </div>}
    </Card>
  )
}

export default MessageHeader