import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Card,
  Col,
  Button,
  Modal,
  Container,
  Spinner,
} from "react-bootstrap";
import  toast  from "react-hot-toast";
import { getMatchReview, putMatchReview } from "../../services/asyncFunctions";
export default function MatchReviewsModal({ show, onHide, matchId }) {
  const [isChecked, setIsChecked] = useState(false);
  const [reviewObject, setReviewObject] = useState({
    batting: {
      batting_team_review_available: 0,
      batting_team_review_success: 0,
      batting_team_review_failed: 0,
    },
    bowling: {
      bowling_team_review_available: 0,
      bowling_team_review_success: 0,
      bowling_team_review_failed: 0,
    },
  });
  const [isLoading, setIsLoading] = useState(true);
  const [currentMatchId, setCurrentMatchId] = useState(matchId);
  function handleUpdate(inning, type, value) {
    if (inning == "batting") {
      setReviewObject({
        bowling: { ...reviewObject.bowling },
        batting: {
          ...reviewObject.batting,
          [`batting_team_review_${type}`]: value,
        },
      });
    } else {
      setReviewObject({
        batting: { ...reviewObject.batting },
        bowling: {
          ...reviewObject.bowling,
          [`bowling_team_review_${type}`]: value,
        },
      });
    }
  }

  async function getReviews() {
    setIsLoading(true);
    try {
      const response = await getMatchReview(matchId);
      if(response.data.data?.review){
        setReviewObject({
          ...response.data.data.review,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateMessage() {
    // if(!message.trim() || !matchId)
    setIsLoading(true);
    let payload = {
      matchId,
      review: {
        batting: {
          batting_team_review_available:
            String(reviewObject.batting.batting_team_review_available),
          batting_team_review_success:
            String(reviewObject.batting.batting_team_review_success),
          batting_team_review_failed:
            String(reviewObject.batting.batting_team_review_failed),
        },
        bowling: {
          bowling_team_review_available:
            String(reviewObject.bowling.bowling_team_review_available),
          bowling_team_review_success:
            String(reviewObject.bowling.bowling_team_review_success),
          bowling_team_review_failed:
            String(reviewObject.bowling.bowling_team_review_failed),
        },
      },
    };
    console.log(payload);
    try {
      const response = await putMatchReview(payload);
      console.log(response);
      toast.success("Message Updated");
      onHide();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (show) {
      getReviews();
    }
  }, [show]);
  return (
    <>
      <Modal
        size="lg"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => onHide()}
      >
        <Modal.Header>
          <Modal.Title>Match Review</Modal.Title>
          <Button variant="" className="close" onClick={() => onHide()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {!isLoading && (
            <div className="d-flex align-items-start flex-column flex-wrap form-group">
              <h4>Batting</h4>
              <div className="d-flex align-items-center">
                <div className="mr-2">
                  <label htmlFor="">Reviews Available</label>
                  <input
                    type="number"
                    className="custom-text-input"
                    value={reviewObject.batting.batting_team_review_available}
                    onChange={(e) =>
                      handleUpdate("batting", "available", e.target.value)
                    }
                  />
                </div>
                <div className=" mr-2">
                  <label htmlFor="">Reviews Success</label>
                  <input
                    type="number"
                    className="custom-text-input"
                    value={reviewObject.batting.batting_team_review_success}
                    onChange={(e) =>
                      handleUpdate("batting", "success", e.target.value)
                    }
                  />
                </div>
                <div className="">
                  <label htmlFor="">Reviews Failed</label>
                  <input
                    type="number"
                    className="custom-text-input"
                    value={reviewObject.batting.batting_team_review_failed}
                    onChange={(e) =>
                      handleUpdate("batting", "failed", e.target.value)
                    }
                  />
                </div>
              </div>
              <h4>Bowling</h4>
              <div className="d-flex align-items-center">
                <div className="mr-2">
                  <label htmlFor="">Reviews Available</label>
                  <input
                    type="number"
                    className="custom-text-input"
                    value={reviewObject.bowling.bowling_team_review_available}
                    onChange={(e) =>
                      handleUpdate("bowling", "available", e.target.value)
                    }
                  />
                </div>
                <div className=" mr-2">
                  <label htmlFor="">Reviews Success</label>
                  <input
                    type="number"
                    className="custom-text-input"
                    value={reviewObject.bowling.bowling_team_review_success}
                    onChange={(e) =>
                      handleUpdate("bowling", "success", e.target.value)
                    }
                  />
                </div>
                <div className="">
                  <label htmlFor="">Reviews Failed</label>
                  <input
                    type="number"
                    className="custom-text-input"
                    value={reviewObject.bowling.bowling_team_review_failed}
                    onChange={(e) =>
                      handleUpdate("bowling", "failed", e.target.value)
                    }
                  />
                </div>
              </div>
              <button
                className="btn btn-warning m-auto mt-3"
                type="button"
                onClick={updateMessage}
              >
                Update
              </button>
            </div>
          )}
          {isLoading && <Spinner variant="info" animation="border" />}
        </Modal.Body>
      </Modal>
    </>
  );
}
