import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';

function FakeViewsModal({ show, close, handleSubmit, viewsForSelectedChannel }) {
    const [ viewCountValue, setViewCountValue] = useState(viewsForSelectedChannel);
    const handleInputChange = (e) => {
        setViewCountValue(e.target.value)
    }

    function submit(){
        if(viewCountValue > 0){
            handleSubmit(viewCountValue)
        }
    }

useEffect(()=>{
    setViewCountValue(viewsForSelectedChannel)
},[viewsForSelectedChannel])
  return (
    <Modal className="fade" show={show}>
    <Modal.Header>
      <Modal.Title>Update Default Message Views</Modal.Title>
      <Button variant="" className="close" onClick={() => close()}>
        <span>&times;</span>
      </Button>
    </Modal.Header>
  
      <Modal.Body>
        <form className="form-control h-100" onSubmit={(e) => {e.preventDefault(); submit()}}>
        <input type="text" placeholder="Enter view count" name="name" className="form-control mb-3" value={viewCountValue} required onChange={handleInputChange}/>
        <button className="btn btn-success shadow w-100">Update</button>
        </form>
      </Modal.Body>
    
  </Modal>
  )
}

export default FakeViewsModal