import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";

export default function UserBetsModal({
  betsData,
  show,
  close,
  isLoading,
  totalPages,
  pageNumber,
  setCurrentPage,
}) {
  function getResult(bet) {
    if (bet?.result === undefined) return "Pending";
    else {
      if (bet?.result?.isWin) return "Won";
      else return "Lost";
    }
  }

  function getTypeStyles(type) {
    if (!type) return "";
    else if (type == "yes" || type == "back")
      return "px-2 py-1 btn btn-primary ";
    else if (type == "no" || type == "lay") return "px-2 py-1 btn btn-danger ";
    else return "";
  }

  function getProfitAndLoss(betObject) {
    if (getResult(betObject) == "Pending")
      return `Expected +${betObject?.profit}`;
    else if (getResult(betObject) == "Lost") return `-${betObject?.profit}`;
    else return `+${betObject?.profit}`;
  }

  const handlePageClick = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected;
    setCurrentPage(currentPage);
  };

  return (
    <>
      <Modal
        size="xl"
        className="fade"
        show={show}
        centered
        onHide={() => close()}
      >
        <Modal.Header>
          <Modal.Title>User Bets</Modal.Title>
          <Button variant="" className="close" onClick={() => close()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>

        <Modal.Body style={{ minHeight: "500px" }}>
          {!isLoading && (
            <div className="row w-100">
              <div className="col-12">
                <div className="table-responsive">
                  <div id="order_lis" className="dataTables_wrapper no-footer">
                    <table
                      id="example5"
                      className="display mb-4 dataTablesCard dataTable no-footer w-100 "
                      style={{ minWidth: 845 }}
                      role="grid"
                      aria-describedby="example5_info"
                    >
                      <thead>
                        <tr role="row">
                          <th
                            className="sorting_asc"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="ascending"
                            aria-label="Order ID: activate to sort column descending"
                            // style={{ width: "71.3333px" }}
                          >
                            Is Session Bet
                          </th>
                          <th
                            className="sorting_asc"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="ascending"
                            aria-label="Order ID: activate to sort column descending"
                            // style={{ width: "71.3333px" }}
                          >
                            Team/Session title
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Date: activate to sort column ascending"
                            // style={{ width: "74.6667px" }}
                          >
                            Date
                          </th>

                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            // style={{ width: "98.6667px" }}
                          >
                            Stake
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            // style={{ width: "98.6667px" }}
                          >
                            Profit
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            // style={{ width: "98.6667px" }}
                          >
                            Type
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            // style={{ width: "98.6667px" }}
                          >
                            odds(Odds Score)
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            // style={{ width: "98.6667px" }}
                          >
                            Result
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {betsData &&
                          betsData.map((item) => (
                            <tr key={item._id} className="text-left mb-2">
                              <td>{item?.isSession ? "Yes" : "No"}</td>
                              <td>{item?.team ? item?.team : item?.title}</td>
                              <td>
                                {moment(item?.createdAt).format("DD-MM-YYYY")}
                              </td>
                              <td>{item?.coins}</td>
                              <td>{getProfitAndLoss(item)}</td>
                              <td>
                                <div className={`${getTypeStyles(item?.type)}`}>
                                  {item?.type.toUpperCase()}
                                </div>
                              </td>
                              <td>
                                {item?.odd}({item?.oddScore})
                              </td>
                              <td>{getResult(item)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {totalPages > 1 && (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={totalPages}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              forcePage={pageNumber}
            />
          )}
          <div className="d-flex align-items-center justify-content-center h-100 w-100">
            {isLoading && <h3 className="text-center">Loading...</h3>}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
