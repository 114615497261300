import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import toast from "react-hot-toast";
import ReactSelect from "react-select";
import { useDispatch } from "react-redux";
import { loadingToggleAction } from "../../../store/actions/AuthActions";
import { updateStatus } from "../../../services/asyncFunctions";

export default function AddNewBanner({ show, close, handleFileChange, submitBanner, handleInputChange }) {
  return (
    <>
      <Modal className="fade" show={show}>
        <Modal.Header>
          <Modal.Title>Add Banner</Modal.Title>
          <Button variant="" className="close" onClick={() => close()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
      
          <Modal.Body>
            <form className="form-control h-100" onSubmit={(e) => {e.preventDefault(); submitBanner()}}>
            <input type="text" placeholder="Enter Banner Title" name="name" className="form-control mb-3" required onChange={handleInputChange}/>
            <input type="text" name="url" placeholder="Enter Redirect URl" className="form-control mb-3" required onChange={handleInputChange}/>
            <input type="file" name="" id="" accept="image/*" className="mb-3" required onChange={handleFileChange}/>
            <button className="btn btn-success shadow w-100">Add Banner</button>
            </form>
          </Modal.Body>
        
      </Modal>
    </>
  );
}