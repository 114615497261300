import ReactPaginate from "react-paginate";

function UserBetMatchesTable({ tableData, openBetsModal, onMatchView }) {
  return (
    <div className="row w-100">
      <div className="col-12">
        <div className="table-responsive">
          <div id="order_lis" className="dataTables_wrapper no-footer">
            <table
              id="example5"
              className="display mb-4 dataTablesCard dataTable no-footer w-100 "
              style={{ minWidth: 845 }}
              role="grid"
              aria-describedby="example5_info"
            >
              <thead>
                <tr role="row">
                  <th
                    className="sorting_asc"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}

                    // style={{ width: "71.3333px" }}
                  >
                    Match Title
                  </th>
                  <th
                    className="sorting"
                    tabIndex={1}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}

                    // style={{ width: "74.6667px" }}
                  >
                    Date
                  </th>
                  <th
                    className="sorting"
                    tabIndex={2}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    // style={{ width: "74.6667px" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData &&
                  tableData.map((item) => (
                    <tr role="row" key={item._id}>
                      <td className="pointer match-name">{item.title}</td>
                      <td>{item.date_start}</td>
                      <td>
                        <span
                          className="btn btn-sm light btn-warning"
                          onClick={() => onMatchView(item._id, 0)}
                        >
                          View
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserBetMatchesTable;
