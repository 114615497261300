import React from 'react'
import Plus from "../../images/plus1.svg"
import Cross from "../../images/cross.svg"
import { PlaceholderAvatar, PLAYER_IMAGE_BASE_URL } from '../../services/endpoints'



function PlayerSelectionCard({data, isSelected, togglePlayer, type, isSubstitute}) {
    let playerImage = data.name.toLowerCase().split(" ").join("") + ".pngs"
    // console.log(PLAYER_IMAGE_BASE_URL + playerImage)

  function getSelectedbackgroundColor(isSelectedFlag, isSubstituteFlag){
     if(isSelectedFlag && isSubstituteFlag) return 'selected-player-substitute';
     else if(isSelectedFlag && !isSubstituteFlag) return 'selected-player';
     else return ""
  }
  return (
    <div className={`player-select-button ${isSelected(data.player_id ?? 0, type) && 'selected-player'} ${isSubstitute(data.player_id ?? 0, type) && 'selected-player-substitute'}`} onClick={() => togglePlayer({playerId:data.player_id, name: data.name}, type)}>
         <img src={PLAYER_IMAGE_BASE_URL + playerImage} alt="" width={50} height={50} className="rounded"  />
         <div className='d-flex flex-column align-items-center'>
          <p className={`m-0 player-name ${isSelected(data.player_id ?? 0, type) ? "" :'font-weight-light'}`}>{data.name}</p>
          <p className={`m-0 player-role ${isSelected(data.player_id ?? 0, type) ? "" :'font-weight-light'}`}>{data.role}</p>
         </div>
         <div className='icon'>
          {!isSelected(data.player_id ?? 0, type) && <img src={Plus} alt="" width={20} height={20}/>}
          {isSelected(data.player_id ?? 0, type) && <img src={Cross} alt="" width={20} height={20}/>}
         </div>
         {data.isCaptian && <span className='text-danger font-weight-bold captian-flag'>C</span>}
    </div>
  )
}
export default PlayerSelectionCard;