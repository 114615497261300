import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { getFootballLeagues, getManagementMatches, getScoreMatches, updateMatchStateOnServer } from "../../services/asyncFunctions";
import Switch from "react-switch";
import { useDispatch } from "react-redux";
import { loadingToggleAction } from "../../store/actions/AuthActions";
import moment from "moment";


function MatchManagement() {
  const [loader, setLoader] = useState(false);
  const [matches, setMatches] = useState(null);
  const [footballMatches, setFootballMatches] = useState(null);
  const [isCricketShown, setIsCricketShown] = useState(true);

  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedFootballDate, setSelectedfootballDate] = useState(null);
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [filteredFootballMatches, setFilteredFootballMatches] = useState([]);




  const getLiveMatches = async () => {
    setLoader(true);
    try {
      const response = await getScoreMatches();
      setMatches(response.data.data);
      setFilteredMatches(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };
 const getFootballMatches = async()=>{
  setLoader(true);
  try {
    const response = await getFootballLeagues("m");
    setFootballMatches(response.data.data);
    setFilteredFootballMatches(response.data.data);
   console.log(response);
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoader(false);
  }
 }



 async function updateMatchState(matchId, isShownOnLiveScreen){
     dispatch(loadingToggleAction(true))
     try{
     let result = await updateMatchStateOnServer({matchId, isShownOnLiveScreen}, "admin/match/showLiveMatch");
     console.log(result)
     getLiveMatches();
     }catch(e){
        console.error("Error:", e);
     }finally{
     dispatch(loadingToggleAction(false))

     }
 } 

 async function updateFootballMatchState(leagueId, isShownOnApp){
  dispatch(loadingToggleAction(true))
  try{
  let result = await updateMatchStateOnServer({leagueId, isShownOnApp}, "admin/football/league/showOnApp");
  console.log(result)
  getFootballMatches();
  }catch(e){
     console.error("Error:", e);
  }finally{
  dispatch(loadingToggleAction(false))

  }
} 

  function handleToggleMatch(id, state) {
    // Map over the array and toggle the `isShown` property for the matching `matchId`
    if(isCricketShown){
      const updatedArray = matches.map((item) => {
        if (item.matchId === id) {
          return {
            ...item,
            isShownOnLiveScreen: !item.isShownOnLiveScreen, // Toggle the `isShown` property
          };
        }
        return item;
      });
      setMatches(updatedArray);
      updateMatchState(id, state);
    }else{
      const updatedArray = footballMatches.map((item) => {
        if (item.leagueId === id) {
          return {
            ...item,
            isShownOnLiveScreen: !item.isShownOnLiveScreen, // Toggle the `isShown` property
          };
        }
        return item;
      });
      setFootballMatches(updatedArray);
      updateFootballMatchState(id, state);
    }    
  }

  const formatDate = (isoDate, format="") => {
    if(!format){
      const parts = isoDate.split('-');
      return `${parts[1]}/${parts[2]}/${parts[0]}`;
    }else{
      return moment(isoDate).format(format);
    }
};

  const filterMatches = () => {
    const formattedDate = formatDate(selectedDate);
    const results = matches.filter(match => match.date_start === formattedDate);
    setFilteredMatches(results);
};

const filterFootballMatches = () => {
  const formattedDate = formatDate(selectedFootballDate, "YYYY-MM-DD");
  console.log(formattedDate)
  const results = footballMatches.filter(match => match.start === formattedDate);
  console.log(results, "filtered matches")
  setFilteredFootballMatches(results);
};


  

  useEffect(() => {
    getLiveMatches();
    getFootballMatches();
  }, []);

  useEffect(()=>{
    if(isCricketShown){
      if(selectedDate){
        filterMatches();
      }
    }else{
      if(selectedFootballDate){
        filterFootballMatches();
      }
    }
   },[selectedDate, selectedFootballDate, isCricketShown])

  return (
    <Card className="p-3">
      <div className="d-flex align-items-center justify-content-between">
      <h2>Toggle Matches</h2>
      <div className="d-flex align-items-center ">
       <span className="mr-2">Football</span>
      <Switch
          checked={isCricketShown}
          onChange={(value) => setIsCricketShown(value)}
          inputProps={{ "aria-label": "Color switch demo" }}
          color="warning"
          />
       <span className="ml-2"> Cricket</span>
      </div>
      </div>

      {
        isCricketShown  && <>
      <input type="date" name="" id="" style={{width: "200px"}} className="mb-2 mt-2 px-2 py-3 rounded" value={selectedFootballDate} onChange={(e)=> setSelectedfootballDate(e.target.value)}/>
      {loader && <h3 className="text-center">Loading...</h3>}
      {!loader &&
        filteredMatches &&
        filteredMatches.map((match) => (
          <div className="p-3 mb-3 shadow-sm d-flex align-items-center rounded justify-content-between" key={match._id}>
            <div className="d-flex flex-column align-items-start">
          <span>{match.title}</span>
          <span className="font-weight-bold">({match.date_start})</span>
            </div>
            <div>
            <Switch
            checked={match.isShownOnLiveScreen}
            onChange={(value) => handleToggleMatch(match.matchId, value)}
            inputProps={{ "aria-label": "Color switch demo" }}
            color="warning"
            />
            </div>
          </div>
        ))}
        </>
      }
      {
        !isCricketShown  && <>
      <input type="date" name="" id="" style={{width: "200px"}} className="mb-2 mt-2 px-2 py-3 rounded" value={selectedFootballDate} onChange={(e)=> setSelectedfootballDate(e.target.value)}/>
      {loader && <h3 className="text-center">Loading...</h3>}
      {!isCricketShown  &&
        filteredFootballMatches &&
        filteredFootballMatches.map((match) => (
          <div className="p-3 mb-3 shadow-sm d-flex align-items-center rounded justify-content-between" key={match._id}>
            <div className="d-flex flex-column align-items-start">
          <span>{match.name}</span>
          <span className="font-weight-bold">({match.start})</span>
            </div>
            <div>
            <Switch
            checked={match.isShownOnApp}
            onChange={(value) => handleToggleMatch(match.leagueId, value)}
            inputProps={{ "aria-label": "Color switch demo" }}
            color="warning"
            />
            </div>
          </div>
        ))}
        </>
      }
      
    </Card>
  );
}

export default MatchManagement;
