import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import { loadingToggleAction } from "../../../store/actions/AuthActions";
import addPhoto from "../../pages/test";
import EditIcon from "../../../images/image.png";
import moment from "moment";
import { rewardsDelete, rewardsGet } from "../../../services/asyncFunctions";

function MultiInputRewardList({ updateHandler }) {
  const [rows, setRows] = useState([{ claimDate: "", name: "", requiredCoin: "", image: "" }])
  const [selectedIndex, setSelectedIndex] = useState(null);
  const dispatch = useDispatch();
  const imageRefs = useRef([]);

  const handleAddRow = () => {
    setRows([
      ...rows,
      { claimDate: "", name: "", requiredCoin: "", image: "" },
    ]);
  };

  const handleRemoveRow = (index, id) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
    if(id){
      deletItem(id)
    }
  };

  const handleRowChange = async (index, key, value) => {
    if (key == "image") {
      let imageUrl = await uploadImage(value);
      const newRows = [...rows];
      newRows[index][key] = imageUrl?.Location ? imageUrl.Location : "";
      setRows(newRows);
    } else {
      const newRows = [...rows];
      newRows[index][key] = value;
      setRows(newRows);
    }
  };

  async function uploadImage(file) {
    dispatch(loadingToggleAction(true));
    let response = await addPhoto(file, "rewardImages");
    console.log(response, "image uploaded");
    dispatch(loadingToggleAction(false));
    return response;
  }

  function clickImagePicker(index) {
    imageRefs.current[index].click();
  }

  async function getRewardList() {
    dispatch(loadingToggleAction(true));
    try {
      const response = await rewardsGet();
      setRows([...response.data.data]);
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }finally{
    dispatch(loadingToggleAction(false));
    }
  }

  async function deletItem(id){
    try {
      const response = await rewardsDelete(id);
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  useEffect(() => {
    getRewardList();
  }, []);

  useEffect(()=>{
   if(rows.length === 0){
    handleAddRow();
   }
  },[rows])

  return (
    <div className="form-control h-100 shadow-sm">
      {rows.map((row, index) => (
        <div key={index} className="d-flex align-items-center">
          <label className="mr-3">
            {/* <span className='text-primary'>Date</span>  */}
            <input
              type="date"
              value={moment(row.claimDate).format("YYYY-MM-DD")}
              className="custom-text-input"
              placeholder="Claim Date"
              onChange={(e) =>
                handleRowChange(index, "claimDate", e.target.value)
              }
            />
          </label>
          <label className="mr-3">
            {/* <span className='text-primary'>Prize</span>  */}
            <input
              type="number"
              value={row.requiredCoin}
              placeholder="Required Coins"
              className="custom-text-input"
              onChange={(e) =>
                handleRowChange(index, "requiredCoin", e.target.value)
              }
            />
          </label>
          <label className="mr-3">
            {/* <span className='text-primary'>Prize</span>  */}
            <input
              type="text"
              value={row.name}
              placeholder="Reward Name"
              className="custom-text-input"
              onChange={(e) => handleRowChange(index, "name", e.target.value)}
            />
          </label>
          <div className="mr-3 d-flex align-items-center">
            {/* <span className='text-primary'>Prize</span>  */}
            {row.image && (
              <img
                src={row.image}
                alt="Reward Image"
                className="mr-3"
                width={60}
              />
            )}
            <input
              type="file"
              className="custom-text-input d-none"
              accept="image/*"
              onChange={(e) =>
                handleRowChange(index, "image", e.target.files[0])
              }
              ref={(ref) => (imageRefs.current[index] = ref)}
            />
            <img
              src={EditIcon}
              alt="edit-icon"
              className="pointer"
              width={30}
              onClick={() => clickImagePicker(index)}
            />
          </div>
          {
            <button
              className="btn btn-danger p-0 p-1 ml-3"
              onClick={() => handleRemoveRow(index, row?._id)}
            >
              Delete
            </button>
          }
        </div>
      ))}
      <button
        className="btn btn-secondary mb-3 font-weight-bold"
        type="button"
        onClick={handleAddRow}
      >
        +
      </button>
      <button
        className="btn btn-success mb-3 font-weight-bold w-100"
        type="button"
        onClick={() => updateHandler(rows)}
      >
        Update
      </button>
    </div>
  );
}

export default MultiInputRewardList;
