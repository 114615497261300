import {
  ADD_ROLES_FOR_USER,
  AGENT_MATCH_ID,
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  REMOVE_TOKEN_FROM_STORE,
  SELECT_PACKAGE_ID,
  SET_USER_NAME,
  SIGNUP_CONFIRMED_ACTION,
  SIGNUP_FAILED_ACTION,
  UPDATE_USERTYPE,
} from "../actions/AuthActions";

const initialState = {
  auth: {
    email: "",
    idToken: "",
    name:"",
    localId: "",
    expiresIn: "",
    refreshToken: "",
  },
  errorMessage: "",
  successMessage: "",
  showLoading: false,
  currentPackageId: "",
  agentSelectedMatchId: "",
  userType: "admin",
  roles: ["dashboard", "", "dashboardhome"],
};

export function AuthReducer(state = initialState, action) {
  if (action.type === SIGNUP_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: "",
      successMessage: "Signup Successfully Completed",
      showLoading: false,
    };
  }
  if (action.type === LOGIN_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: {...state.auth, idToken: action.payload},
      errorMessage: "",
      successMessage: "Login Successfully Completed",
      showLoading: false,
    };
  }
  if(action.type === SET_USER_NAME){
    return {
      ...state,
      auth: {...state.auth, name: action.payload},
    }
  }

  if (action.type === LOGOUT_ACTION) {
    return {
      ...state,
      errorMessage: "",
      successMessage: "",
      auth: {
        email: "",
        idToken: "",
        localId: "",
        expiresIn: "",
        refreshToken: "",
      },
    };
  }
  if (
    action.type === SIGNUP_FAILED_ACTION ||
    action.type === LOGIN_FAILED_ACTION
  ) {
    return {
      ...state,
      errorMessage: action.payload,
      successMessage: "",
      showLoading: false,
    };
  }
  if (action.type === LOADING_TOGGLE_ACTION) {
    return {
      ...state,
      showLoading: action.payload,
    };
  }
  if (action.type === SELECT_PACKAGE_ID) {
    return {
      ...state,
      currentPackageId: action.payload,
    };
  }
  if (action.type === AGENT_MATCH_ID) {
    return {
      ...state,
      agentSelectedMatchId: action.payload,
    };
  }
  if(action.type === REMOVE_TOKEN_FROM_STORE){
    return {
        ...state,
        auth: {
          email: "",
          idToken: "",
          localId: "",
          expiresIn: "",
          refreshToken: "",
        },
    }
  }
  if(action.type === ADD_ROLES_FOR_USER){
    return {
        ...state,
        roles: [...state.roles, ...action.payload]
    }
  }
  if(action.type === UPDATE_USERTYPE){
    return {
        ...state,
        userType: action.payload
    }
  }
  return state;
}
