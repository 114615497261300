import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import toast from "react-hot-toast";
import { getOddsMatches } from "../../../services/asyncFunctions";


export default function SelectMatchOdds({ show, onHide, submitFunc }) {
  const [loadingMatches, setLoadingMatches] = useState(false);
  const [oddsMatches, setOddsMatches] = useState([]);

  const fetchOddsMatches = async () => {
    try {
      const response = await getOddsMatches();
      console.log(response);
      setOddsMatches(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function handleClick(id, oddsMatchName){
    submitFunc(id, oddsMatchName);
    onHide();
  }

  useEffect(()=>{
    fetchOddsMatches();
  },[])

  return (
    <>
      <Modal
        size="lg"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => onHide()}
      >
        <Modal.Header>
          <Modal.Title>Select Match Odds</Modal.Title>
          <Button variant="" className="close" onClick={() => onHide()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        {loadingMatches === false && (
          <Modal.Body className="w-100">
            <div className="d-flex align-items-center flex-wrap form-group w-100">
            <ul>
            {oddsMatches.map((match) => (
              <li
                key={match.eventId}
                className="w-100 shadow px-2 py-3 text-dark rounded d-flex align-items-center justify-content-between mb-3"
                style={{ backgroundColor: "#EEE2DE" }}
              >
                
                <div className="d-flex flex-column align-items-start">
                <span>{match.name}</span>
                  <span className="font-weight-bold">({match.startDate})</span>
                  </div>

                
                <button className="btn btn-danger" onClick={()=> handleClick(match.eventId, match.name)}>
                    connect
                </button>
              </li>
            ))}
          </ul>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}
