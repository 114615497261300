import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import toast from "react-hot-toast";
import ReactSelect from "react-select";
import { useDispatch } from "react-redux";
import { loadingToggleAction } from "../../../store/actions/AuthActions";
import { updateStatus } from "../../../services/asyncFunctions";

export default function AddCoinsModal({ show, close, handleAccountUpdate, submitUpdatedAccount }) {

  const dispatch = useDispatch();
  const notifyTopRight = () => {
    toast.success("✅ Package created successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = () => {
    toast.error("❌ Tipper already has package for this match !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

 

  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <Modal className="fade" show={show}>
        <Modal.Header>
          <Modal.Title>Update account</Modal.Title>
          <Button variant="" className="close" onClick={() => close()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
      
          <Modal.Body>
            <form className="form-control h-100" onSubmit={(e) => {e.preventDefault(); submitUpdatedAccount()}}>
                <div className="form-group">
            <label className="form-label">Coins</label>
            <input type="number" name="coins" className="form-control" onChange={handleAccountUpdate}/>
                </div>
                <div className="form-group">
                <label>Diamonds</label>
            <input type="number" name="diamonds"  className="form-control" onChange={handleAccountUpdate}/>
                </div>
            <button className="btn btn-success shadow w-100">Update Account</button>
            </form>
          </Modal.Body>
        
      </Modal>
    </>
  );
}