import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { notifyError, notifySuccess } from '../../constants/testTeams';
import { getMatches, updateMatchVotingTotal } from '../../services/asyncFunctions';
import EditVotingModal from '../components/modals/EditVotingModal';

function VotingManagement() {
    const [loader, setLoader] = useState(false);
    const [matches, setMatches] = useState(null);
    const [selectedMatch, setSelectedMatch] = useState(null);
    const [votingModal, setVotingModal] = useState(false);
    const [currentTotalVotes, setCurrentTotalVotes] = useState(0);
    const [searchState, setSearchState] = useState("");
    const [filteredMatches, setFilterMatches] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);

    async function fetchMatches() {
        setLoader(true);
        let result = await getMatches();
        setMatches(result?.data?.data);
        setFilterMatches(result?.data?.data);
        setLoader(false);
      }

    function handleEditModalOpen(matchId, teamsVoteObject){
        setSelectedMatch(matchId);
        setCurrentTotalVotes(teamsVoteObject);
        setVotingModal(true);
    }


 function filterMatches(type){
  if(type == "search"){
    if(!searchState) {
      setMatches([...filteredMatches]);
      return;
    }
    let machesFilt = filteredMatches.filter(item => (item.title).toLowerCase().includes(searchState.toLowerCase()));
    console.log(machesFilt);
    setMatches([...machesFilt]);
  }else{
    if(!selectedDate) {
      setMatches([...filteredMatches]);
      return;
    }
    let machesFilt = filteredMatches.filter(item => moment(item?.date_start, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") == selectedDate);
    console.log(machesFilt);
    setMatches([...machesFilt]);
  }
 }

    async function updateMatchVotes(matchId, vote){
      if(!matchId) return;
      let payload = {
        matchId,
        teamaVote: Number(vote?.teama?.adminVote),
        teambVote: Number(vote?.teamb?.adminVote),
      }
        try{
            let response = await updateMatchVotingTotal({...payload});
            if(response.status === 200){
                notifySuccess();
                fetchMatches();
            }
            
        }catch(error){
            notifyError();
        }
    }

function resetList(){
  setMatches([...filteredMatches]);
  setSearchState("");
  setSelectedDate(null);
}
useEffect(()=>{
    fetchMatches();
},[])

useEffect(()=>{
  if(searchState){
    filterMatches("search");
  }
},[searchState])

useEffect(()=>{
  if(selectedDate){
    filterMatches("date");
  }
},[selectedDate])
  return (
    <div>
    <EditVotingModal show={votingModal} onHide={()=> setVotingModal(false)} votes={currentTotalVotes} matchId={selectedMatch}  submitFunction={updateMatchVotes}/>
    <input type="text" placeholder='Match name' name="" id="" style={{width: "200px"}} className="mb-2 mt-2 px-2 py-3 rounded" value={searchState} onChange={(e)=> setSearchState(e.target.value)}/>
    <input type="date" name="" id="" style={{width: "200px"}} className="mb-2 mt-2 ml-2 px-2 py-3 rounded" value={selectedDate} onChange={(e)=> {console.log(e.target.value); setSelectedDate(e.target.value)}}/>
    <button className='btn btn-primary ml-2' onClick={resetList}>Reset</button>
    <div className=''>
        {!loader &&
        matches &&
        matches.map((match) => (
          <div className="p-3 mb-3 shadow-sm d-flex bg-white align-items-center rounded justify-content-between" key={match._id}>
            <div className="d-flex flex-column align-items-start">
          <span>{match.title} ({moment(match?.date_start, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")})</span>
          <div className='d-flex justify-content-between align-items-center'>
            <span>{match?.whoWillWin?.teama?.name}: {match?.whoWillWin?.teama?.adminVote + match?.whoWillWin?.teama?.votes?.length}</span>
            <span className='ml-5'>{match?.whoWillWin?.teamb?.name}: {match?.whoWillWin?.teamb?.adminVote + match?.whoWillWin?.teamb?.votes?.length}</span>
          </div>
            </div>
            <div>
            <button className='btn btn-warning' onClick={()=> handleEditModalOpen(match.matchId, match?.whoWillWin)}>Edit votes</button>
            </div>
          </div>
        ))}
    </div>
    </div>
  )
}

export default VotingManagement