import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { addSession, deleteSession, getSessionOdds, toggleSessionOdds } from '../../../services/asyncFunctions';
import SessionHistoryCard from '../sessionHistoryCard/SessionHistoryCard';
import { ToastContainer } from "react-toastify";
import toast from "react-hot-toast";
import SessionHistoryPostCard from './SessionHistoryPostCard';


const SessionObject = {
  matchId: "",
  isPassed: "",
  passedAt: "",
  inning: "",
  teamId: "",
  session: "",
  min: "",
  max: "",
  open: "",
  odds: "",
}

function SessionHistoryModal({show, onHide, matchId}) {
    const [loader, setLoader] = useState(false);
    const [selectedSessions, setSelectedSessions] = useState([]);
    const [allSessions, setAllSessions] = useState(null);
    const [teams, setTeams] = useState([]);
    const [showForm, setShowForm] = useState(false)


    const notifyTopRight = () => {
      toast.success("✅ Updated successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    };

    const notifyError = () => {
      toast.error("❌ Error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };

    async function updateSessionPassFailStatus(sessionId, isPassed, text, inning, teamId, min, max, open, odds, passedAt){
      // setLoader(true);
      try {
        let response = await toggleSessionOdds(matchId, sessionId, isPassed, text, inning, teamId, min, max, open, odds, passedAt);
        console.log(response);
      notifyTopRight();
        // fetchSessions();
      } catch (e) {
        console.log(e);
        notifyError();
      } finally {
        // setLoader(false);
      }
    }
  
    async function fetchSessions() {
      setLoader(true);
      try {
        let response = await getSessionOdds(matchId);
        console.log(response);
        setAllSessions(response?.data?.data.sessions);
        setTeams(response?.data?.data.teams);
      } catch (e) {
        console.log(e);
      } finally {
        setLoader(false);
      }
    }

    async function addNewSession(isPassed, session,inning, min, max, open, odds, teamId, passedAt){
      setLoader(true);
      try{
        let response = await addSession(matchId, isPassed,inning, session, min, max, open, odds, teamId, passedAt);
        console.log(response);
        setShowForm(false);
      notifyTopRight();
        fetchSessions()
      }catch (e) {
        console.log(e);
        notifyError();
      } finally {
        setLoader(false);
      }
    }

    async function removeSession(sessionId){
      setLoader(true);
        try{
           let res = await deleteSession(matchId, sessionId);
           notifyTopRight();
           fetchSessions()
        }catch(e){
          notifyError();
        }finally {
          setLoader(false);
        }
    }

  
   
    useEffect(() => {
      if (show) {
        fetchSessions();
      }
    }, [show]);


  
  return (
    <>
    <Modal
    size="lg"
    className="fade vertically-centered"
    show={show}
    centered
    scroll
    onHide={() => onHide()}
  >
    <Modal.Header className='d-flex align-items-center justify-content-between'>
      <Modal.Title>Session history</Modal.Title>
      <Button variant="" className="btn btn-secondary" onClick={() => onHide()}>
        Close
      </Button>
    </Modal.Header>
    <Modal.Body scrollable={true}>
      <button className='btn btn-success' onClick={()=>setShowForm(!showForm)}>Add New</button>
      {showForm && <div>
          <SessionHistoryPostCard teams={teams} addSession={addNewSession}/>
      </div>}

       <h4 className='text-center mt-3'>Live session list</h4>
      {!loader && allSessions &&
        allSessions.map((item) => (
          
          <SessionHistoryCard
            key={item._id}
            handleStatusUpdate={updateSessionPassFailStatus}
            handleDelete={removeSession}
            sessionId={item._id}
            title={item.title}
            yes={item?.odds?.yes ?? 0}
            no={item?.odds?.no ?? 0}
            isPassed={Boolean(item?.isPassed) ?? false}
            inning={item?.inning ?? "not selected"}
            text = {item?.session ?? ""}
            open={item?.open ?? ""}
            min= {item?.min ?? ""}
            max = {item?.max ?? ""}
            odds={item?.odds ?? ""}
            teamId={item?.teamId ?? ""}
            teams={teams}
            type={'update'}
            passedAt={item?.passedAt ?? ""}
          />
        ))}
      {
        loader && <h2 className="text-center">Loading...</h2>
      }  
      {
        !loader && (!allSessions || allSessions.length == 0) && <h3 className="text-center">No Fancy Available</h3>
      }  
    </Modal.Body>
  </Modal>
    </>
  )
}

export default SessionHistoryModal;