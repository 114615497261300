import React, { useRef } from 'react'
import { Card } from 'react-bootstrap'
import MessageHeader from './MessageHeader'
import ChatInput from './ChatInput'
import MessageListScroll from './MessageListScroll'
// import ScrollPicker from '../ScrollPicker'
import { BsChatSquareText } from "react-icons/bs";
// import { primaryColor } from '../../../utils/content'


function MessagesList({messageList, isChatFetching, conversationSelectedTrue}) {
  return (
    <Card className='message-list shadow-md'>
       {conversationSelectedTrue && <>
       
      
        <MessageHeader />
         <MessageListScroll messageList={messageList} isChatFetching={isChatFetching}/>
         <ChatInput />
       </> 
        }
         {!conversationSelectedTrue && <div className="no-conversation-container">
      <div className="message-container">
      <BsChatSquareText size={"100px"} color={"#007bff"} />
        <p className="message">Please select a chat to continue</p>
      </div>
    </div>
      }
    </Card>
  )
}

export default MessagesList