import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import ReactSwitch from 'react-switch'
import { getMatchControls, putMatchControls } from '../../services/asyncFunctions';

function MatchControls() {
  const [matchStates, setMatchStates] = useState({
    gamePlay: false,
    googleAdds: false,
  })


  async function postToggleAppControl(type, state){
    let newState = {
      ...matchStates,
      [type]: state
    }
    let payload = {
     ...newState
    }
    try{
      let res = await putMatchControls(payload);
      console.log(res);
      GetToggleAppControls();
    }catch(e){
    console.log(e);
    }
  }

  async function GetToggleAppControls(){
    let res = await getMatchControls();
    console.log(res);
    setMatchStates({
      gamePlay: res.data.data.gamePlay,
      googleAdds: res.data.data.googleAdds
    })
  }

  useEffect(()=>{
    GetToggleAppControls();
  },[])
  return (
    <Card className='p-3'>
        <div className='d-flex align-items-center justify-content-between'>
        <h1>Match Controls</h1>
        {/* <button className='btn btn-warning'>Update Settings</button> */}
        </div>
        <div className='mt-3'>
          <h4 className='mb-2'>Match Data controls</h4>
          <ul className='d-flex  flex-column w-50'>
            <li className='mb-2 d-flex align-items-center w-100 justify-content-between'>
                
                <p>Game Play</p> <ReactSwitch 
                checked={matchStates.gamePlay}
                onChange={(value) => postToggleAppControl("gamePlay", value)}
                inputProps={{ "aria-label": "Color switch" }}
                color="warning"
                /> 
            </li>
            {/* <li className='mb-2 d-flex align-items-center w-100 justify-content-between'>
                <p>Live section</p> <ReactSwitch /> 
            </li>
            <li className='mb-2 d-flex align-items-center w-100 justify-content-between'>
                
                <p>My Bets</p> <ReactSwitch /> 
            </li>
            <li className='mb-2 d-flex align-items-center w-100 justify-content-between'>
                
                <p>LeaderBoard</p> <ReactSwitch /> 
            </li>
            <li className='mb-2 d-flex align-items-center w-100 justify-content-between'>
             
             <p>Points Balance</p> <ReactSwitch /> 
            </li> */}
          </ul>
        </div>
        {/* <div className='mt-3'>
          <h4 className='mb-2 d-flex align-items-center w-100 justify-content-between'>Wallet Controls</h4>
          <ul className='d-flex  flex-column w-50'>
            <li className='mb-2 d-flex align-items-center w-100 justify-content-between'>
                
                <p>Hide Rewards</p> <ReactSwitch /> 
            </li>
            <li className='mb-2 d-flex align-items-center w-100 justify-content-between'>
               
                <p> Hide Bet history</p> <ReactSwitch /> 
            </li>
            <li className='mb-2 d-flex align-items-center w-100 justify-content-between'>
                
                <p>Hide Account Statements</p> <ReactSwitch /> 
            </li>
          </ul>
        </div>
        <div className='mt-3'>
          <h4 className='mb-2 d-flex align-items-center w-100 justify-content-between'>Profile Controls</h4>
          <ul className='d-flex  flex-column w-50'>
            <li className='mb-2 d-flex align-items-center w-100 justify-content-between'>
                
                <p>My LeaderBoard</p> <ReactSwitch /> 
            </li>
          </ul>
        </div> */}
        {/* <div className='mt-3'>
          <h4 className='mb-2 d-flex align-items-center w-100 justify-content-between'>Toggle Ads</h4>
          <ul className='d-flex  flex-column w-50'>
            <li className='mb-2 d-flex align-items-center w-100 justify-content-between'>
                <p>Google Ads</p> <ReactSwitch 
                checked={matchStates.googleAdds}
                onChange={(value) => postToggleAppControl("googleAdds", value)}
                inputProps={{ "aria-label": "Color switch" }}
                color="warning"
                /> 
            </li>
          </ul>
        </div> */}
    </Card>
  )
}

export default MatchControls