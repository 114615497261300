import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import ReactSelect from "react-select";
import { toast, ToastContainer } from "react-toastify";
import { getMatches, sendNotification } from "../../services/asyncFunctions";
import { loadingToggleAction } from "../../store/actions/AuthActions";
import addPhoto from "./test";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const options = [
  { value: "all", label: "All" },
  { value: "tipper", label: "Expert" },
  { value: "user", label: "Fan" },
  { value: "guest", label: "Guest" },
];

const homescreenTabs = [
  {value: "live", label:"Live tab"},
  {value: "upcoming", label:"Upcoming tab"},
  {value: "result", label:"Result tab"},
]

const Screens = [
  { value: "home", label: "Home" },
  { value: "match", label: "Match" },
  { value: "books", label: "Books" },
  { value: "tips", label: "Tips" },
  { value: "shop", label: "Shop" },
  { value: "profile", label: "Profile" },
  { value: "wallet", label: "Wallet" },
  { value: "rewards", label: "Rewards" },
  { value: "gameHistory", label: "Game History" },
  { value: "myLeaderboards", label: "My Leaderboards" },
  { value: "accountStatements", label: "Account Statements" },
  { value: "externalLink", label: "External Link" },
];

const pushType = {
  match: { value: "match", type: 1 },
  books: { value: "books", type: 12 },
  tips: { value: "tips", type: 13 },
  shop: { value: "shop", type: 4 },
  profile: { value: "profile", type: 5 },
  wallet: { value: "wallet", type: 6 },
  rewards: { value: "rewards", type: 7 },
  gameHistory: { value: "gameHistory", type: 8 },
  myLeaderboards: { value: "myLeaderboards", type: 9 },
  accountStatements: { value: "accountStatements", type: 10 },
  home: { value: "home", type: 11 },
  externalLink: { value: "externalLink", type: 15 },
  
};

function Notifications() {
  const dispatch = useDispatch();
  const [desc, setDesc] = useState("");
  const [sendTo, setSendTo] = useState("all");
  const [title, setTitle] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const imageRef = useRef();
  const [notificationType, setNotificationType] = useState(null);
  const [matchType, setMatchType] = useState("live");
  const [matchList, setMatchList] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [externalLink, setExternalLink] = useState("");
  const location = useLocation();
  const [formKey, setFormkey] = useState(0)
  const [homescreenTab, setHomeScreenTab] = useState("");

  async function uploadNotificationImage() {
    if (!desc || !sendTo || !title || !notificationType) return;
    if(notificationType == "home" && !homescreenTab) return;

    dispatch(loadingToggleAction(true));
    let imageResponse = null;
    if (!imageFile) {
      sendNotificationToUsers("");
      return;
    } 
    imageResponse = await addPhoto(imageFile, "notificationImages");
    sendNotificationToUsers(imageResponse.imageName);
    console.log(imageResponse);
  }

  async function fetchMatches() {
    try {
      let result = await getMatches();
      console.log(result);
      let matchList = result?.data?.data.map((item) => {
        return {
          label: item.title,
          value: item.matchId,
        };
      });
      setMatchList(matchList);
    } catch (e) {
      console.log(e);
    }
  }

  function getSelectedMatchId(selectedMatch) {
    if (notificationType == "match") {
      if (!selectedMatch) {
        notifyError("Select a match!");
      } else {
        return selectedMatch + "";
      }
    } else {
      return "";
    }
  }

  async function sendNotificationToUsers(imagePath) {
    let payload = {
      sendTo: sendTo,
      title: title,
      description: desc,
      image: imagePath,
      notificationType: notificationType,
      matchId: getSelectedMatchId(selectedMatch),
      externalUrl: externalLink,
      pushType: pushType[notificationType].type,
      matchType: notificationType !== "match" ? "" : matchType,
      tabName: notificationType == "home" ? homescreenTab : ""
    };
    console.log(payload);
    try {
      let result = await sendNotification(payload);
      console.log(result);
      notifyTopRight();
      setSendTo("");
      setTitle("");
      setDesc("");
      imageRef.current.value = "";
      setImageFile(null);
      setNotificationType("");
      setExternalLink("")
      setSelectedMatch(null);
      setHomeScreenTab("");
      setFormkey(prev => prev + 1)
      dispatch(loadingToggleAction(false));
    } catch (e) {
      notifyError("Something went wrong");
      dispatch(loadingToggleAction(false));
    }
  }

  const notifyTopRight = () => {
    toast.success("✅ Notification Sent!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const notifyError = (message) => {
    toast.error(`❌ ${message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    fetchMatches();
  }, []);
  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>Create Notification</Card.Title>
        </Card.Header>
        <Card.Body key={formKey}>
          <div className="d-flex flex-column align-items-start pb-1 select-match-dropdown">
            <div className="w-75 mb-2">Select User type</div>
            <ReactSelect
              options={options}
              className="w-100"
              defaultValue={sendTo}
              onChange={(value) => setSendTo(value.value)}
            />

            <div className="w-75 mb-2 mt-3">Notification Type</div>
            <ReactSelect
              options={Screens}
              className="w-100"
              defaultValue={notificationType}
              onChange={(value) => setNotificationType(value.value)}
            />
          </div>

          {notificationType == "home" && (
            <div className="" style={{ maxWidth: "300px" }}>
              <div className="w-75 mb-2 mt-3">Select Tab</div>
              <ReactSelect
                options={homescreenTabs}
                className="w-100"
                onChange={(value) => setHomeScreenTab(value.value)}
              />
            </div>
          )}

          {notificationType == "match" && (
            <div className="" style={{ maxWidth: "300px" }}>
              <div className="w-75 mb-2 mt-3">Match Type</div>
              <div className="btn btn-secondary px-4 py-2 mb-3">
                {matchType}
              </div>
              <div className="w-75 mb-2 mt-3">Select Match</div>
              <ReactSelect
                options={matchList}
                className="w-100"
                onChange={(value) => setSelectedMatch(value.value)}
              />
            </div>
          )}

          <div
            className="d-flex flex-column align-items-start gap-3 mt-3"
            style={{ maxWidth: "500px" }}
          >
            <input
              type="text"
              className="form-control mb-3 border-dark"
              placeholder="Notification Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <input
              type="file"
              name=""
              id=""
              accept="image/*"
              className="mb-3"
              ref={imageRef}
              onChange={(e) => {
                setImageFile(e.target.files[0]);
                console.log(e);
              }}
            />
            {notificationType == "externalLink" && (
              <input
                type="text"
                className="form-control mb-3 border-dark"
                placeholder="External Link"
                value={externalLink}
                onChange={(e) => setExternalLink(e.target.value)}
              />
            )}
            <textarea
              className="form-control border-dark"
              rows={5}
              placeholder="Notification Description"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            ></textarea>
          </div>
          <button
            className="btn btn-primary mt-2"
            onClick={uploadNotificationImage}
          >
            Send Notification
          </button>
        </Card.Body>
      </Card>
    </>
  );
}

export default Notifications;
