import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container, Spinner } from "react-bootstrap";
import { deleteBannerWithId, getAppBanner, updateTopBanner } from "../../../services/asyncFunctions";
import addPhoto, { AWS_S3_BASE_URL_EMPTY } from "../../pages/test";

export default function TopBanner({ show, close, handleFileChange, submitBanner, handleInputChange }) {
 const [isLoading, setIsLoading] = useState(true);
 const [imageFile, setImageFile] = useState(null);
 const [bannerData, setBannerData] = useState(null);
 const [title, setTitle] = useState(null);
 const [url, setUrl] = useState(null);
 const [order, setOrder] = useState(null);


  async function getTopBanner(){
    setIsLoading(true);
    try{
        let res = await getAppBanner();
        console.log(res);
        setBannerData(res.data.data);
    }catch(e){
        console.log(e)
    }finally{
    setIsLoading(false);
    }
  } 

  async function uploadBannerImage() {
    if (!imageFile) {
    //   notifyError("Please select an image file");
      return;
    }
    let imageResponse = null;
    setIsLoading(true);
    if (imageFile) {
      imageResponse = await addPhoto(imageFile, "banner");
      editTopBanner(imageResponse.imageName);
      close();
    } else {
      editTopBanner("");
    }
  }

  async function editTopBanner(imageUrl){
    setIsLoading(true);
    let payload = {
        title: title,
        image:  imageUrl ? imageUrl : bannerData.image,
        url: url,
        order,
        isActive: true,
    }
    try{
        let res = await updateTopBanner(payload);
        setTitle("");
        setUrl("");
      setOrder(null);
    }catch(e){
        console.log(e)
    }finally{
    setIsLoading(false);
    }
  } 

  async function deleteBanner(id){
    setIsLoading(true);
      try{
          let res = await deleteBannerWithId(id);
          getTopBanner();
      }catch(error){
          console.log(error);
      }finally{
        setIsLoading(false)
      }
  }
  
  
  useEffect(()=>{
    if(show){
        getTopBanner();
    }
  },[show])
  return (
    <>
      <Modal className="fade" show={show}>
        <Modal.Header>
          <Modal.Title>Top Banner</Modal.Title>
          <Button variant="" className="close" onClick={() => close()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
          <Modal.Body>
            <form className="form-control h-100" onSubmit={(e) => {e.preventDefault(); uploadBannerImage()}}>
            <input type="text" placeholder="Enter Banner Title" name="name" className="form-control mb-3" value={title} required onChange={(e)=> setTitle(e.target.value)}/>
            <input type="text" name="url" placeholder="Enter Redirect URl" className="form-control mb-3" value={url} required onChange={(e)=> setUrl(e.target.value)}/>
            <input type="number" name="order" placeholder="Enter banner order" className="form-control mb-3" value={order} required onChange={(e)=> setOrder(e.target.value)}/>
            <input type="file" name="" id="" accept="image/*" className="mb-3" required onChange={(e)=> setImageFile(e.target.files[0])}/>
            <button className="btn btn-success shadow w-100">Add Banner</button>
            </form>

           {!isLoading && <div className="">
                {bannerData && bannerData.map((item, idx)=> <div key={item._id} className="shadow-sm p-2  text-monospace">
                  <div className="d-flex align-items-center justify-content-end mb-2"><button className="btn btn-danger p-0 px-3 py-1 fs-12" onClick={()=>deleteBanner(item._id)}>Delete</button></div>
                    <div className="mb-2 font-weight-bold d-flex align-items-center justify-content-between">Title: <span className="fs-12">{item.title}</span></div>
                    <div className="mb-2 font-weight-bold d-flex align-items-center justify-content-between">Link: <a href={item.url} target="_blank" rel="noopener noreferrer" className="fs-12">{item.url}</a></div>
                    <div className="mb-2 font-weight-bold d-flex align-items-center justify-content-between">Image: <img src={AWS_S3_BASE_URL_EMPTY + "banner/" + item.image} alt="" className="" style={{objectFit: "cover"}} width={100} height={100}/></div>
                </div>)}
            </div>}

            {isLoading && <Spinner variant="info" animation="border" />}
          </Modal.Body>
        
      </Modal>
    </>
  );
}