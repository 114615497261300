import React, { useState } from 'react'
import Switch from "react-switch";


function MultiInputListComponent() {
    const [rows, setRows] = useState([{ rank: '', prize: '', isRange: false }]);

    const handleAddRow = () => {
      setRows([...rows, { rank: '', prize: '', isRange: false }]);
    };
  
    const handleRemoveRow = (index) => {
      if (index === 0) {
        return;
      }
  
      const newRows = [...rows];
      newRows.splice(index, 1);
      setRows(newRows);
    };
  
    const handleRowChange = (index, key, value) => {
      const newRows = [...rows];
      newRows[index][key] = value;
      setRows(newRows);
    };

    const handleChangeState = (index, key, value) => {
        const newRows = [...rows];
        newRows[index][key] = value;
        setRows(newRows);
    }

    return ( <div>
      {rows.map((row, index) => (
        <div key={index} className="d-flex align-items-center">
          <label className='mr-3'>
            {/* <span className='text-primary'>Rank</span>  */}
            <input
              type="text"
              value={row.rank}
              placeholder="Rank"
              className="custom-text-input"
              onChange={(e) => handleRowChange(index, 'rank', e.target.value)}
            />
          </label>
          <label className='mr-3'>
            {/* <span className='text-primary'>Prize</span>  */}
            <input
              type="text"
              value={row.prize}
              placeholder="Prize"
              className="custom-text-input"
              onChange={(e) => handleRowChange(index, 'prize', e.target.value)}
            />
          </label>
          
          <label htmlFor="" style={{fontSize: "12px"}}>Range
          <Switch
            checked={row.isRange}
            onChange={(value) => handleChangeState(index, 'isRange', value)}
            inputProps={{ "aria-label": "Color switch demo" }}
            color="warning"
            />
          </label>
          {index !== 0 && (
            <button className='btn btn-danger p-0 p-1 ml-3' onClick={() => handleRemoveRow(index)}>Delete</button>
          )}
        </div>
      ))}
      <button className='btn btn-secondary mb-3 font-weight-bold' type='button' onClick={handleAddRow}>+</button>
    </div>
    )
}

export default MultiInputListComponent