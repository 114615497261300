import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { getCompetitions } from "../../services/asyncFunctions";
import CompetitionCard from "../components/cards/CompetitionCard";
import EditCompetition from "../components/modals/EditCompetition";

function Competitions() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedCompetition, setSelectedCompetition] = useState(null);
  const [searchString, setSearchString] = useState(null);


  async function getCompetitionListing() {
    setIsLoading(true);
    try {
      let result = await getCompetitions(0);
      console.log(result);
      setData(result.data.data.competitions);
      setFilteredData(result.data.data.competitions);
      setTotalCount(result.data.data.count);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }

  const filterMatches = () => {
    if(!searchString) {
        setFilteredData(data);
        return;
    }
      const results = data.filter((match) =>
        match.title.toLowerCase().includes(searchString)
      );
      setFilteredData(results);
  };

  useEffect(() => {
    getCompetitionListing();
  }, []);

  useEffect(()=>{
    if(selectedCompetition){
        setShowModal(true);
        console.log(selectedCompetition)
    }
  },[selectedCompetition])

  useEffect(()=>{
    filterMatches();
  },[searchString])
  return (
    <>
      <EditCompetition
        show={showModal}
        close={() => setShowModal(false)}
        selectedCompetition={selectedCompetition}
        refetch={getCompetitionListing}
      />
      <Card>
        <Card.Header>
            <div className="d-flex align-items-center justify-content-between">
          <Card.Title>Competitions</Card.Title>
          <input
              type="search"
              className="form-control ml-3"
              placeholder="Search match title"
              onChange={(e) => setSearchString(e.target.value)}
              value={searchString}
            />
            </div>
        </Card.Header>
        <Card.Body>
          {isLoading && <h2 className="text-center">Loading...</h2>}
          {filteredData &&
            filteredData.map((item) => (
              <CompetitionCard
                key={item?._id}
                id={item?._id}
                title={item?.title}
                status={item?.status}
                dateStart={item?.dateStart}
                dateEnd={item?.dateend}
                logo={item?.logo}
                telecast={item?.telecast}
                handleSelection={setSelectedCompetition}
              />
            ))}
        </Card.Body>
      </Card>
    </>
  );
}

export default Competitions;
