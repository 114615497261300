import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { Card } from "react-bootstrap";

import {
  expressionEventCodes,
  getBatsmanOnStrike,
  getCurrentOverTotal,
  getEventData,
  getLastballObject,
  getOverNumber,
  getOverString,
  getScoreFromEvent,
  getSlicedPlayerName,
  isNotOut,
  isTrueBall,
  isWicketLost,
  primaryEventCodes,
  shouldBallAdded,
  shouldRunAddedToBatsman,
  shouldWicketAddedToBowler,
} from "../../constants/agentEvents";
import CheckEvents from "../components/modals/CheckEvents";
import ExtraEvents from "../components/modals/ExtraEvents";
import InjuryEvents from "../components/modals/InjuryEvents";
import LegByEvents from "../components/modals/LegByEvents";
import MatchEvents from "../components/modals/MatchEvents";
import NoBallEvents from "../components/modals/NoBallEvents";
import WicketEvents from "../components/modals/WicketEvents";
import WideEvents from "../components/modals/WideEvents";
import { getUserToken } from "../../services/utils/generalUtils";
import OverSlider from "../components/Slider/overSlider/OverSlider";
import { connect, useDispatch } from "react-redux";
import { loadingToggleAction } from "../../store/actions/AuthActions";
import { getSocketData, resetMatchToss } from "../../services/asyncFunctions";
import { set } from "date-fns/esm";
import { useHistory, withRouter } from "react-router-dom";
import Power from "../../images/PP2.png";
import CustomMessage from "../components/modals/CustomMessage";
import BatsmanModal from "../components/modals/BatsmanModal";
import BowlerModal from "../components/modals/BowlerModal";
import ScoreInput from "../components/Inputs/ScoreInput";
import Edit from "../../images/edit.png";
import ScoreInputModal from "../components/modals/ScoreInputModal";
import FancyModal from "../components/modals/FancyModal";
import SessionHistoryModal from "../components/modals/SessionHistoryModal";
import TeamScoreInputModal from "../components/modals/TeamScoreInputModal";
import  toast  from "react-hot-toast";
import BowlerStatsModal from "../components/modals/BowlerStatsModal";
import UpdateInningOvers from "../components/modals/UpdateInningOvers";
import TieMatchModal from "../components/modals/TieMatchModal";
import SuperOverTossModal from "../components/modals/SuperOverTossModal";
import Swal from "sweetalert2";
import { toastError, toastSuccess } from "../components/PluginsMenu/Nestable/utils";
const URLString = process.env.REACT_APP_API_BASE_URL;

let socket;
let socketGet;

function AgentMatchScreen(props) {
  const [eventPressed, setEventPressed] = useState("");
  const [expressionPressed, setExpressionPressed] = useState("");
  const [wicketShow, setWicketShow] = useState(false);
  const [showDrawModal, setShowDrawModal] = useState(false);
  const [showSuperOverTossModal, setSuperOverTossModal] = useState(false);
  const [wideShow, setWideShow] = useState(false);
  const [legByShow, setLegByShow] = useState(false);
  const [noBallShow, setNoBallShow] = useState(false);
  const [matchShow, setMatchShow] = useState(false);
  const [injuryShow, setInjuryShow] = useState(false);
  const [checkShow, setCheckShow] = useState(false);
  const [extraShow, setExtraShow] = useState(false);
  const [customShow, setCustomShow] = useState(false);
  const [batsmanModalShow, setBatsmanModal] = useState(false);
  const [bowlerModalShow, setBowlerModal] = useState(false);
  const [ballText, setBallText] = useState("");
  const [totalScore, setTotalScore] = useState(0);
  const [teamaScore, setTeamaScore] = useState(0);
  const [teambScore, setTeambScore] = useState(0);
  const [wickets, setWickets] = useState(0);
  const [commentaries, setCommentaries] = useState([]);
  const [inningNumber, setInningNumber] = useState(0);
  const [liveDeliveries, setLiveDeliveries] = useState(0);
  const [globalScoreVariable, setGlobalScoreVariable] = useState(0);
  const [globalWicketVariable, setGlobalWicketVariable] = useState(0);
  const [globalDeliveriesVariable, setGlobalDeliveriesVariable] = useState(0);
  const [currentPermanentText, setCurrentPermanentText] = useState("");
  const [screenTextDisplayVariable, setScreenTextDisplayVariable] =
    useState("Start");
  const [isNewBall, setIsNewBall] = useState(true);
  const [teamAWickets, setTeamAWickets] = useState(0);
  const [teamBWickets, setTeamBWickets] = useState(0);
  const [playerOnStrike, setPlayerOnStrike] = useState("");
  const [teamAData, setTeamAData] = useState(null);
  const [teamBData, setTeamBData] = useState(null);
  const matchIdFromLocalStorage = localStorage.getItem("agentMatchId");
  const [matchStatus, setMatchStatus] = useState(null);
  const [tossData, setTossData] = useState({
    //initializing toss object
    winningTeamId: null,
    battingTeamId: null,
    choice: "bat",
    screenText: null,
  });
  const [isPPlayActive, setIsPPlayActive] = useState(true);
  const [tossError, setTossError] = useState(false);
  const [overNumber, setOverNumber] = useState(1);
  const [currentOverTotal, setCurrentOverTotal] = useState(0);
  const [currentRR, setCurrentRR] = useState(0);
  const [requiredRR, setRequiredRR] = useState(0);
  const [target, setTarget] = useState(0);
  const [customText, setCutomText] = useState("");
  const [battingTeamPlayers, setBattingTeamPlayers] = useState([]);
  const [bowlingTeamPlayers, setBowlingTeamPlayers] = useState([]);
  const [currentBatsmen, setCurrentBatsmen] = useState({
    //initializing current batsmen object
    batsman1: null,
    batsman2: null,
  });
  const [currentBowler, setCurrentBowler] = useState(null);
  const [batsManNumber, setBatsManNumber] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();
  const screenTextRef = useRef(null);
  const [batsmanRuns, setBatsmanRuns] = useState(0);
  const [batsmanBalls, setBatsmanBalls] = useState(0);
  const [batsmanFours, setBatsmanFours] = useState(0);
  const [batsmanSixes, setBatsmanSixes] = useState(0);
  const [bowlerRuns, setBowlerRuns] = useState(0);
  const [bowlerWkts, setBowlerWkts] = useState(0);
  const [bowlerOvers, setBowlerOvers] = useState(0);
  const [batsman1Options, showBatsman1Options] = useState(false);
  const [batsman2Options, showBatsman2Options] = useState(false);
  const [bowlerOptions, showBowlerOptions] = useState(false);
  const [fancyModalShow, setFancyModalShow] = useState(false);
  const [sessionModalShow, setSessionModalShow] = useState(false);
  const [showTeamaScoreModal, setShowTeamaScoreModal] = useState(false);
  const [showTeambScoreModal, setShowTeambScoreModal] = useState(false);
  const [showInningOverModal, setShowInningOverModal] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState(false);
  const [currentInningOvers, setCurrentInningOvers] = useState(0);
  const [isMatchViaSocket, setIsMatchViaSocket] = useState(true);




  //main event for updating match score
  function sendEvent(eventName, type = "") {
    console.log("entered function");
    let scoreToAdd = getScoreFromEvent(eventName, type); //get runs  on the ball
    let scoreToAddForBatsMan = getScoreFromEvent(eventName, type, "batsman");
    let wicketsToAdd = isWicketLost(eventName, type); // did we lose a wicket on the ball or not
    let notOut = isNotOut(type); // is the event not out
    let trueBall = isTrueBall(eventName); // is the ball a true ball or not
    let scorePayload = {
      // payload for other events than overEnd
      event: eventName,
      totalScore: globalScoreVariable + scoreToAdd,
      balls: shouldBallAdded(isNewBall, trueBall, eventName, "team")
        ? globalDeliveriesVariable + 1
        : globalDeliveriesVariable,
      isConfirmed: false,
      overNumber: overNumber,
      overTotal: currentOverTotal,
      wickets:
        wicketsToAdd && !notOut
          ? Number(globalWicketVariable) + 1
          : globalWicketVariable, //if a player was out then add 1 to live wicket number
      ...getEventData(eventName, type),
    };

    let overEndPayload = {
      //overend payload because we dont want to update any variables on overend just return latest values
      event: eventName,
      totalScore: totalScore,
      balls: liveDeliveries,
      isConfirmed: false,
      overNumber: overNumber,
      overTotal: currentOverTotal,
      wickets: wickets,
      ...getEventData(eventName, type),
    };

    if (eventName !== "overEnd" && wickets < 11) {
      // calling player stats function with all that data needed
      let playerStatsData = playerStatsEvent(
        scoreToAdd,
        isNewBall,
        trueBall,
        eventName,
        wicketsToAdd,
        notOut,
        type,
        scoreToAddForBatsMan
      );
      scorePayload = {
        ...scorePayload,
        ...playerStatsData
      }
    }
    try {
      if (eventName == "overEnd" && wickets < 11) {
        //if overEnd and if wickets >= 10 emit thi
        socket.emit("addMatchScore", {
          ...overEndPayload,
        });
      } else {
        if (wickets < 11) {
          socket.emit("addMatchScore", {
            ...scorePayload,
          });
        }
      }
      if (isNewBall) {
        // if it was a new ball then change the flag to false because we will update this ball only untill ball start
        setIsNewBall(false);
      }
    } catch (e) {
      console.log(e);
    }
  }

  //event for updating current player stats
  function playerStatsEvent(
    runsOnball,
    isNewBall,
    isTrueBall,
    eventName,
    wicketsToAdd,
    notOut,
    type,
    scoreToAddForBatsMan
  ) {

    console.log({
      runsOnball,
      isNewBall,
      isTrueBall,
      eventName,
      wicketsToAdd,
      notOut,
      type,
      scoreToAddForBatsMan
    })
    let updatedBatsmanStats = {
      //adding new values to the batsman on strik stats
      runs:
      shouldRunAddedToBatsman(isTrueBall, eventName, type)
          ? Number(batsmanRuns) + Number(scoreToAddForBatsMan)
          : batsmanRuns,
      balls: shouldBallAdded(isNewBall, isTrueBall, eventName, "batsman")
        ? Number(batsmanBalls) + 1
        : batsmanBalls,
      fours: runsOnball == 4 ? Number(batsmanFours) + 1 : batsmanFours,
      sixes: runsOnball == 6 ? Number(batsmanSixes) + 1 : batsmanSixes,
    };
    let updatedBowlerStats = {
      // adding new values to the bowler data
      wkts: shouldWicketAddedToBowler(wicketsToAdd, notOut, type) ? Number(bowlerWkts) + 1 : bowlerWkts,
      runs:  eventName !== "legby" ? Number(bowlerRuns) + Number(runsOnball) : bowlerRuns,
      overs: shouldBallAdded(isNewBall, isTrueBall, eventName, "bowler")
        ? Number(bowlerOvers) + 1
        : bowlerOvers,
    };
    let finalPayload;
    if (playerOnStrike == currentBatsmen.batsman1.playerId) {
      // updating currentBatsmen object acc to whos on strike
      finalPayload = {
        currentBatsmen: {
          batsman1: {
            name: currentBatsmen?.batsman1?.name ?? "",
            playerId: currentBatsmen?.batsman1?.playerId ?? "0",
            onStrike: currentBatsmen?.batsman1?.onStrike ?? false,
            ...updatedBatsmanStats,
          },
          batsman2: currentBatsmen?.batsman2,
        },
        currentBowler: {
          name: currentBowler?.name,
          playerId: currentBowler?.playerId,
          overs: currentBowler?.overs,
          ...updatedBowlerStats,
        },
      };
    } else {
      finalPayload = {
        currentBatsmen: {
          batsman2: {
            name: currentBatsmen?.batsman2?.name ?? "",
            playerId: currentBatsmen?.batsman2?.playerId ?? "0",
            onStrike: currentBatsmen?.batsman2?.onStrike ?? false,
            ...updatedBatsmanStats,
          },
          batsman1: currentBatsmen?.batsman1,
        },
        currentBowler: {
          name: currentBowler?.name,
          playerId: currentBowler?.playerId,
          overs: currentBowler?.overs,
          ...updatedBowlerStats,
        },
      };
    }
    // console.log(finalPayload);
    return finalPayload;
    // socket.emit("updatePlayerStats", finalPayload); // fire the event
  }

 //undo key event for overEnd
 function undoKeyEvent(){
  let lastBall = getLastballObject(commentaries);
  if(lastBall){
    let batsmanOnStrike = getBatsmanOnStrike(currentBatsmen, lastBall.run);
    let undoPayload = {
        //undo payload to reduce the stats to last ball
        totalScore: totalScore - lastBall?.run,
        balls: liveDeliveries - 1,
        wickets: lastBall?.event == "wicket" ? wickets - 1 : wickets,
        batsmanData: {
           playerId: batsmanOnStrike.playerId,
           data:{
            runs: batsmanOnStrike.runs - lastBall?.run,
            balls: batsmanOnStrike.balls - 1,
            fours: lastBall?.run == "4" ? batsmanOnStrike.fours - 1 : batsmanOnStrike.fours, 
            sixes: lastBall?.run == "6" ? batsmanOnStrike.sixes - 1 : batsmanOnStrike.sixes, 
           }
        },
        bowlerData: {
          playerId: currentBowler?.playerId,
          runs: currentBowler?.runs - lastBall?.run,
          wkts: lastBall?.event == "wicket" ? currentBowler?.wkts - 1 : currentBowler?.wkts,
          overs: currentBowler?.overs - 1
        }
    };
  console.log(undoPayload);

  socket.emit('undoEvent', undoPayload)  ;
  } 
 }



  //player stats event from input fields
  function playerStatsFromInput(){ 
    let finalPayload = {
      currentBatsmen,
      currentBowler
    }
    socket.emit("updatePlayerStats", finalPayload)
  }

  //update bowler stats

  function updateBowlerStats(bowlerObject){
    let finalPayload = {
      currentBatsmen,
      currentBowler:{...bowlerObject}
    }
    socket.emit("updatePlayerStats", finalPayload)
  }


  //switch match to run from entity api and stop socket

  async function switchMatchToApi(state){
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No',
    });

    if(!result.isConfirmed) return;
    socket.emit("switchToApi", {switch: state})
  }

  

  //event for updating batsman selection
  function updateBatsmenEvent(currentBatsmenObject) {
    // here we are updating the currentBatsmen with new batsman selection
    if (currentBatsmenObject?.batsman1 && currentBatsmenObject?.batsman2) {
      //if both are not falsy
      socket.emit("updateBatsmen", {
        currentBatsmen: currentBatsmenObject,
      });
      setCurrentBatsmen(currentBatsmenObject); // setting in local variable for viewing
    } else {
      setCurrentBatsmen(currentBatsmenObject);
      return;
    }
  }

  //event for updating bolwer selection
  function updateBowlerEvent(currentBowlerObject) {
    // updating the bolwer selection
    socket.emit("updateBowler", {
      currentBowler: currentBowlerObject,
    });
    setCurrentBowler(currentBowlerObject);
    setTimeout(()=> displayPlayerName(currentBowlerObject.name), 300);
  }

  //event for changing the player on strike
  function changeStrikeEvent(playerId) {
    // changing the player on strike by sending the playerId to the server
    socket.emit("changeStrike", {
      playerId,
    });
  }

  //event for display only events during the match
  function sendDisplayEvent(screenText) {
    let payload = {
      screenText: screenText,
    };
    try {
      socket.emit("displayEvents", {
        ...payload,
      });
    } catch (e) {
      console.log(e);
    }
  }


  //Updating lambi / khado over 
  function updateOverSessions(payload){
    if(payload){
      socket.emit('updateLambiAndKhado', payload)
    }
  }

  function removeSlots(sessionType){
    console.log({matchId: matchIdFromLocalStorage, sessionType})
    socket.emit('removeLambiKhado', {matchId: matchIdFromLocalStorage, sessionType})
  }

  //reset the match for tesing purposes
  function resetMatch() {
    socket.emit("addMatchScore", {
      matchId: matchIdFromLocalStorage,
      isClear: true,
    });
    // socketGet.emit("disconnectUser")
  }

  //updating the global variables with the latest data from server on new ball start
  function updateGlobalVariables() {
    setGlobalScoreVariable(totalScore);
    setIsNewBall(true);
    setGlobalWicketVariable(wickets);
    setGlobalDeliveriesVariable(liveDeliveries);
    updatePlayerOnStrikeId(currentBatsmen);
    initializeBowlerData(currentBowler);
  }

  //event for starting new ball after previous ball was closed
  function ballStart() {
    updateGlobalVariables(); // setting global variables
    socket.emit("ballStart", {
      balls: liveDeliveries,
      wickets: wickets,
    });
  }

  //Display PLayer name event
  function displayPlayerName(playerName, type){
    let displayString = "";
       if(type == "bat"){
        displayString = playerName + "to bat"
         socket.emit("showPlayerName", displayString)
       }else{
        displayString = playerName + "to Ball"
        socket.emit("showPlayerName", displayString)
       }
  }

  //Api function that get the data of the match till now from the server
  async function getSocketMatchData(matchId) {
    dispatch(loadingToggleAction(true)); // Toggle the loading state
    try {
      let result = await getSocketData(matchId); // Fetch socket data for the given matchId
      console.log(result);
      // Extract relevant data from the result
      let commentaries = result.data.data.liveScore.commentaries;
      setScreenTextDisplayVariable(
        result.data.data.liveScore.currentScreenText
      );
      setIsMatchViaSocket(result.data.data.liveScore.isAdminUpdateScore);
      setCurrentPermanentText(result.data.data.liveScore.permanentScreenText);
      // console.log(result.data.data.liveScore.permanentScreenText);
      // Set data based on the current team battinggg
      if (
        result?.data?.data?.liveScore?.liveInningTeamId ==
        result?.data.data.liveScore?.teama?.teamId
      ) {
        // Team A is batting
        setGlobalDeliveriesVariable(result.data.data.liveScore.teama.balls);
        setGlobalScoreVariable(result.data.data.liveScore.teama.totalScore);
        setGlobalWicketVariable(result.data.data.liveScore.teama.wickets);
        setWickets(result.data.data.liveScore.teama.wickets);
        setTotalScore(result.data.data.liveScore.teama.totalScore);
        setLiveDeliveries(result.data.data.liveScore.teama.balls);
        setBattingTeamPlayers(result.data.data.liveScore.teama.playingEleven);
        setBowlingTeamPlayers(result.data.data.liveScore.teamb.playingEleven);
        setCurrentInningOvers(result.data.data.liveScore.teama.totalBalls)
      } else {
        // Team B is batting
        setGlobalDeliveriesVariable(result.data.data.liveScore.teamb.balls);
        setGlobalScoreVariable(result.data.data.liveScore.teamb.totalScore);
        setGlobalWicketVariable(result.data.data.liveScore.teamb.wickets);
        setWickets(result.data.data.liveScore.teamb.wickets);
        setTotalScore(result.data.data.liveScore.teamb.totalScore);
        setLiveDeliveries(result.data.data.liveScore.teamb.balls);
        setBattingTeamPlayers(result.data.data.liveScore.teamb.playingEleven);
        setBowlingTeamPlayers(result.data.data.liveScore.teama.playingEleven);
        setCurrentInningOvers(result.data.data.liveScore.teamb.totalBalls)
      }
      // Set additional scores and wickets for teams A and B
      setTeamaScore(result.data.data.liveScore?.teama?.totalScore ?? 0);
      setTeambScore(result.data.data.liveScore?.teamb?.totalScore ?? 0);
      setTeamAWickets(result.data.data.liveScore?.teama?.wickets ?? 0);
      setTeamBWickets(result.data.data.liveScore?.teamb?.wickets ?? 0);
      // Set data for team A and team B
      setTeamAData(result.data.data.liveScore?.teama);
      setTeamBData(result?.data?.data?.liveScore?.teamb);
      // Set match status, power play status, current batsmen, and current bowler
      setMatchStatus(result?.data?.data?.liveScore?.status);
      setIsPPlayActive(result?.data?.data?.liveScore?.isPowerPlayActive);
      setCurrentBatsmen(result?.data?.data?.liveScore?.currentBatsmen);
      setCurrentBowler(result?.data?.data?.liveScore?.currentBowler);
      // Update the player on strike ID
      updatePlayerOnStrikeId(result?.data?.data?.liveScore?.currentBatsmen);
      initializeBowlerData(result?.data?.data?.liveScore?.currentBowler);
      dispatch(loadingToggleAction(false)); // Toggle the loading state
    } catch (e) {
      console.error(e);
      dispatch(loadingToggleAction(false)); // Toggle the loading state
    }
  }

  //set the playerOnStrike Id to the new player on strike after the change strike event
  function updatePlayerOnStrikeId(currentBatsmen) {
    if (currentBatsmen?.batsman1?.onStrike) {
      //if batsman 1 is on strike
      setPlayerOnStrike(currentBatsmen?.batsman1?.playerId);
      initializeBatsmanData(currentBatsmen?.batsman1); // initialize stats variables with batsman1 data
    } else {
      setPlayerOnStrike(currentBatsmen?.batsman2?.playerId);
      initializeBatsmanData(currentBatsmen?.batsman2);
    }
  }

  //initialize batsman data for the one whos on strike right now
  function initializeBatsmanData(playerOnStrike) {
    if (playerOnStrike?.playerId) {
      setBatsmanRuns(playerOnStrike.runs);
      setBatsmanFours(playerOnStrike.fours);
      setBatsmanBalls(playerOnStrike.balls);
      setBatsmanSixes(playerOnStrike.sixes);
    }
  }
  function initializeBowlerData(bowler) {
    if (bowler?.playerId) {
      setBowlerRuns(bowler.runs);
      setBowlerWkts(bowler.wkts);
      setBowlerOvers(bowler.overs);
    }
  }

  //key  events handler function for multiple functions
  function onkeydownEvent(e) {
    e.preventDefault();
    if (primaryEventCodes.includes(e.keyCode)) {
      if (e.code === "Space") {
        ballStart();
        setEventPressed(e.code);
        setExpressionPressed("");
      } else {
        setEventPressed(String.fromCharCode(e.keyCode));
        if (String.fromCharCode(e.keyCode) == "w") {
          setWicketShow(true);
          sendEvent("wicket", "");
        } else if (String.fromCharCode(e.keyCode) == "v") {
          setWideShow(true);
          sendEvent("wide", "WD");
        } else if (String.fromCharCode(e.keyCode) == "n") {
          setNoBallShow(true);
          sendEvent("no ball", "NB");
        }
        else {
          sendEvent("run", String.fromCharCode(e.keyCode));
        }
      }
    }
    if (e.key == "Enter") {
      setGlobalScoreVariable(totalScore);
      setGlobalWicketVariable(wickets);
      setBowlerModal(true);
      setExpressionPressed(e.code.toLowerCase());
      setEventPressed("");
      sendEvent("overEnd", "Over End");
    } else {
      setExpressionPressed(String.fromCharCode(e.keyCode));
      if (String.fromCharCode(e.keyCode) == "b") {
        setLegByShow(true);
        sendEvent("legby", "LB");
      }
      if (String.fromCharCode(e.keyCode) == "c") {
        setCheckShow(true);
      }
      if (String.fromCharCode(e.keyCode) == "x") {
        sendEvent("not out");
      }
      if (String.fromCharCode(e.keyCode) == "d") {
        sendDisplayEvent("Catch drop");
      }
      if (String.fromCharCode(e.keyCode) == "`") {
        // sendDisplayEvent("Confirming");
        undoKeyEvent();
      }
      if (String.fromCharCode(e.keyCode) == "o") {
        sendDisplayEvent("Over throw");
      }
      if (String.fromCharCode(e.keyCode) == "s") {
        sendDisplayEvent("Bowler Stop");
      }
      if (String.fromCharCode(e.keyCode) == "a") {
        sendDisplayEvent("Appeal");
      }
      if (String.fromCharCode(e.keyCode) == "h") {
        sendDisplayEvent("In air");
      }

      if (String.fromCharCode(e.keyCode) == "/") {
        sendDisplayEvent("Boundary check");
      }
      if (String.fromCharCode(e.keyCode) == "m") {
        setMatchShow(true);
      }
      if (String.fromCharCode(e.keyCode) == "e") {
        setExtraShow(true);
      }
      if (String.fromCharCode(e.keyCode) == "i") {
        setInjuryShow(true);
      }
      if (String.fromCharCode(e.keyCode) == "f") {
        sendDisplayEvent("Free Hit");
      }
      if(String.fromCharCode(e.keyCode) == "p"){
        setEventPressed("p");
        sendDisplayEvent("Power Surge");
      }
      // }
    }
  }

  //set modal true functions
  function openMatchEvents() {
    setMatchShow(true);
  }
  function openCheckEvents() {
    setCheckShow(true);
  }
  function openInjuryEvents() {
    setInjuryShow(true);
  }
  function openExtraEvents() {
    setExtraShow(true);
  }

  //Toss event for the match
  function sendTossEvent() {
    let payload = {
      ...tossData,
    };
    if (!payload.winningTeamId || !payload.battingTeamId) {
      setTossError("Select the winning team!");
      return;
    } else {
      setTossError("");
      console.log(payload, "toss data");
      socket.emit("toss", payload);
    }
  }

  //Custom message event for sending custom message during the match
  function sendCutomEvent(isPermanent) {
    let payload = {
      screenText: customText,
      isPermanent
    };
    console.log(payload)
    socket.emit("customMessage", payload);
  }

  function removePermanentText() {
    let payload = {
      screenText: "",
      isPermanent: true
    };
    socket.emit("customMessage", payload);
  }

  //function to get batting teamId and setting the toss result text
  function getBattingTeamId() {
    if (tossData.choice == "bat") {
      setTossData({ ...tossData, screenText: `test` });
      if (tossData.winningTeamId === teamAData?.teamId) {
        let temp = tossData;
        temp.screenText = `${teamAData?.short_name} won the toss and elected to bat`;
        setTossData(temp);
      } else {
        let temp = tossData;
        temp.screenText = `${teamBData?.short_name} won the toss and elected to bat`;
        setTossData(temp);
      }
      setTossData({ ...tossData, battingTeamId: tossData.winningTeamId });
    } else {
      if (tossData.winningTeamId === teamAData?.teamId) {
        let temp = tossData;
        temp.screenText = `${teamAData?.short_name} won the toss and elected to ball`;
        setTossData(temp);
      } else {
        let temp = tossData;
        temp.screenText = `${teamBData?.short_name} won the toss and elected to ball`;
        setTossData(temp);
      }

      setTossData({
        ...tossData,
        battingTeamId:
          teamAData?.teamId == tossData.winningTeamId
            ? teamBData?.teamId
            : teamAData?.teamId,
      });
    }
  }

  //function to swap batting and bowling team on inning change
  function swapTeams(inningTeamId, teama, teamb) {
    if (inningTeamId == teama?.teamId) {
      setBattingTeamPlayers(teama.playingEleven);
      setBowlingTeamPlayers(teamb.playingEleven);
    } else {
      setBattingTeamPlayers(teamb.playingEleven);
      setBowlingTeamPlayers(teama.playingEleven);
    }
  }

  //update team score
  function updateTeamScoreEvent(score,wicket, team){
    console.log(team);
    socket.emit('updateTeamScore', {score: score, wickets: wicket, teamId: team == "1" ? teamAData.teamId : teamBData.teamId});
  }

  //socket connection function 
  const establishConnection = (URLString, matchId) => {
    socketGet = io(URLString, {
      transports: ['websocket', 'polling'],
      query:{
        "matchId": props.matchId === "" ? matchId : props.matchId,
        "user" : "admin",
      }
    });
  };

  //get current innning over design
  function currentInningOversEvent(updatedOvers){
    console.log({totalBalls: updatedOvers, inningNumber})
    socket.emit('updateInningOvers', {totalBalls: updatedOvers, inningNumber});
  }


  //reset match data

  async function resetMatchTossLocal(){
    // 
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Reset It!',
      cancelButtonText: 'No, cancel',
    });
    if (!result.isConfirmed) return;
    // setResetIsLoading(true);
      try{
        let response = await resetMatchToss(matchIdFromLocalStorage);
        toastSuccess("Match was reset!");
        if(response?.status == 200){
          history.push("/agent-match-listing");
        }
      }catch(error){
        toastError(error?.response?.data?.message);
      }finally{
        // setResetIsLoading(false)
      }
  }
  


  //useEffect section
  useEffect(() => {
    if (!customShow && !batsman1Options && !showInningOverModal && !batsman2Options && !bowlerOptions && !sessionModalShow && !showTeamaScoreModal && !showTeambScoreModal ) {
      document.addEventListener("keypress", onkeydownEvent);
    }
    return () => {
      document.removeEventListener("keypress", onkeydownEvent);
    };
  }, [onkeydownEvent, customShow]);


  useEffect(() => {
    socket = io(URLString, {
      transports: ['websocket'],
      query:{
        "matchId": props.matchId === "" ? matchIdFromLocalStorage : props.matchId,
        "token" : getUserToken()
      }
    });
    return () => {
      socket.emit("disconnectUser");
    };
  }, []);


  useEffect(()=> {
   // Initial connection
   establishConnection(process.env.REACT_APP_API_BASE_URL, matchIdFromLocalStorage);
   return () => {
    socketGet.emit("disconnectUser");
   }
  },[connectionStatus])

  useEffect(() => {
    setOverNumber(getOverNumber(liveDeliveries));
  }, [liveDeliveries]);

  useEffect(() => {
    //useEffect that listens to all the events emitted by the server
    socketGet.on("getMatchData", (response) => {
      // returns the match data for primary events
      if (response.screenText == "Ball Start") {
        setScreenTextDisplayVariable(response.screenText);
      } else {
        console.log(response, "inside other than ball start");
        setScreenTextDisplayVariable(response.liveScore.currentScreenText);
        setCurrentPermanentText(response.liveScore.permanentScreenText);
        if (
          response?.liveScore?.liveInningTeamId ==
          response?.liveScore?.teama?.teamId
        ) {
          setInningNumber(0);
          setTotalScore(response?.liveScore?.teama?.totalScore ?? 0);
          setWickets(
            response?.liveScore?.teama?.wickets == ""
              ? 0
              : response?.liveScore?.teama?.wickets
          ); //taking live wickets from the socket
          setLiveDeliveries(response?.liveScore?.teama?.balls ?? 0);
          setBattingTeamPlayers(response?.liveScore?.teama?.playingEleven);
          setBowlingTeamPlayers(response?.liveScore?.teamb?.playingEleven);
          setCurrentInningOvers(response?.data?.data?.liveScore?.teama?.totalBalls)

          // setGlobalDeliveriesVariable(response?.liveScore.teama.balls);
        } else {
          setInningNumber(response?.liveScore?.liveInning);
          setTotalScore(response?.liveScore?.teamb?.totalScore ?? 0);
          setWickets(
            response?.liveScore?.teamb?.wickets == ""
              ? 0
              : response?.liveScore?.teamb?.wickets
          ); //taking live wickets from the socket
          setLiveDeliveries(response?.liveScore?.teamb?.balls ?? 0);
          setBattingTeamPlayers(response?.liveScore?.teamb?.playingEleven);
          setBowlingTeamPlayers(response?.liveScore?.teama?.playingEleven);
          setCurrentInningOvers(response?.data?.data?.liveScore?.teamb?.totalBalls);
          // setGlobalDeliveriesVariable(response?.liveScore.teamb.balls);
        }
        if (response?.liveScore?.commentaries.length > 0) {
          setCommentaries(response?.liveScore?.commentaries);
          setCurrentOverTotal(
            getCurrentOverTotal(response?.liveScore?.commentaries)
          );
          // setScreenTextDisplayVariable(
          //   response?.liveScore?.commentaries[
          //     response?.liveScore?.commentaries.length - 1
          //   ].screenText
          // );
        } else {
          setCommentaries([]);
        }
        setTeamaScore(response?.liveScore?.teama?.totalScore ?? 0);
        setTeambScore(response?.liveScore?.teamb?.totalScore ?? 0);
        setTeamAWickets(response?.liveScore?.teama?.wickets ?? 0);
        setTeamBWickets(response?.liveScore?.teamb?.wickets ?? 0);
        setCurrentRR(response?.crr ?? 0);
        setRequiredRR(response?.rrr ?? 0);
        setTarget(response?.target ?? 0);
        setCurrentBatsmen(response?.liveScore?.currentBatsmen);
        setCurrentBowler(response?.liveScore?.currentBowler);
      }
    });
    socketGet.on("getDisplayEvents", (response) => {
      // returns screentext for display events
      console.log(response);
      if (response?.screenText == "2nd Inning") {
        // if screen text is 2nd Inning we reset the score, wicket, comm, balls stats for new team
        if (inningNumber !== 1) {
          setTotalScore(0);
          setWickets(0);
          setLiveDeliveries(0);
          setCommentaries([]);
          setCurrentBatsmen({ batsman1: null, batsman2: null });
          setCurrentBowler(null);
          swapTeams(
            // we swap teams having other team as batting team
            response?.liveScore?.liveInningTeamId,
            response?.liveScore?.teama,
            response?.liveScore?.teamb
          );
        }
      }
      setScreenTextDisplayVariable(response?.screenText);
      setIsPPlayActive(response?.liveScore?.isPowerPlayActive ?? true); // get updated powerplay value
    });

    socketGet.on("toss", (response) => {
      // event for match status and toss result message
      console.log(response);
      setScreenTextDisplayVariable(response?.screenText);
      setMatchStatus(response?.liveScore?.status);
    });
    socketGet.on("switchToApi", (response) => {
      console.log(response)
      setIsMatchViaSocket(response.isAdminUpdateScore);
    })
    socketGet.on("customMessage", (response) => {
      // event for custom message from the panel for the users
      console.log(response);
      setScreenTextDisplayVariable(response?.screenText);
      setMatchStatus(response?.liveScore?.status);
      setCurrentPermanentText(response?.liveScore?.permanentScreenText);
    });
    socketGet.on("getUpdatedBatsmen", (response) => {
      // event that receives updatedbatsmen object
      console.log(response);
      setCurrentBatsmen(response.liveScore.currentBatsmen);
      updatePlayerOnStrikeId(response.liveScore.currentBatsmen);
      if (
        response.liveScore.liveInningTeamId ==
        response?.liveScore?.teama?.teamId
      ) {
        setBattingTeamPlayers(response?.liveScore?.teama?.playingEleven);
      } else {
        setBattingTeamPlayers(response?.liveScore?.teamb?.playingEleven);
      }
    });
    socketGet.on("getUpdatedBowler", (response) => {
      // event that receives updatedbolwer object
      console.log(response);
      setCurrentBowler(response.liveScore.currentBowler);
      setBowlerRuns(response.liveScore.currentBowler.runs);
      setBowlerWkts(response.liveScore.currentBowler.wkts);
      // setBowlerOvers(response.liveScore.currentBowler.wkts);
    });
    // Attach the retryConnection event listener
    socketGet.on("retryConnection", ()=> {
     console.log("disconnected from server");
     setConnectionStatus(prev => !prev);
    } );
  }, [socketGet]);

  useEffect(() => {
    // get matchId from localstorage
    getSocketMatchData(
      props.matchId == "" ? matchIdFromLocalStorage : props.matchId
    );
  }, []);

  useEffect(() => {
    //redirect to /player-selection when status == toss
    if (matchStatus == "toss") {
      history.push("/player-selection");
    }
  }, [matchStatus]);

  useEffect(() => {
    //useEffect to disconnect socket on unmount
    return () => {
      // socketGet.disconnect();
      // socketGet.emit("disconnectUser");
      socket.emit("disconnectUser")
    };
  }, []);

  useEffect(() => {
    getBattingTeamId();
  }, [tossData.choice, tossData.winningTeamId]);

  useEffect(() => {
    const animateText = () => {
      const textDiv = screenTextRef.current;
      if (textDiv) {
        textDiv.classList.remove("animate__zoomIn");
        void textDiv.offsetWidth;
        textDiv.classList.add("animate__zoomIn");
      }
    };

    animateText();
  }, [screenTextDisplayVariable]);

  //show if the toss is yet to happen
  if (matchStatus === null) {
    return (
      <>
        <CustomMessage
          show={customShow}
          onHide={() => setCustomShow(false)}
          customText={customText}
          handleCustomTextChange={setCutomText}
          currentPermanentText={currentPermanentText}
          sendEvent={sendCutomEvent}
          removePermanentText={removePermanentText}
        />
        <div className="toss-container">
          <h1 className="text-center ">
            {teamAData ? teamAData?.short_name : "Team A"} Vs{" "}
            {teamBData ? teamBData?.short_name : "Team B"}
          </h1>
          <h3 className="text-center">Who won the toss?</h3>
          <div className="d-flex align-items-center justify-content-center">
            <div
              className={`toss-team-block shadow ${
                tossData.winningTeamId == teamAData?.teamId && "selected-team"
              }`}
              onClick={() =>
                setTossData({ ...tossData, winningTeamId: teamAData?.teamId })
              }
            >
              <img
                src={teamAData ? teamAData?.logo_url : ""}
                alt=""
                height={40}
                width={40}
              />
              <span>{teamAData ? teamAData?.short_name : "Team A"}</span>
            </div>
            <div
              className={`toss-team-block shadow ${
                tossData.winningTeamId == teamBData?.teamId && "selected-team"
              }`}
              onClick={() =>
                setTossData({ ...tossData, winningTeamId: teamBData?.teamId })
              }
            >
              <img
                src={teamBData ? teamBData?.logo_url : ""}
                alt=""
                height={40}
                width={40}
              />
              <span>{teamAData ? teamBData?.short_name : "Team B"}</span>
            </div>
          </div>

          <h4 className="text-center mt-4">Select One</h4>
          <div className="d-flex align-items-center justify-content-center">
            <div
              className={`toss-team-block shadow-sm  ${
                tossData.choice == "bat" && "selected-team"
              }`}
              onClick={() => setTossData({ ...tossData, choice: "bat" })}
            >
              {"Bat"}
            </div>
            <div
              className={`toss-team-block shadow-sm   ${
                tossData.choice == "ball" && "selected-team"
              }`}
              onClick={() => setTossData({ ...tossData, choice: "ball" })}
            >
              {"Ball"}
            </div>
          </div>
          <div className="w-100 d-flex flex-column align-items-center justify-content-center mt-4">
            <button className=" btn btn-primary m-auto" onClick={sendTossEvent}>
              Submit
            </button>
            <button
              className="btn btn-warning"
              style={{ marginTop: "1rem" }}
              onClick={() => setCustomShow(true)}
            >
              Custom message
            </button>
            <h4 className="text-center text-danger">{tossError}</h4>
          </div>
        </div>
      </>
    );
  }
  // show if toss is done and waiting for game play
  else
    return (
      <>
      <SuperOverTossModal show={showSuperOverTossModal} teamAData={teamAData} teamBData={teamBData} onHide={() => setSuperOverTossModal(false)}/>
      <TieMatchModal 
      show={showDrawModal}
      onHide={()=> setShowDrawModal(false)}
      tossModalFunction={setSuperOverTossModal}
      />
        <WicketEvents
          wicketClose={() => setWicketShow(false)}
          wicketShow={wicketShow}
          actionFunction={sendEvent}
        />
        <WideEvents
          wideClose={() => setWideShow(false)}
          wideShow={wideShow}
          actionFunction={sendEvent}
        />
        <LegByEvents
          legByClose={() => setLegByShow(false)}
          legByShow={legByShow}
          actionFunction={sendEvent}
        />
        <NoBallEvents
          noBallClose={() => setNoBallShow(false)}
          noBallShow={noBallShow}
          actionFunction={sendEvent}
        />
        <InjuryEvents
          injuryClose={() => setInjuryShow(false)}
          injuryShow={injuryShow}
          actionFunction={sendDisplayEvent}
        />
        <CheckEvents
          checkClose={() => setCheckShow(false)}
          checkShow={checkShow}
          actionFunction={sendDisplayEvent}
        />
        <ExtraEvents
          extraClose={() => setExtraShow(false)}
          extraShow={extraShow}
          actionFunction={sendDisplayEvent}
        />
        <MatchEvents
          matchClose={() => setMatchShow(false)}
          matchShow={matchShow}
          actionFunction={sendDisplayEvent}
        />
        <CustomMessage
          show={customShow}
          onHide={() => setCustomShow(false)}
          customText={customText}
          handleCustomTextChange={setCutomText}
          currentPermanentText={currentPermanentText}
          removePermanentText={removePermanentText}
          sendEvent={sendCutomEvent}
        />
        <BatsmanModal
          show={batsmanModalShow}
          onHide={() => setBatsmanModal(false)}
          playerList={battingTeamPlayers}
          handleBatsmanSelection={updateBatsmenEvent}
          batsmanNumber={batsManNumber}
          currentBatsmen={currentBatsmen}
        />
        <BowlerModal
          show={bowlerModalShow}
          onHide={() => setBowlerModal(false)}
          playerList={bowlingTeamPlayers}
          handleBowlerSelection={updateBowlerEvent}
          lastBowler={currentBowler}
        />
        <FancyModal 
        show={fancyModalShow}
        onHide={() => setFancyModalShow(false)}
        matchId={props.matchId == "" ? matchIdFromLocalStorage : props.matchId}
        sendEvent={updateOverSessions}
        removeSlots={removeSlots}
        socketInstance={socketGet}
        />
        <SessionHistoryModal 
        show={sessionModalShow}
        onHide={() => setSessionModalShow(false)}
        matchId={props.matchId == "" ? matchIdFromLocalStorage : props.matchId}
        />
        <ScoreInputModal 
        balls={currentBatsmen?.batsman1?.balls ?? 0}
        score={currentBatsmen?.batsman1?.runs ?? 0}
        updatePlayer={setCurrentBatsmen}
        type="1"
        currentBatsmen={currentBatsmen}
        sendUpdatedPlayerData={playerStatsFromInput}
        show={batsman1Options}
        onHide={() => showBatsman1Options(false)}
        title={currentBatsmen?.batsman1?.name ?? "Batsman 1"}
        />
        <ScoreInputModal
        balls={currentBatsmen?.batsman2?.balls ?? 0}
        score={currentBatsmen?.batsman2?.runs ?? 0}
        updatePlayer={setCurrentBatsmen}
        type="2"
        currentBatsmen={currentBatsmen}
        sendUpdatedPlayerData={playerStatsFromInput}
        show={batsman2Options}
        onHide={() => showBatsman2Options(false)}
        title={currentBatsmen?.batsman2?.name ?? "Batsman 2"}
        />
        <TeamScoreInputModal 
        score={teamaScore}
        wicket={teamAWickets}
        type={'1'}
        show={showTeamaScoreModal}
        onHide={()=> setShowTeamaScoreModal(false)}
        updateScoreHandler={updateTeamScoreEvent}
        />
        <TeamScoreInputModal 
        score={teambScore}
        wicket={teamBWickets}
        type={'2'}
        show={showTeambScoreModal}
        onHide={()=> setShowTeambScoreModal(false)}
        updateScoreHandler={updateTeamScoreEvent}
        />

        <BowlerStatsModal 
        overs={getOverString(currentBowler?.overs)}
        updatePlayer={setCurrentBowler}
        currentBowler={currentBowler}
        sendUpdatedPlayerData={updateBowlerStats}
        show={bowlerOptions}
        onHide={() => showBowlerOptions(false)}
        title={currentBowler?.name ?? "Bowler"}
        />
        <UpdateInningOvers 
        overs={currentInningOvers}
        show={showInningOverModal}
        onHide={() => setShowInningOverModal(false)}
        updateOverHandler={currentInningOversEvent}
        />


        <Card
          className="p-2 agent-match-screen shadow"
          style={{ overflowY: "hidden" }}
        >
          <div className="score-view-container rounded-xl shadow">
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="team-score" onClick={()=>setShowTeamaScoreModal(true)}>
                {teamAData && teamAData?.short_name} {teamaScore}-
                {teamAWickets == "" ? 0 : teamAWickets}
              </div>
              <div
                ref={screenTextRef}
                className="ball animate__animated"
                
              >
                {screenTextDisplayVariable}
              </div>
              <div className="team-score" onClick={()=>setShowTeambScoreModal(true)}>
                {teamBData && teamBData?.short_name} {teambScore}-
                {teamBWickets == "" ? 0 : teamBWickets}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex align-items-center pointer">
                <div
                  className="team-score player-info"
                  onClick={() =>
                    changeStrikeEvent(currentBatsmen?.batsman1?.playerId ?? "")
                  }
                >
                  {currentBatsmen?.batsman1?.name ?? "___"}
                  {currentBatsmen?.batsman1?.onStrike && "(*)"}
                </div>
                  
                <img
                  src={Edit}
                  className="edit-icon rounded"
                  onClick={() => showBatsman1Options(!batsman1Options)}
                  alt=""
                  width={30}
                  height={30}
                />
              </div>
              <div className="team-score player-info">
                Bowler: {currentBowler?.name}
                {isPPlayActive && (
                  <img
                    src={Power}
                    className="ml-2"
                    alt=""
                    width={20}
                    height={20}
                  />
                )}{" "}
              </div>

              <div className="d-flex align-items-center pointer">
                <div
                  className="team-score player-info pointer"
                  onClick={() =>
                    changeStrikeEvent(currentBatsmen?.batsman2?.playerId ?? "")
                  }
                >
                  {currentBatsmen?.batsman2?.name ?? "___"}
                  {currentBatsmen?.batsman2?.onStrike && "(*)"}
                  
                </div>
                <img
                  src={Edit}
                  className="edit-icon rounded"
                  onClick={() => showBatsman2Options(!batsman2Options)}
                  alt=""
                  width={30}
                  height={30}
                />
              </div>
            </div>
            <div className="overs ">
              Over: {getOverString(liveDeliveries ?? 0)}
            </div>
            <div className="d-flex align-items-center justify-content-between w-75 run-rate-container">
              <div>CR: {currentRR}</div>
              <div>RR: {inningNumber == 1 ? requiredRR : "__"}</div>
              <div>TR: {inningNumber == 1 ? target : "__"}</div>
              <div className="d-flex align-items-center ">
              <button
                className="btn btn-success py-2 px-2 mr-1"
                style={{ fontSize: "12px" }}
                onClick={() => setCustomShow(true)}
              >
                Custom message
              </button>
              <button
                className="btn btn-warning py-2 px-2"
                style={{ fontSize: "12px" }}
                onClick={() => setShowInningOverModal(true)}
              >
                Ing. Overs
              </button>
              </div>
            </div>
            <OverSlider data={commentaries} />
          </div>
          <div className="event-grid">
            <div className="row">
              <div
                className={`col-1 event-block event-block-sm shadow-sm ${
                  eventPressed == "0" && "active-event text-white shadow"
                } `}
                onClick={() => {
                  setEventPressed("0");
                  sendEvent("run", "0");
                }}
              >
                <div>0</div>
                <div className="event-key-text-sm">Press- 0</div>
              </div>
              <div
                className={`col-1 event-block event-block-sm shadow-sm ${
                  eventPressed == "1" && "active-event text-white shadow"
                } `}
                onClick={() => {
                  setEventPressed("1");
                  sendEvent("run", "1");
                }}
              >
                <div>1 Run</div>
                <div className="event-key-text-sm">Press- 1</div>
              </div>
              <div
                className={`col-1 event-block event-block-sm shadow-sm ${
                  eventPressed == "2" && "active-event text-white shadow"
                } `}
                onClick={() => {
                  setEventPressed("2");
                  sendEvent("run", "2");
                }}
              >
                <div>2 Runs</div>
                <div className="event-key-text-sm">Press- 2</div>
              </div>
              <div
                className={`col-1 event-block event-block-sm shadow-sm ${
                  eventPressed == "3" && "active-event text-white shadow"
                } `}
                onClick={() => {
                  setEventPressed("3");
                  sendEvent("run", "3");
                }}
              >
                <div>3 Runs</div>
                <div className="event-key-text-sm">Press- 3</div>
              </div>
              <div
                className={`col-1 event-block event-block-sm shadow-sm ${
                  eventPressed == "4" && "active-event text-white shadow"
                } `}
                onClick={() => {
                  setEventPressed("4");
                  sendEvent("run", "4");
                }}
              >
                <div>Four</div>
                <div className="event-key-text-sm">Press- 4</div>
              </div>
              <div
                className={`col-1 event-block event-block-sm shadow-sm ${
                  eventPressed == "5" && "active-event text-white shadow"
                } `}
                onClick={() => {
                  setEventPressed("5");
                  sendEvent("run", "5");
                }}
              >
                <div>Six</div>
                <div className="event-key-text-sm">Press- 5</div>
              </div>
              <div
                className={`col-1 event-block event-block-sm shadow-sm ${
                  eventPressed == "6" && "active-event text-white shadow"
                } `}
                onClick={() => {
                  setEventPressed("6");
                  sendEvent("run", "6");
                }}
              >
                <div>Six</div>
                <div className="event-key-text-sm">Press- 6</div>
              </div>

              <div
                className={`col-1 event-block event-block-sm shadow-sm ${
                  eventPressed == "p" && "active-event text-white shadow"
                } `}
                style={{fontSize: "10px"}}
                onClick={() => {
                  setEventPressed("p");
                  sendDisplayEvent("Power Surge");
                }}
              >
                <div>P. surge</div>
                <div className="event-key-text-sm">Press- P</div>
              </div>
            </div>
            <div className="row">
              <div
                className={`col-2 event-block shadow-sm ${
                  eventPressed == "Space" && "active-event text-white shadow"
                } `}
                // onTouchEnd={() => {
                //   ballStart();
                //   setEventPressed("Space");
                //   setExpressionPressed("");
                // }}
                onClick={() => {
                  setEventPressed("Space");
                  setExpressionPressed("");
                  ballStart();
                }}
              >
                <div>Ball Start</div>
                <div className="event-key-text">Press- Space</div>
              </div>
              <div
                className={`col-2 event-block shadow-sm ${
                  eventPressed == "w" && "active-event text-white shadow"
                } `}
                // onTouchEnd={() => {
                //   setEventPressed("w");
                //   sendEvent("wicket");
                //   setWicketShow(true);
                // }}
                onClick={() => {
                  setEventPressed("w");
                  sendEvent("wicket");
                  setWicketShow(true);
                }}
              >
                <div>Wicket</div>
                <div className="event-key-text">Press- W</div>
              </div>
              <div
                className={`col-2 event-block shadow-sm ${
                  eventPressed == "n" && "active-event text-white shadow"
                } `}
                onClick={() => {
                  setEventPressed("n");
                  sendEvent("no ball", "NB");
                  setNoBallShow(true);
                }}
              >
                <div>No Ball</div>
                <div className="event-key-text">Press- N</div>
              </div>
              <div
                className={`col-2 event-block shadow-sm ${
                  eventPressed == "v" && "active-event text-white shadow"
                } `}
                onClick={() => {
                  setEventPressed("v");
                  sendEvent("wide", "WD");
                  setWideShow(true);
                }}
              >
                <div>Wide</div>
                <div className="event-key-text">Press- V</div>
              </div>
            </div>
          </div>

          <div className="expression-grid d-flex  justify-content-between">
            <div className=" w-75">
              <div
                className={`btn expression-block shadow-sm ${
                  expressionPressed == "`" && "shadow active-expression"
                } `}
                onTouchEnd={() => {
                  setExpressionPressed("`");
                  // sendDisplayEvent("Confirming");
                  undoKeyEvent();
                }}
                // onClick={() => {
                //   setExpressionPressed("`");
                //   sendDisplayEvent("Confirming");
                // }}
              >
                <div>Undo</div>
                <div className="expression-key-code">Press- `</div>
              </div>
              <div
                className={`btn expression-block shadow-sm ${
                  expressionPressed == "enter" && "shadow active-expression"
                } `}
                onClick={() => {
                  setGlobalScoreVariable(totalScore);
                  setGlobalWicketVariable(wickets);
                  setBowlerModal(true);
                  setExpressionPressed("enter");
                  setEventPressed("");
                  sendEvent("overEnd", "Over End");
                }}
                // onClick={() => {
                //   setGlobalScoreVariable(totalScore);
                //   setGlobalWicketVariable(wickets);
                //   setExpressionPressed("enter");
                //   // ballStart();
                //   setEventPressed("");
                //   sendEvent("overEnd", "Over End");
                // }}
              >
                <div>Over End </div>
                <div className="expression-key-code">Press- enter</div>
              </div>
              <div
                className={`btn expression-block shadow-sm ${
                  expressionPressed == "d" && "shadow active-expression"
                } `}
                onClick={() => {
                  setExpressionPressed("d");
                  sendDisplayEvent("Catch Drop");
                }}
                // onClick={() => {
                //   setExpressionPressed("d");
                //   sendDisplayEvent("Catch Drop");
                // }}
              >
                <div>Catch Drop </div>
                <div className="expression-key-code">Press- d</div>
              </div>
              <div
                className={`btn expression-block shadow-sm ${
                  expressionPressed == "o" && "shadow active-expression"
                } `}
                onClick={() => {
                  setExpressionPressed("o");
                  sendDisplayEvent("Over Throw");
                }}
                // onClick={() => {
                //   setExpressionPressed("o");
                //   sendDisplayEvent("Over Throw");
                // }}
              >
                <div>over Throw </div>
                <div className="expression-key-code">Press- o</div>
              </div>
              <div
                className={`btn expression-block shadow-sm ${
                  expressionPressed == "b" && "shadow active-expression"
                } `}
                onClick={() => {
                  sendEvent("legby", "LB");
                  setExpressionPressed("b");
                  setLegByShow(true);
                }}
                // onClick={() => {
                  
                // }}
              >
                <div>Leg By </div>
                <div className="expression-key-code">Press- b</div>
              </div>
              <div
                className={`btn expression-block shadow-sm ${
                  expressionPressed == "s" && "shadow active-expression"
                } `}
                onClick={() => {
                  setExpressionPressed("s");
                  sendDisplayEvent("Bowler Stop");
                }}
                // onClick={() => {
                //   setExpressionPressed("s");
                //   sendDisplayEvent("Bowler Stop");
                // }}
              >
                <div>Bowler Stop </div>
                <div className="expression-key-code">Press- s</div>
              </div>
              <div
                className={`btn expression-block shadow-sm ${
                  expressionPressed == "a" && "shadow active-expression"
                } `}
                onClick={() => {
                  setExpressionPressed("a");
                  sendDisplayEvent("Appeal");
                }}
                // onClick={() => {
                //   setExpressionPressed("a");
                //   sendDisplayEvent("Appeal");
                // }}
              >
                <div>Appeal </div>
                <div className="expression-key-code">Press- a</div>
              </div>
              <div
                className={`btn expression-block shadow-sm ${
                  expressionPressed == "h" && "shadow active-expression"
                } `}
                onTouchEnd={() => {
                  setExpressionPressed("h");
                  sendDisplayEvent("In Air");
                }}
                // onClick={() => {
                //   setExpressionPressed("h");
                //   sendDisplayEvent("In Air");
                // }}
              >
                <div>In air </div>
                <div className="expression-key-code">Press- h</div>
              </div>
              <div
                className={`btn expression-block shadow-sm ${
                  expressionPressed == "f" && "shadow active-expression"
                } `}
                onTouchEnd={() => {
                  setExpressionPressed("f");
                  sendDisplayEvent("Free Hit");
                }}
                // onClick={() => {
                //   setExpressionPressed("f");
                //   sendDisplayEvent("Free Hit");
                // }}
              >
                <div>Free Hit </div>
                <div className="expression-key-code">Press- F</div>
              </div>
              <div
                className={`btn expression-block shadow-sm ${
                  expressionPressed == "/" && "shadow active-expression"
                } `}
                onTouchEnd={() => {
                  setExpressionPressed("/");
                  sendDisplayEvent("Boundary check");
                }}
                // onClick={() => {
                //   setExpressionPressed("/");
                //   sendDisplayEvent("Boundary check");
                // }}
              >
                <div>Boundary check </div>
                <div className="expression-key-code">Press- /</div>
              </div>
              <div
                className={`btn expression-block shadow-sm ${
                  expressionPressed == "x" && "shadow active-expression"
                } `}
                onTouchEnd={() => {
                  setExpressionPressed("x");
                  sendEvent("not out");
                }}
                // onClick={() => {
                //   setExpressionPressed("x");
                //   sendEvent("not out");
                // }}
              >
                <div>Not out </div>
                <div className="expression-key-code">Press- x</div>
              </div>
              <div
                className={`btn expression-block other-btn shadow-sm  `}
                onClick={openMatchEvents}
                // onTouchEnd={openMatchEvents}
              >
                <div>Match </div>
                <div className="expression-key-code">Press-m</div>
              </div>
              <div
                className={`btn expression-block other-btn shadow-sm `}
                onClick={openExtraEvents}
                // onTouchEnd={openExtraEvents}
              >
                <div>Extra </div>
                <div className="expression-key-code">Press- e</div>
              </div>
              <div
                className={`btn expression-block other-btn shadow-sm  `}
                onClick={openCheckEvents}
                // onTouchEnd={openCheckEvents}
              >
                <div>Check </div>
                <div className="expression-key-code">Press- c</div>
              </div>
              <div
                className={`btn expression-block other-btn shadow-sm `}
                onClick={openInjuryEvents}
                // onTouchEnd={openInjuryEvents}
              >
                <div>Injury </div>
                <div className="expression-key-code">Press- i</div>
              </div>
              <div
                className={`btn expression-block other-btn shadow-sm `}
                onClick={()=> switchMatchToApi(true)}
              >
                <div>Match Via </div>
                <span>({isMatchViaSocket ? "Admin" : "Api"})</span>
              </div>
              <div
                className={`btn btn-warning p-2 px-3 shadow-sm `}
                onClick={()=> resetMatchTossLocal()}
              >
                <div>Reset Match </div>
              </div>
            </div>
            <div
              className="h-100 w-25 d-flex flex-column position-relative"
              style={{ marginTop: "-1rem" }}
            >
              <div className="w-100 text-center text-black h6">SCORECARD</div>
              <div className="row align-items-center text-black mb-1">
                <div className="col">Batsman 1:</div>
                <div
                  className="btn shadow btn-secondary col"
                  onClick={() => {
                    setBatsManNumber(1);
                    setBatsmanModal(true);
                  }}
                >
                  {getSlicedPlayerName(currentBatsmen?.batsman1?.name)}
                  {`(${currentBatsmen?.batsman1?.runs ?? 0})`}
                  {`(${currentBatsmen?.batsman1?.balls ?? 0})`}
                </div>
              </div>
              <div className="row align-items-center text-black mb-1">
                <div className="col">Batsman 2:</div>
                <div
                  className="btn shadow btn-secondary shadow col"
                  onClick={() => {
                    setBatsManNumber(2);
                    setBatsmanModal(true);
                  }}
                >
                  {getSlicedPlayerName(currentBatsmen?.batsman2?.name)}
                  {`(${currentBatsmen?.batsman2?.runs ?? 0})`}
                  {`(${currentBatsmen?.batsman2?.balls ?? 0})`}
                </div>
              </div>
              <div className="row align-items-center text-black">
                <div className="col">Bowler:
                <img
                  src={Edit}
                  className="edit-icon rounded pointer border"
                  onClick={() => showBowlerOptions(!bowlerOptions)}
                  alt=""
                  width={30}
                  height={30}
                />
                </div>
                <div
                  className="btn shadow btn-primary col"
                  onClick={() => setBowlerModal(true)}
                >
                  {getSlicedPlayerName(currentBowler?.name)}
                  {`(${getOverString(currentBowler?.overs ?? 0)})`}
                  {`(${currentBowler?.runs ?? 0})`}
                </div>
              </div>
              <div className="px-3 py-2 text-white shadow rounded bg-warning fancy-button pointer" onClick={()=>  setFancyModalShow(true)}>Fancy</div>
              <div className="px-3 py-2 text-white shadow rounded bg-primary lineup-button pointer" onClick={()=>  history.push("/player-selection")}>Lineup</div>
              <div className="px-3 py-2 text-white shadow rounded bg-secondary sessions-button pointer" onClick={()=> setSessionModalShow(true)}>Sessions</div>

            </div>
          </div>
        </Card>
      </>
    );
}

const mapStateToProps = (state) => {
  return {
    matchId: state.auth.agentSelectedMatchId,
    isLoading: state.auth.showLoading,
  };
};
export default withRouter(connect(mapStateToProps)(AgentMatchScreen));
