import React from "react";
import { Input } from "react-dropzone-uploader";
import { AWS_TIPS_AUDIO, AWS_TIPS_IMAGE } from "../pages/test";

function MessageBox({ message, image, audioURL, handleDelete, tipId }) {
  return (
    <div className="message-box p-3 shadow-sm rounded text-monospace mb-2">
      {message && <div>{message}</div>}
      {image && (
        <img
          src={AWS_TIPS_IMAGE + image}
          className="rounded"
          style={{ maxWidth: "300px", maxHeight: "300px", objectFit: "cover" }}
        />
      )}
      {audioURL && (
        <audio
          controls
          src={AWS_TIPS_AUDIO + audioURL}
          className="mt-2"
        ></audio>
      )}

      <div className="mt-2 d-flex justify-content-between align-items-center w-100 border-top pt-2">
        <div>11:00am</div>
        <button
          className="btn btn-danger py-1 px-2"
          onClick={() => handleDelete(tipId)}
        >
          Delete
        </button>
      </div>
    </div>
  );
}

export default MessageBox;
