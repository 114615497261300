import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { Badge, Dropdown } from "react-bootstrap";
// import { ToastContainer } from "react-toastify";
import toast from "react-hot-toast";
import { blockUser, deleteUser, user } from "../../services/AuthService";
import EditUser from "./EditUser";
import moment from "moment";
import CreateUser from "./CreateUser";
import { Row, Col, Card, Button, Tab, Nav } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { AWS_PHOTO_BASE_URL } from "../pages/test";
import CreatePackage from "./CreatePackage";
import { loadingToggleAction } from "../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import {
  createPackage,
  flagUserById,
  updateUserAccount,
} from "../../services/asyncFunctions";
import ReviewRequest from "./modals/ReviewRequest";
import { getUserToken } from "../../services/utils/generalUtils";
import AddCoinsModal from "./modals/AddCoinsModals";
import { CSVLink } from "react-csv";
import ConfirmDataExport from "./modals/ConfirmDataExport";

const options = [
  { value: "user", label: "Better" },
  { value: "tipper", label: "Tipper" },
];
const csvData = [
  ["firstname", "lastname", "email"],
  ["Ahmed", "Tomi", "ah@smthing.co.com"],
  ["Raed", "Labes", "rl@smthing.co.com"],
  ["Yezzi", "Min l3b", "ymin@cocococo.com"],
];
const headers = [
  { label: "USERNAME", key: "userName" },
  { label: "USERTYPE", key: "userType" },
  { label: "EMAIL", key: "email" },
  { label: "IMAGE", key: "profileImage" },
  { label: "COINS", key: "sportExCoins" },
  { label: "CREATION DATE", key: "creationDate" },
  { label: "CREATION TIME", key: "creationTime" },
  { label: "STRIPEID", key: "stripeCustomerId" },
  { label: "PHONE NUMBER", key: "phoneNumber" },
  { label: "DIAMONDS", key: "diamonds" },
  { label: "VERIFIED", key: "isVerified" },
  {label:"COUNTRY CODE", key: "countryCode" },
];

const UserManagementTable = () => {
  const [createUserModal, setCreateUserModal] = useState(false);
  const [createPackageModal, setCreatePackageModal] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const dispatch = useDispatch();

  const [userName, setUserName] = useState("");
  const [editeUserModal, setEditUserModal] = useState(false);
  const history = useHistory();
  const [tipperName, setTipperName] = useState("");

  // console.log(userName, "kkkk");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userType, setUserType] = useState("user");
  const [createDate, setCreateDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showExportModal, setShowExportModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [pageNumber, setPageNumber] = useState(0);
  const [tipperType, setTipperType] = useState("pending");
  const [tipperId, setTipperId] = useState("");
  const [accountsModal, setAccountsModal] = useState(false);
  const initialLoadRef = useRef(true);
  const [approvalStatus, setApprovalStatus] = useState(null);
  const [accountData, setAccountData] = useState({
    coins: 0,
    diamonds: 0,
  });

  let limit = 6;
  // console.log(userId, "kkkkk");
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const notifyTopRight = () => {
    toast.success("✅successfull!")
  };

  const notifyError = () => {
toast.error("❌ Error !")

  };

  function deleteUserTipper(id) {
    deleteUser(id, userType).then((response) => {
      console.log(response, "user data delete response");
      user(
        userName,
        email,
        phoneNumber,
        userType,
        createDate,
        limit,
        pageNumber
      ).then((response) => {
        console.log(response, "user data response");
        setUsers(response.data.data.user);
      });
      notifyTopRight();
    });
  }
  function blockUserTipper(id) {
    blockUser(id, userType).then((response) => {
      console.log(response, "user data block response");
      user(
        userName,
        email,
        phoneNumber,
        userType,
        createDate,
        limit,
        pageNumber
      ).then((response) => {
        setUsers(response.data.data.user);
      });
    });
  }

  async function flagUser(userId, status){
    let statusLocal;
    if(status === undefined) statusLocal = true;
    else statusLocal = !status;
    dispatch(loadingToggleAction(true));
    try {
      let res = await flagUserById(userId, userType, statusLocal);
      notifyTopRight();
      fetchUsers();
    } catch (e) {
      notifyError();
    } finally {
      dispatch(loadingToggleAction(false));
    }
  }

  function handleAccountUpdate(event) {
    setAccountData({
      ...accountData,
      [event.target.name]: event.target.value,
    });
  }

  const handlePageClick = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected;
    setPageNumber(currentPage)
  };

  function fetchUsers(limit = 6) {
    dispatch(loadingToggleAction(true));
    user(userName, email, phoneNumber, userType, createDate, limit, pageNumber, tipperType)
      .then((response) => {
        if(response.data.data.user.length > 10){
          setAllUsers(response.data.data.user)
          setShowExportModal(true);
        }else{
          console.log(response);
          setUsers(response.data.data.user);
          const total = response.data.data.count;
          setpageCount(Math.ceil(total / limit));
        }
        dispatch(loadingToggleAction(false));

      })
      .catch((e) => {
        console.log(e);
        dispatch(loadingToggleAction(false));
      });
      initialLoadRef.current = false;
  }

  async function updateAccout() {
    dispatch(loadingToggleAction(true));
    try {
      let res = await updateUserAccount({ userId, userType, ...accountData });
      console.log(res);
      notifyTopRight();
      setAccountsModal(false);
    } catch (e) {
      notifyError();
    } finally {
      dispatch(loadingToggleAction(false));
    }
  }

  function getAllUsers(){
    if (getUserToken()) {
      fetchUsers(1000000);
    }
  }

  function goToUser(userId){
      if(!userId) return;
      localStorage.setItem('userDetailsId', JSON.stringify({userId, userType}));
      history.push('user-details');
  }

  useEffect(() => {
    if (initialLoadRef.current || pageNumber > 0) {
      
      fetchUsers(6);
    }
  }, [pageNumber]);




useEffect(() => {
if(!initialLoadRef.current){
  fetchUsers(6);
}
return () => setPageNumber(0);
},[userType, tipperType]);


  return (
    <Fragment>
      <EditUser
        show={editeUserModal}
        close={() => setEditUserModal(false)}
        id={userId}
        table={() => fetchUsers()}
      />
      <CreateUser
        show={createUserModal}
        close={() => setCreateUserModal(false)}
        table={() => fetchUsers()}
      />
      <CreatePackage
        show={createPackageModal}
        close={() => setCreatePackageModal(false)}
        tipperId={tipperId}
      />

      <ReviewRequest
        show={requestModal}
        close={() => setRequestModal(false)}
        tipperId={tipperId}
        tipperName={tipperName}
        fetchUsers={fetchUsers}
        approvalStatus={approvalStatus}
      />
      <AddCoinsModal
        show={accountsModal}
        close={() => setAccountsModal(false)}
        handleAccountUpdate={handleAccountUpdate}
        submitUpdatedAccount={updateAccout}
      />
      <ConfirmDataExport
        data={allUsers}
        headers={headers}
        show={showExportModal}
        close={() => setShowExportModal(false)}
      />

      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
          <div>
            <Card.Title>User Management</Card.Title>
          </div>
          <div>
            <Button
              className=""
              variant="outline-secondary"
              onClick={() => setCreateUserModal(true)}
            >
              Create User
            </Button>
            
          </div>
        </Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-12">
              <div>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="form-group">
                      <label className="mb-2 ">
                        <strong className="">User Name</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="form-group">
                      <label className="mb-2 ">
                        <strong className="">Email</strong>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="form-group">
                      <label className="mb-2 ">
                        <strong className="">Phone Number</strong>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="form-group">
                      <label className="mb-2 ">
                        <strong className="">User Type</strong>
                      </label>
                      <select
                        defaultValue={userType}
                        name="emailstatus"
                        className="form-control"
                        onChange={(e) => setUserType(e.target.value)}
                      >
                        <option value="tipper">Tipper</option>
                        <option value="user">Fan</option>
                      </select>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="form-group mr-2">
                      <label className="mb-2 ">
                        <strong className=""> start Date</strong>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        onChange={(e) =>
                          setCreateDate(
                            moment(e.target.value).format("DD-MM-YYYY")
                          )
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label className="mb-2 ">
                        <strong className="">End Date</strong>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        onChange={(e) =>
                          setCreateDate(
                            moment(e.target.value).format("DD-MM-YYYY")
                          )
                        }
                      />
                    </div>
                  </div>
                 {userType == "tipper" && <div className="col-12 col-md-6 col-lg-3">
                    <div className="form-group">
                      <label className="mb-2 ">
                        <strong className="">Tipper Approval Status</strong>
                      </label>
                      <select
                        defaultValue={userType}
                        name="emailstatus"
                        className="form-control"
                        onChange={(e) => setTipperType(e.target.value)}
                      >
                        <option value="pending">Pending</option>
                        <option value="accepted">Approved</option>
                        <option value="rejected">Rejected</option>
                      </select>
                    </div>
                  </div>}
                </div>
                <div className="mb-5">
                  <button
                    className="btn bg-app-gradient text-white mr-3 "
                    onClick={() => fetchUsers()}
                  >
                    Search
                  </button>
                  <button type="submit" className="btn btn-dark ">
                    Clear
                  </button>
                </div>
              </div>
              <div></div>
              <button
                className="btn btn-secondary ml-2 text-white"
                onClick={getAllUsers}
              >
                Export Data
              </button>
              <div className="table-responsive">
                <div id="order_list" className="dataTables_wrapper no-footer">
                  <table
                    id="example5"
                    className="display mb-4  dataTable no-footer w-100 "
                    style={{ minWidth: 845 }}
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Customer Name: activate to sort column ascending"
                          style={{ width: "85.3333px" }}
                        >
                          Photo
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          style={{ width: "80.6667px" }}
                        >
                          Username
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          style={{ width: "90px" }}
                        >
                          Email Id
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          style={{ width: "80px" }}
                        >
                          Phone Number
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          style={{ width: "89.3333px" }}
                        >
                          Create Date
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          style={{ width: "89.3333px" }}
                        >
                          Status
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          style={{ width: "50.3333px" }}
                        >
                          Approval status
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example5"
                          rowSpan={1}
                          colSpan={1}
                          style={{ width: "89.3333px" }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {users.map((item) => (
                        <tr
                          key={item._id}
                          role="row"
                          className={`odd border-bottom ${item?.isTeamMember && "highlighet-table-row"}`}
                    
                        >
                          <td className="">
                            {item.profileImage && (
                              <img
                                src={AWS_PHOTO_BASE_URL + item.profileImage}
                                style={{ height: "60px", width: "60px" }}
                              />
                            )}
                            {item.profileImage == "" && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="60"
                                height="60"
                                fill="currentColor"
                                class="bi bi-image"
                                viewBox="0 0 16 16"
                              >
                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />
                              </svg>
                            )}
                          </td>
                          <td>
                            <div className="pointer" onClick={()=> goToUser(item._id)}>{item.userName}</div>{" "}
                          </td>
                          <td>
                            {item?.email
                              ? item.email.slice(0, 10) + "..."
                              : item.email}
                          </td>
                          <td>{item.phoneNumber}</td>
                          <td>
                            {moment(item.createdAt).format("DD-MM-YYYY")}
                            <br/>
                            <span className="fs-12">({moment(item.createdAt).format("LT")})</span>
                          </td>
                          <td>
                            {item.isBlocked === true ? (
                              <Badge variant="danger light">Block</Badge>
                            ) : (
                              <Badge variant="success light">Active</Badge>
                            )}
                          </td>

                          <td>
                            {userType == "tipper"
                              ? item?.adminApprovalStatus
                              : "Fan"}
                          </td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="info light"
                                className="light sharp btn btn-info i-false"
                              >
                                {svg1}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    setUserId(item._id);
                                    setEditUserModal(true);
                                  }}
                                >
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    flagUser(item._id, item?.isTeamMember);
                                  }}
                                >
                                  {item?.isTeamMember ? "Unflag User" : "flag User"}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    blockUserTipper(item._id);
                                  }}
                                >
                                  {item.isBlocked ? "Unblock" : "Block"}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    deleteUserTipper(item._id);
                                  }}
                                >
                                  Delete
                                </Dropdown.Item>
                                {userType == "tipper" && (
                                  <Dropdown.Item
                                    onClick={() => {
                                      setTipperId(item._id);
                                      setCreatePackageModal(true);
                                    }}
                                  >
                                    Create Package
                                  </Dropdown.Item>
                                )}
                                {userType == "tipper" && item.adminApprovalStatus == "pending" && (
                                  <Dropdown.Item
                                    onClick={() => {
                                      setTipperId(item._id);
                                      setRequestModal(true);
                                      setTipperName(item.userName);
                                      setApprovalStatus(item.adminApprovalStatus);
                                    }}
                                  >
                                    Respond to request
                                  </Dropdown.Item>
                                )}
                                {userType == "tipper" && (item.adminApprovalStatus == "accepted" || item.adminApprovalStatus == "rejected") && (
                                  <Dropdown.Item
                                    onClick={() => {
                                      setTipperId(item._id);
                                      setTipperName(item.userName);
                                      setRequestModal(true);
                                      setApprovalStatus(item.adminApprovalStatus);
                                      
                                    }}
                                  >
                                    {item.adminApprovalStatus == "accepted" ?"Reject as a Tipper" : "Approve as a Tipper"}
                                  </Dropdown.Item>
                                )}
                                <Dropdown.Item
                                  onClick={() => {
                                    setUserId(item._id);
                                    setAccountsModal(true);
                                   
                                  }}
                                >
                                  Add coins
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="mt-4">
                {pageCount > 1 && (
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                    forcePage={pageNumber}
                  />
                )}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default UserManagementTable;


const svg1 = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);
