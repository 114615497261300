import React, { useEffect, useMemo, useState } from "react";
import {
  addSubAdmin,
  deleteSubAdmin,
  getSubAdmins,
  updateSubAdmin,
  updateSubAdminPassword,
} from "../../services/asyncFunctions";

import { Card } from "react-bootstrap";
import TableLoaderSubAdmin from "../components/ContentLoaders/TableLoaderSubAdmin";
import AddSubadmin from "../components/modals/AddSubadmin";
import Swal from "sweetalert2";
import ResetSubadminPassword from "../components/modals/ResetSubadminPassword";
import toast from "react-hot-toast";
import EditSubadmin from "../components/modals/EditSubadmin";
import { tabCodes } from "../components/PluginsMenu/Nestable/utils";
import { setUserName } from "../../store/actions/AuthActions";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const toastSuccess = (message) => {
  toast.success(message);
};

const toastError = (message) => {
  toast.error(message);
};

function AdminSettings() {
  const [isLoading, setLoading] = useState(true);
  const [subAdminList, setSubAdminList] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState(null);
  const [selectedRoledObjects, setSelectedRolesObjects] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState(null);
  const userType = useSelector(state=> state.auth.userType);
  const history = useHistory();

  async function addSubAdminWithRoles(payload) {
    setLoading(true);
    try {
      const response = await addSubAdmin(payload);
      console.log(response);
      toastSuccess("SubAdmin createds succesfully!");
      getUserList();
    } catch (error) {
      toastError(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  // Function to edit a user
  async function editSubAdmin(updatedUserData, type) {
    updatedUserData.subAdminId = selectedUserId;
    try {
      if (type == "editUser") {
        const response = await updateSubAdmin(updatedUserData);
        console.log(response);
        toastSuccess("SubAdmin details updated succesfully!");
      } else {
        const response = await updateSubAdminPassword(updatedUserData);
        console.log(response);
        toastSuccess("SubAdmin password updated succesfully!");
      }
      getUserList();
      setSelectedRoles(null);
      setUserName(null);
    } catch (error) {
      toastError(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  // Function to delete a user
  async function deleteUser(userId) {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (!result.isConfirmed) return;
    try {
      const response = await deleteSubAdmin(userId);
      toastSuccess("SubAdmin deleted succesfully!");
      getUserList();
    } catch (error) {
      toastError(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  // Function to get a list of all users
  async function getUserList() {
    try {
      const response = await getSubAdmins();
      console.log(response);
      setSubAdminList(response.data.data.subAdmins);
      setSelectedRolesObjects(null)
    } catch (error) {
      toastError(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }
  function handleUserSelection(userId, user, type) {
    setSelectedUserId(userId);
    if (type == "password") {
      setShowPasswordModal(true);
    } else if (type == "editUser") {
      setShowEditModal(true);
      setSelectedRoles(user.roles);
      setSelectedUserName(user.name);
    }
  }

  useEffect(() => {
    if(userType !== "admin"){
      history.push("/")
    }
    getUserList();
  }, []);

  useEffect(() => {
    if (selectedRoles) {
      let temp = selectedRoles.map((value) => {
        const correspondingObject = tabCodes.filter(
          (obj) => obj.value === value
        );
        if (correspondingObject.length > 0) return correspondingObject[0];
      });
      setSelectedRolesObjects(temp.filter(Boolean));
    }
  }, [selectedRoles]);

  useEffect(()=>{
 if(!showEditModal){
  setSelectedRoles(null);
  setSelectedUserName(null);
 }
  },[showEditModal])




  return (
    <>
      <EditSubadmin
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        name={selectedUserName}
        currentRoles={selectedRoledObjects}
        updateUser={editSubAdmin}
      />
      <ResetSubadminPassword
        show={showPasswordModal}
        onHide={() => setShowPasswordModal(false)}
        updateUserPassword={editSubAdmin}
      />
      <AddSubadmin
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        addUser={addSubAdminWithRoles}
      />
      <Card className="p-3">
        <h1 className="mb-4">Manage Admins</h1>
        <div className="d-flex align-items-center justify-content-end mb-3">
          <button
            className="btn btn-outline-warning py-2 px-4 fs-12"
            onClick={() => setShowAddModal(true)}
          >
            Add +
          </button>
        </div>
        {!isLoading && (
          <div className="table-responsive">
            <table className="table table-bordered table-striped text-monospace">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Reset Password</th>
                  <th>Edit Permission</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {subAdminList &&
                  subAdminList.map((item) => (
                    <tr key={item._id}>
                      <td className="text-dark font-weight-bold">
                        {item.name}
                      </td>
                      <td>{item.email}</td>
                      <td>
                        <button
                          className="btn btn-outline-success py-1 px-3 fs-12"
                          onClick={() =>
                            handleUserSelection(item._id, item, "password")
                          }
                        >
                          Reset Password
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-outline-primary py-1 px-3 fs-12"
                          onClick={() =>
                            handleUserSelection(item._id, item, "editUser")
                          }
                        >
                          Edit
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-outline-danger py-1 px-3 fs-12"
                          onClick={() => deleteUser(item._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                {subAdminList && subAdminList.length == 0 && (
                  <div className="text-center">No Data</div>
                )}
              </tbody>
            </table>
          </div>
        )}
        {isLoading && <TableLoaderSubAdmin />}
      </Card>
    </>
  );
}

export default AdminSettings;
