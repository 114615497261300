import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getAgentLiveMatches, getMatches } from "../../services/asyncFunctions";
import { toastError } from "../components/PluginsMenu/Nestable/utils";
import GlobalCustomMessage from "../components/modals/GlobalCustomMessageModal";
import GlobalFancyModal from "../components/modals/GlobalFancyModal";
import MatchReviewsModal from "./MatchReviewModal";

function SessionsManagement() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [liveMatches, setLiveMatches] = useState(null);
  const [openCustomMessageModal, setOpenCustomMessageModal] = useState(false);
  const [openFancyModal, setOpenFancyModal] = useState(false);
  const [reviewsModal, setReviewsModal] = useState(false);

  const [selectedMatchId, setSelectedMatchId] = useState(null);

  async function fetchMatches() {
    setIsLoading(true);
    try {
        let result = await getAgentLiveMatches();
      setLiveMatches(result?.data?.data);
    } catch (e) {
      toastError("Could not get matches");
    } finally {
      setIsLoading(false);
    }
  }
 function handleMatchSelection(matchId, type){
  setSelectedMatchId(matchId);
  if(type == "message"){
    setOpenCustomMessageModal(true);
  }else if(type == "fancy"){
    setOpenFancyModal(true);
  }else{
    setReviewsModal(true)
  }
 }
  useEffect(()=>{
    fetchMatches();
  },[])
  return (
    <>
    <MatchReviewsModal show={reviewsModal} onHide={()=> setReviewsModal(false)} matchId={selectedMatchId}/>
    <GlobalCustomMessage show={openCustomMessageModal} onHide={()=> setOpenCustomMessageModal(false)} matchId={selectedMatchId}/>
    <GlobalFancyModal show={openFancyModal} onHide={()=>setOpenFancyModal(false)} matchId={selectedMatchId} />
    <Card>
      <Card.Header>
        <Card.Title>Live Matches</Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading && <h2 className="text-center">Loading...</h2>}
        <div className="table-responsive">
          <div id="order_lis" className="dataTables_wrapper no-footer">
            <table
              id="example5"
              className="display mb-4 dataTablesCard dataTable no-footer w-100 "
              style={{ minWidth: 845 }}
              role="grid"
              aria-describedby="example5_info"
            >
              <thead>
                <tr role="row">
                  <th
                    className="sorting_asc"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                  >
                    Match Title
                  </th>
                  <th
                    className="sorting"
                    tabIndex={2}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                  >
                    Sessions
                  </th>
                  <th
                    className="sorting"
                    tabIndex={2}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                  >
                    Fancy
                  </th>
                  <th
                    className="sorting"
                    tabIndex={2}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                  >
                   Reviews
                  </th>
                  <th
                    className="sorting"
                    tabIndex={2}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                  >
                    Message
                  </th>
                </tr>
              </thead>
              <tbody>
                {liveMatches &&
                  liveMatches.map((item) => (
                    <tr role="row" key={item._id}>
                      <td className="pointer match-name">{item.title}</td>
                      {/* <td>{moment(item.date_start).format("DD-MM-YYYY")}</td> */}
                      <td>
                        <span
                          className="btn btn-sm light btn-warning"
                          onClick={() => history.push(`/session-bet-management/${item.matchId}`)}
                        >
                          View sessions
                        </span>
                      </td>
                      <td>
                        <span
                          className="btn btn-sm light btn-warning"
                          onClick={() => handleMatchSelection(item.matchId, "fancy")}
                        >
                          View Fancy
                        </span>
                      </td>
                      <td>
                        <span
                          className="btn btn-sm light btn-warning"
                          onClick={() => handleMatchSelection(item.matchId, "review")}
                        >
                          Reviews
                        </span>
                      </td>
                      <td>
                        <span
                          className="btn btn-sm light btn-warning"
                          onClick={() => handleMatchSelection(item.matchId, "message")}
                        >
                          Update Message
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </Card.Body>
    </Card>
    </>
  );
}

export default SessionsManagement;
