import React, { useEffect, useRef, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
export default function EditVotingModal({
  show,
  onHide,
  votes,
  matchId,
  submitFunction,
}) {
  const inputRef = useRef();
  const [votesObject, setVotesNumber] = useState(votes)


  function handleEventSend(){
    submitFunction(matchId, votesObject);
    onHide();
  }  

 function handleVotesUpdate(value, name){
  if(name == "teama-votes"){
    setVotesNumber({
      ...votesObject,
      teama:{
        ...votesObject.teama,
        adminVote: value
      }
    })
  }else{
    setVotesNumber({
      ...votesObject,
      teamb:{
        ...votesObject.teamb,
        adminVote: value
      }
    })
  }
 }

  useEffect(() => {
    if(show){
      inputRef.current.focus();
    }
  },[show])

  useEffect(()=>{
    setVotesNumber(votes)
  },[votes])
  return (
    <>
      <Modal
        size="lg"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => onHide()}
      >
        <Modal.Header>
          <Modal.Title>Team votes</Modal.Title>
          <Button variant="" className="close" onClick={() => onHide()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center flex-column flex-wrap form-group">
          <div className="d-flex flex-column">
            <label htmlFor={votesObject?.teama?.name} >{votesObject?.teama?.name}</label>
            <input
              type="number"
              ref={inputRef}
              className="custom-text-input"
              name={"teama-votes"}
              value={votesObject?.teama?.adminVote}
              placeholder={"Votes"}
              onChange={(e) => {
                handleVotesUpdate(e.target.value, e.target.name);
              }}
            />
          </div>

<div className="d-flex flex-column">
    <label htmlFor={votesObject?.teamb?.name} className="text-dark" >{votesObject?.teamb?.name}</label>
    <input
              type="number"
              className="custom-text-input"
              name={"teamb-votes"}
              value={votesObject?.teamb?.adminVote}
              placeholder={"Votes"}
              onChange={(e) => {
                handleVotesUpdate(e.target.value, e.target.name);
              }}
            />
</div>
            <button className="btn btn-warning m-auto mt-3" type="button" onClick={handleEventSend}>Update votes</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
