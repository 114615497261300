import React, { useState } from 'react'
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";


function TeamScoreInputModal({type, score,wicket, show, onHide, updateScoreHandler}) {
    const [scoreInput, setScoreInput] = useState(score);
    const [wicketInput, setWicketInput] = useState(wicket);



    function submitFunc(){
        updateScoreHandler(scoreInput,wicketInput, type);
        onHide();
    }

  return (
    <Modal
        size="sm"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => onHide()}
      >
        <Modal.Header>
          <Modal.Title>Edit </Modal.Title>
          <Button variant="" className="close" onClick={() => onHide()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center flex-wrap form-group">
            <input
              type="text"
              className="custom-text-input"
              placeholder="score"
              value={scoreInput}
              onChange={(e) => {
                setScoreInput(e.target.value);
              }}
            />
            <input
              type="text"
              className="custom-text-input"
              placeholder="wicket"
              value={wicketInput}
              onChange={(e) => {
                setWicketInput(e.target.value);
              }}
            />
            <button
              className="btn btn-warning m-auto"
              type="button"
              onClick={submitFunc}
            >
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>
  )
}

export default TeamScoreInputModal
