import React, { useEffect, useState } from "react";
import { Badge, Card } from "react-bootstrap";
import {
  getMatchLoad,
  getMatcheBetsForAllUsers,
  getMatches,
  getUnselltedMatches,
} from "../../services/asyncFunctions";
import {
  toastError,
} from "../components/PluginsMenu/Nestable/utils";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function UnsetteledMatches() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [searchString, setSearchString] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [showExportModal, setShowExportModal] = useState(false);
  const history = useHistory()


  async function fetchMatches(page, date) {
    setIsLoading(true);
    try {
      let result = await getUnselltedMatches(page, date);
      setData(result?.data?.data);
      setFilteredMatches(result?.data?.data);
    } catch (e) {
      toastError("Could not get matches");
    } finally {
      setIsLoading(false);
    }
  }

  const handlePageClick = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected;
    setPage(currentPage);
  };

  function handleMatchClick(matchId){
    history.push(`/unsettled-matches/${matchId}`)
  }



  useEffect(() => {
    fetchMatches(page, selectedDate);
  }, [selectedDate]);

  return (
    <>
    {/* <ExportMatchBets show={showExportModal} close={()=> setShowExportModal(false)} matchId={selectedMatch}/> */}
    <Card>
      <Card.Header>
        <Card.Title>Matches</Card.Title>
      </Card.Header>
     
        <Card.Body>
          {isLoading && <h2 className="text-center">Loading...</h2>}
          <div className="d-flex align-items-center justify-content-between">
            {/* <input
              type="search"
              className="form-control mr-3"
              placeholder="Search match title"
              onChange={(e) => setSearchString(e.target.value)}
              value={searchString}
            /> */}
            <input
              type="date"
              className="form-control w-50"
              value={selectedDate}
              onChange={(e) => setSelectedDate(moment(e.target.value).format("YYYY-MM-DD"))}
            />
          </div>
          <div className="table-responsive">
            <div id="order_lis" className="dataTables_wrapper no-footer">
              <table
                id="example5"
                className="display mb-4 dataTablesCard dataTable no-footer w-100 "
                style={{ minWidth: 845 }}
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      className="sorting_asc"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Match Title
                    </th>
                    <th
                      className="sorting"
                      tabIndex={1}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Date
                    </th>
                    <th
                      className="sorting"
                      tabIndex={1}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Status
                    </th>
                    <th
                      className="sorting"
                      tabIndex={2}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredMatches &&
                    filteredMatches.map((item) => (
                      <tr role="row" key={item._id}>
                        <td className="pointer match-name">{item.title}</td>
                        <td>{moment(item.date_start).format("DD-MM-YYYY")}</td>
                        <td>
<Badge variant={`${item.status == "live" ? "success" : "warning"} light`}>{item.status.toUpperCase()}</Badge>
                        </td>

                        <td>
                          <span
                            className="btn btn-sm light btn-warning"
                            onClick={() => handleMatchClick(item._id)}
                          >
                            View
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </Card.Body>
      
      
    </Card>
    </>
  );
}

export default UnsetteledMatches;

