import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import toast from "react-hot-toast";
import ReactSelect from "react-select";
import { useDispatch } from "react-redux";
import { loadingToggleAction } from "../../../store/actions/AuthActions";
import { updateStatus } from "../../../services/asyncFunctions";

export default function ReviewRequest({ show, close, tipperId, approvalStatus, fetchUsers, tipperName }) {
  let errorsObj = { userName: "", email: "", phoneNumber: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [apiError, setApiError] = useState("");
  const [cost, setCost] = useState(0);
  const [description, setDescription] = useState("");
  const [isFree, setIsFree] = useState(false);
  const [matchId, setMatchId] = useState("");
  const [loadingMatches, setLoadingMatches] = useState(false);
  const [matchList, setMatchList] = useState([]);
  const [isAccepted, setIsAccepted] = useState(approvalStatus);
  const dispatch = useDispatch();
  const notifyTopRight = () => {
    toast.success("✅ Package created successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = () => {
    toast.error("❌ Tipper already has package for this match !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  async function respondToRequest(status) {
    dispatch(loadingToggleAction(true));
    try {
      let result = await updateStatus({ tipperId, status });
      console.log(result);
      notifyTopRight();
      close();
      fetchUsers();
      dispatch(loadingToggleAction(false));
    } catch (err) {
      dispatch(loadingToggleAction(false));
      console.log(err);
    }
  }

  useEffect(() => {
    console.log(isAccepted)
    setIsAccepted(approvalStatus);
  }, [approvalStatus]);

  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <Modal className="fade" show={show}>
        <Modal.Header>
          <Modal.Title>Review Request</Modal.Title>
          <Button variant="" className="close" onClick={() => close()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        {loadingMatches === false && (
          <Modal.Body>
            {apiError && (
              <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                {apiError}
              </div>
            )}
            <form onSubmit={() => {}}>
              <div className="form-group">
                {/* <div className="mb-2 font-weight-bold d-flex align-items-center justify-content-between"><span>Tipper Id:</span>  <span>{tipperId}</span></div>
                <div className="mb-2 font-weight-bold d-flex align-items-center justify-content-between"><span>Contact Number:</span>  <span>{tipperId}</span></div> */}
                <div className="mb-2 font-weight-bold d-flex align-items-center justify-content-between"><span>User Name:</span>  <span>{tipperName}</span></div>


           
          
              </div>

              <div className="mt-4 d-flex align-items-center">
                {(isAccepted === "pending" || isAccepted === "rejected")&& <button
                  type="button"
                  className="btn btn-primary mr-3 w-100"
                  onClick={() => respondToRequest("accepted")}
                >
                  Approve
                </button>}
                {(isAccepted === "pending" || isAccepted === "accepted")&& <button
                  type="button"
                  onClick={() => respondToRequest("rejected")}
                  className="btn btn-danger w-100"
                >
                  Reject
                </button>}
              </div>
            </form>
          </Modal.Body>
        )}

        {loadingMatches && (
          <Modal.Body>
            <h3 className="text-center">Loading...</h3>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}
