import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getNextBowlerObject } from "../../../constants/agentEvents";
import { getOverSessions } from "../../../services/asyncFunctions";
import { PLAYER_IMAGE_BASE_URL } from "../../../services/endpoints";
import SessionCard from "../sessionCard/SessionCard";
export default function FancyModal({ show, onHide, matchId, sendEvent, removeSlots, socketInstance }) {
  const [loader, setLoader] = useState(false);
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [allSessions, setAllSessions] = useState(null);

  function handleSelection(id) {
    if(selectedSessions.some(item => item.id == id)){
        let updatedSessions = selectedSessions.filter(item => item.id != id);
        setSelectedSessions(updatedSessions);
    }else{
        if(selectedSessions.length == 2){
            let temp = selectedSessions.splice(1, 1);
            setSelectedSessions([...temp])
        }else{
            setSelectedSessions([...selectedSessions, {
                id: id,
                type:{
                    isLambi: false,
                    iskhado: false,
                }
            }]);
        }
    }
  }

  function handleSessionTypeSelection(id, typeObject) {
    let indexToUpdate = selectedSessions.findIndex(item => item.id == id);
  
    if (indexToUpdate !== -1) {
      let updatedItem = {
        ...selectedSessions[indexToUpdate],
        type: typeObject
      };
  
      let updatedSessions = [
        ...selectedSessions.slice(0, indexToUpdate),
        updatedItem,
        ...selectedSessions.slice(indexToUpdate + 1)
      ];
  
      setSelectedSessions(updatedSessions);
    }
  }


  async function fetchOverSessions() {
    setSelectedSessions([]);
    setLoader(true);
    try {
      let response = await getOverSessions(matchId);
      console.log(response, "dfsdfsdf");
      setAllSessions(response?.data?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  }

  function handleSendEvent(){
    sendEvent(selectedSessions);
    setSelectedSessions([]);
    setAllSessions(null)
    onHide();

  }
  useEffect(() => {
    if (matchId && show) {
      fetchOverSessions();
    }
  }, [show]);

  useEffect(()=>{
       socketInstance.on('updatedLambiKhado', ()=>{
      fetchOverSessions();
       })
  },[socketInstance])

  return (
    <>
      <Modal
        size="md"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => onHide()}
      >
        <Modal.Header>
          <Modal.Title>Lambi / khado</Modal.Title>
          <Button variant="" className="btn btn-success" onClick={() => handleSendEvent()}>
            Update
          </Button>
        </Modal.Header>
        <Modal.Body scrollable={true}>
          {!loader && allSessions &&
            allSessions.map((item) => (
              <SessionCard
                selectedSessions={selectedSessions}
                key={item.marketId}
                handleSelection={handleSelection}
                handleSessionTypeSelection={handleSessionTypeSelection}
                id={item.question_id}
                title={item.title}
                yes={item?.odds?.yes ?? 0}
                no={item?.odds?.no ?? 0}
                isLambi={item?.isLambi ?? false}
                isKhado={item?.isKhado ?? false}
                matchId={matchId}
                refetch={fetchOverSessions}
                removeSlots={removeSlots}
              />
            ))}
          {
            loader && <h2 className="text-center">Loading...</h2>
          }  
          {
            !loader && (!allSessions || allSessions.length == 0) && <h3 className="text-center">No Fancy Available</h3>
          }  
        </Modal.Body>
      </Modal>
    </>
  );
}
