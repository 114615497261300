import axios from "axios";
import { store } from "../store/store";
import {
  ADD_GEMS_PACKAGE,
  ADS_BANNER,
  APP_TOP_BANNER,
  CHANNEL,
  CUSTOM_MESSAGE_FOR_MATCHID,
  DELETE_GEMS_PACKAGE,
  DELETE_PACKAGE,
  DELETE_REWARD,
  DELETE_SESSION_HISTORY,
  DELETE_TIP,
  EDIT_GEMS_PACKAGE,
  EXPORT_MATCH_BETS,
  FAKE,
  FANTASY_TIPS,
  FLAG_USER,
  GET_AGENT_LIVE_MATCHES,
  GET_APP_CONTROLS,
  GET_BANNER_ID,
  GET_BANNER_LIST,
  GET_BET_MATCHES,
  GET_CHANNELS,
  GET_CRICKET_COMPETITIONS,
  GET_CUSTOM_MESSAGE_FOR_MATCHID,
  GET_ENTITY_MATCHES,
  GET_GEMS_PACKAGES,
  GET_GLOBAL_COINS,
  GET_LEADERBOARD_LIVE_MATCHES,
  GET_LEAGUE_LIST,
  GET_MANAGEMENT_MATCHES,
  GET_MATCHES,
  GET_MATCHES_FOR_LEADERBOARD,
  GET_MATCH_LOAD,
  GET_MATCH_SQUADS,
  GET_ODDS_MATCHES,
  GET_OVER_SESSION,
  GET_PACKAGES,
  GET_PROFILE,
  GET_RECENT_LEADERBOARD_MATCHES,
  GET_REWARD_LIST,
  GET_SCORE_MATCHES,
  GET_SESSION_HISTORY,
  GET_SOCIAL_LINKS,
  GET_SOCKET_DATA,
  GET_TIPS,
  GET_USER_BET_HISTORY,
  GET_USER_BET_MATCHES,
  GET_USER_DETAILS,
  HOT_PICKS,
  LAMBI_KHADO,
  MATCH_REVIEW,
  POST_ODDS_CONNECTION,
  POST_PACKAGE,
  POST_PLAYING_ELEVEN,
  POST_SESSION_HISTORY,
  POST_TIP,
  PUT_APP_CONTROLS,
  PUT_GLOBAL_COINS,
  PUT_MATCH_LEADERBOARD_STATUS,
  PUT_SESSION_HISTORY,
  REMOVE_LAMBI_KHADO,
  RESET_MATCH_TOSS,
  RESET_SUBADMIN_PASSWORD,
  RISKY_PICKS,
  SEND_NOTIFICATIONS,
  SET_TEAM_ODDS,
  SORT_BANNER_ORDER,
  SUBADMIN,
  TOGGLE_BET_STATUS,
  TOGGLE_MATCH,
  UPDATE_REWARD_LIST,
  UPDATE_TIPPER_STATUS,
  UPDATE_TOTAL_VOTING,
  UPDATE_USER_ACCOUNT,
  UPDATE_USER_COINS,
} from "./endpoints";
import { deleteUserToken } from "./utils/generalUtils";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const config = {
//   headers: {
//     Authorization: `Bearer ${state.auth.auth.idToken}`,
//     "Content-type": "",
//   },
// };
// const formConfig = {
//   headers: {
//     Authorization: `Bearer ${state.auth.auth.idToken}`,
//     "Content-type": "application/json",
//   },
// };

const api = axios.create({
  baseURL: BASE_URL,
  "content-type": "application/x-www-form-urlencoded",
});
api.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.auth.idToken;
  config.headers.Authorization = "Bearer " + token;
  return config;
});
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      deleteUserToken();
      window.location.replace("/");
    }
    return Promise.reject(error);
  }
);

export const getPackages = async (matchId) => {
  let response = await api.get(
    GET_PACKAGES + `?matchId=${matchId}&page=0&limit=10`
  );
  return response;
};

export const getPackageDetails = async (packageId) => {
  console.log(packageId);
  let response = await api.get(GET_TIPS + `?packageId=${packageId}`);
  return response;
};

export const createPackage = async (payload) => {
  // let data = JSON.stringify(payload);
  let response = await api.post(POST_PACKAGE, payload);
  return response;
};

export const getMatches = async () => {
  let response = await api.get(GET_MATCHES);
  return response;
};

export const getAgentLiveMatches = async () => {
  let response = await api.get(
    GET_AGENT_LIVE_MATCHES);
  return response;
};

export const getMatchLoad = async(matchId)=>{
  let response = await api.get(GET_MATCH_LOAD + `?matchId=${matchId}`);
  return response;
}

export const createTip = async (payload) => {
  let response = await api.post(POST_TIP, payload);
  return response;
};

export const deleteTip = async (packageId, tipId) => {
  let response = await api.delete(
    DELETE_TIP + `?packageId=${packageId}&tipId=${tipId}`
  );
  return response;
};

export const deletePackage = async (packageId) => {
  let response = await api.delete(DELETE_PACKAGE + `?packageId=${packageId}`);
  return response;
};

export const sendNotification = async (payload) => {
  let response = await api.post(SEND_NOTIFICATIONS, payload);
  return response;
};

export const updateStatus = async (payload) => {
  let response = await api.put(UPDATE_TIPPER_STATUS, payload);
  return response;
};

export const getSocketData = async (matchId) => {
  let response = await api.get(GET_SOCKET_DATA + `?matchId=${matchId}`);
  return response;
};

export const getMatchSquads = async (matchId) => {
  let response = await api.get(GET_MATCH_SQUADS + `?matchId=${matchId}`);
  return response;
};

export const postPlayingEleven = async (payload) => {
  let response = await api.post(POST_PLAYING_ELEVEN, payload);
  return response;
};

export const getOverSessions = async (matchId) => {
  let response = await api.get(GET_OVER_SESSION + `?matchId=${matchId}`);
  return response;
};
export const getSocialLinks = async () => {
  return await api.get(GET_SOCIAL_LINKS);
};

export const putSocialLinks = async (payload) => {
  return await api.put(GET_SOCIAL_LINKS, payload);
};

export const getManagementMatches = async () => {
  let response = await api.get(GET_MANAGEMENT_MATCHES);
  return response;
};
export const updateMatchStateOnServer = async (payload, endpoint) => {
  let response = await api.post(endpoint, payload);
  return response;
};
export const updateUserAccount = async (payload) => {
  let response = await api.post(UPDATE_USER_ACCOUNT, payload);
  return response;
};
export const getSessionOdds = async (matchId) => {
  let response = await api.get(GET_SESSION_HISTORY + `?matchId=${matchId}`);
  return response;
};
export const getDefaultCoins = async () => {
  let response = await api.get(GET_GLOBAL_COINS);
  return response;
};
export const updateDefaultCoins = async (payload) => {
  console.log(payload);
  let { dailyBonus, joiningCoins, joiningDiamond, dailyBonusDiamond } = payload;
  let response = await api.put(PUT_GLOBAL_COINS, {
    dailyBonus,
    joiningCoins,
    joiningDiamond,
    dailyBonusDiamond,
  });
  return response;
};

export const flagUserById = async (userId, userType, isTeamMember) => {
  let response = await api.put(FLAG_USER, { userId, userType, isTeamMember });
  return response;
};

export const getUserDetailsById = async (userId, userType) => {
  let response = await api.get(
    GET_USER_DETAILS + `?userId=${userId}&userType=${userType}`
  );
  return response;
};
export const getUserBetHistoryById = async (
  userId,
  userType,
  matchId,
  page
) => {
  let response = await api.get(
    GET_USER_BET_HISTORY +
      `?userId=${userId}&userType=${userType}&matchId=${matchId}&page=${page}&limit=10`
  );
  return response;
};
export const getUserBetMatchesByUserId = async (userId, userType, page) => {
  let response = await api.get(
    GET_USER_BET_MATCHES +
      `?userId=${userId}&userType=${userType}&page=${page}&limit=5`
  );
  return response;
};

export const updateUserCoins = async (userId, userType, coins) => {
  let response = await api.put(UPDATE_USER_COINS, { userId, userType, coins });
  return response;
};

export const toggleSessionOdds = async (
  matchId,
  sessionId,
  isPassed,
  session,
  inning,
  teamId,
  min,
  max,
  open,
  odds,
  passedAt
) => {
  // console.log({matchId, marketId, isPassed, text, inning, teamId, min, max, open, adminOdds});
  let response = await api.put(PUT_SESSION_HISTORY, {
    matchId,
    sessionId,
    isPassed,
    session,
    inning,
    teamId,
    min,
    max,
    open,
    odds,
    passedAt,
  });
  return response;
};

export const addSession = async (
  matchId,
  isPassed,
  inning,
  session,
  min,
  max,
  open,
  odds,
  teamId,
  passedAt
) => {
  let response = await api.post(POST_SESSION_HISTORY, {
    matchId,
    isPassed,
    inning,
    session,
    min,
    max,
    open,
    odds,
    teamId,
    passedAt,
  });
  return response;
};

export const deleteSession = async (matchId, sessionId) => {
  let response = await api.delete(
    DELETE_SESSION_HISTORY + `?matchId=${matchId}&sessionId=${sessionId}`
  );
  return response;
};

export const getLeaderBoardLiveMatches = async () => {
  let response = await api.get(GET_LEADERBOARD_LIVE_MATCHES);
  return response;
};

export const getLeaderBoardRecentMatches = async (
  page = 0,
  date = "",
  searchStr = ""
) => {
  let response = await api.get(
    GET_RECENT_LEADERBOARD_MATCHES +
      `?page=${page}&limit=10&selectDate=${date}&search=${searchStr}`
  );
  return response;
};

export const putLeaderBoardStatus = async (matchId, leaderboardStatus) => {
  let response = await api.put(PUT_MATCH_LEADERBOARD_STATUS, {
    matchId,
    leaderboardStatus,
  });
  return response;
};

export const removeLambiKhado = async (matchId, removingSession) => {
  let response = await api.put(REMOVE_LAMBI_KHADO, {
    matchId,
    removingSession,
  });
  return response;
};

export const rewardsPost = async (payload) => {
  let response = await api.post(UPDATE_REWARD_LIST, payload);
  return response;
};

export const rewardsGet = async () => {
  let response = await api.get(GET_REWARD_LIST);
  return response;
};

export const rewardsDelete = async (rewardId) => {
  let response = await api.delete(DELETE_REWARD + `?rewardId=${rewardId}`);
  return response;
};

export const getScoreMatches = async () => {
  let response = await api.get(GET_SCORE_MATCHES);
  return response;
};

export const getOddsMatches = async () => {
  let response = await api.get(GET_ODDS_MATCHES);
  return response;
};

export const getMatchControls = async () => {
  let response = await api.get(GET_APP_CONTROLS);
  return response;
};

export const putMatchControls = async (payload) => {
  let response = await api.put(PUT_APP_CONTROLS, payload);
  return response;
};

export const postConnectMatchToOdds = async (
  matchId,
  eventId,
  oddsMatchName
) => {
  let response = await api.post(POST_ODDS_CONNECTION, {
    matchId: matchId + "",
    eventId: eventId + "",
    oddsMatchName,
  });
  return response;
};

export const getGemsPackages = async () => {
  let response = await api.get(GET_GEMS_PACKAGES);
  return response;
};

export const postGemsPackage = async (payload) => {
  let response = await api.post(ADD_GEMS_PACKAGE, payload);
  return response;
};

export const editGemsPackage = async (payload) => {
  let response = await api.put(EDIT_GEMS_PACKAGE, payload);
  return response;
};

export const deleteGemsPackages = async (packageId) => {
  let response = await api.delete(
    DELETE_GEMS_PACKAGE + `?packageId=${packageId}`
  );
  return response;
};

export const matchTeamToOdds = async (matchId, selectedTeams) => {
  let response = await api.post(SET_TEAM_ODDS, { matchId, selectedTeams });
  return response;
};

export const getFootballLeagues = async (search) => {
  let response = await api.get(
    GET_LEAGUE_LIST + `?search=${search}&limit=${2000}&page=${0}`
  );
  return response;
};

export const addNewBanner = async (payload) => {
  let response = await api.post(ADS_BANNER, payload);
  return response;
};

export const editBanner = async (payload) => {
  let response = await api.put(ADS_BANNER, payload);
  return response;
};
export const deleteBanner = async (bannerId) => {
  let response = await api.delete(ADS_BANNER + `?bannerId=${bannerId}`);
  return response;
};

export const getBannerList = async () => {
  let response = await api.get(GET_BANNER_LIST);
  return response;
};

export const getBannerById = async (id) => {
  let response = await api.get(GET_BANNER_ID + `?bannerId=${id}`);
  return response;
};

export const updateBannerOrder = async (payload) => {
  let response = await api.put(SORT_BANNER_ORDER, payload);
  return response;
};

export const getLeaderBoardMatches = async (type, date = "", title = "") => {
  let response = await api.get(
    GET_MATCHES_FOR_LEADERBOARD + `?type=${type}&date=${date}&title=${title}`
  );
  return response;
};

export const getAppBanner = async () => {
  let response = await api.get(APP_TOP_BANNER);
  return response;
};

export const updateTopBanner = async (payload) => {
  let response = await api.post(APP_TOP_BANNER, payload);
  return response;
};
export const deleteBannerWithId = async (id) => {
  let response = await api.delete(APP_TOP_BANNER + `?bannerId=${id}` );
  return response
}

export const updateMatchVotingTotal = async (payload) => {
  let response = await api.put(UPDATE_TOTAL_VOTING, payload);
  return response;
};

export const getCompetitions = async (page) => {
  let response = await api.get(GET_CRICKET_COMPETITIONS + `?page=${page}&limit=${1000}`);
  return response;
};

export const editCompetition = async (payload) => {
  let response = await api.put(GET_CRICKET_COMPETITIONS, payload);
  return response;
};

export const getMatcheBetsForAllUsers = async (payload, page, limit) => {
  let response = await api.get(GET_BET_MATCHES + `?page=${page}&limit=${limit}&matchId=${payload}`);
  return response;
}

export const getAllBetsForExport = async (matchId) => {
  let response = await api.get(EXPORT_MATCH_BETS + `?matchId=${matchId}`);
  return response;
}

export const resetMatchToss = async (matchId) => {
  let response = await api.put(RESET_MATCH_TOSS + `?matchId=${matchId}`);
  return response;
}

export const toggleMatchApiAndSocket = async (matchId) => {
  let response = await api.put("admin/match/switchToApi" + `?matchId=${matchId}`);
  return response;
}

export const toggleBetStatusForMatch = async (payload) => {
  let response = await api.put(TOGGLE_BET_STATUS , payload);
  return response;
}


export const profile = async () => {
  let response = await api.get(GET_PROFILE);
  return response;
}

export const addSubAdmin = async (payload) => {
  let response = await api.post(SUBADMIN, payload);
  return response;
}

export const getSubAdmins = async () => {
  let response = await api.get(SUBADMIN);
  return response;
}
export const deleteSubAdmin = async (subAdminId) => {
  let response = await api.delete(SUBADMIN + `?subAdminId=${subAdminId}`);
  return response;
}
export const updateSubAdmin = async (payload) => {
  let response = await api.put(SUBADMIN, payload);
  return response;
}
export const updateSubAdminPassword = async (payload) => {
  let response = await api.put(RESET_SUBADMIN_PASSWORD, payload);
  return response;
}

export const getCustomTextForMatch = async (matchId) =>{
  let response = await api.get(CUSTOM_MESSAGE_FOR_MATCHID + `?matchId=${matchId}`)
  return response
}

export const putCustomMessage = async (payload) => {
  let response = await api.put(CUSTOM_MESSAGE_FOR_MATCHID, payload);
  return response;
}


export const getMatchReview = async (matchId) =>{
  let response = await api.get(MATCH_REVIEW + `?matchId=${matchId}`)
  return response
}

export const putMatchReview = async (payload) => {
  let response = await api.put(MATCH_REVIEW, payload);
  return response;
}

export const putLambiKhado = async (payload) => {
  let response = await api.put(LAMBI_KHADO, payload);
  return response;
}

export const getMatchSquad= async (matchId) => {
  let response = await api.get(`admin/fantasy/players?matchId=${matchId}`);
  return response
}

export const getFantasyData = async (matchId)=>{
  let response = await api.get(`admin/fantasy/data?matchId=${matchId}`);
  return response
}

export const postFantasyTips = async (matchId, payload)=>{
  let response = await api.post(FANTASY_TIPS, {matchId, ...payload});
  return response
}

export const postFantasyHotPicks = async (matchId, payload)=>{
  let response = await api.post(HOT_PICKS, {matchId, hotPicks: [...payload]})
  return response
}
export const postFantasyRiskyPicks = async (matchId, payload)=>{
  let response = await api.post(RISKY_PICKS, {matchId, riskyPicks: [...payload]})
  return response
}

export const getTopPicksForFantasyMatch = async (matchId) =>{
  let response = await api.get(`user/fantasy/toppicks?matchId=${matchId}`);
  return response
}

export const postTopPicks = async (payload) =>{
  let response = await api.post('admin/fantasy/toppicks', payload)
  return response
}
export const deleteFancyTip = async (matchId, tipId) => {
  let response = await api.delete(FANTASY_TIPS + `?matchId=${matchId}&tipId=${tipId}`);
  return response
}

export const deleteHotpick = async (matchId, pickId) => {
  let response = await api.delete(HOT_PICKS + `?matchId=${matchId}&hotPickId=${pickId}`);
  return response
}
export const deleteRiskypick = async (matchId, pickId) => {
  let response = await api.delete(RISKY_PICKS + `?matchId=${matchId}&riskyPickId=${pickId}`);
  return response
}

export const getChannels = async () => {
  let response = await api.get(GET_CHANNELS);
  return response
}

export const getChannelById = async (channelId) => {
  let response = await api.get(CHANNEL + `?channelId=${channelId}`);
  return response
}

export const deleteChannel = async (channelId) => {
  let response = await api.delete(CHANNEL + `?channelId=${channelId}`);
  return response
}

export const putChannel = async (payload) => {
  let response = await api.put(CHANNEL, payload);
  return response
}

export const putFakeViews = async (payload) => {
  let response = await api.put(FAKE, payload);
  return response
}


export const getUnselltedMatches = async (page=0, date="") => {  
  let response = await api.get("admin/match/recentLiveMatches" + `?page=${page}&limit=${100}&date=${date ? date : ""}`);
  return response
}

export const getUnselltedMatchSessions = async (matchId) => {
  let response = await api.get("admin/match/unsettledBets" + `?matchId=${matchId}`);
  return response
}