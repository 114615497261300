import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { changePasswoard, createUserTipper } from "../../services/AuthService";
import { ToastContainer } from "react-toastify";
import toast from "react-hot-toast";
import { country } from "./Countery";
import AWS from "aws-sdk";
import addPhoto from "../pages/test";
import ReactSelect from "react-select";
import { useDispatch } from "react-redux";
import { loadingToggleAction } from "../../store/actions/AuthActions";
import { createPackage, getMatches } from "../../services/asyncFunctions";
import { getUserToken } from "../../services/utils/generalUtils";

export default function CreatePackage({ show, close, tipperId }) {
  let errorsObj = { userName: "", email: "", phoneNumber: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [apiError, setApiError] = useState("");
  const [cost, setCost] = useState(0);
  const [description, setDescription] = useState("");
  const [isFree, setIsFree] = useState(false);
  const [matchId, setMatchId] = useState("");
  const [loadingMatches, setLoadingMatches] = useState(false);
  const [matchList, setMatchList] = useState([]);
  const dispatch = useDispatch();
  // console.log(imgLocation, "imgjjkjkjk");
  const notifyTopRight = () => {
  
  };
  const notifyError = () => {
    toast.error("❌ Tipper already has package for this match !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  async function onSubmitPackage(e) {
    e.preventDefault();
    dispatch(loadingToggleAction(true));
    let payload = {
      tipperId: tipperId,
      dimonds: cost,
      description: description,
      matchId: matchId,
      isFree: isFree == "free" ? true : false,
    };
    try {
      let result = await createPackage(payload);
      console.log(result);
      close();
      dispatch(loadingToggleAction(false));
    } catch (error) {
      console.error(error.message);
      notifyError();
      close();
      dispatch(loadingToggleAction(false));
    }
  }

  // function onCreatUser(e) {
  //   e.preventDefault();
  //   let error = false;
  //   const errorObj = { ...errorsObj };
  //   if (userName === "") {
  //     errorObj.userName = "User Name is Required";
  //     error = true;
  //   }
  //   if (email === "") {
  //     errorObj.email = "Email is Required";
  //     error = true;
  //   }
  //   if (phoneNumber === "") {
  //     errorObj.phoneNumber = "Phone Number is Required";
  //     error = true;
  //   }

  //   setErrors(errorObj);
  //   if (error) {
  //     return;
  //   }

  //   addPhoto(profileImage, albumName)
  //     .then((response) => {
  //       createUserTipper(
  //         userName,
  //         email,
  //         phoneNumber,
  //         userType,
  //         countryCode,
  //         response.imageName
  //       )
  //         .then((response) => {
  //           console.log(response);

  //           setUserName("");
  //           setEmail("");
  //           setPhoneNumber("");
  //           setProfileImage("");
  //           close();
  //           // table();

  //           notifyTopRight();
  //         })
  //         .catch((error) => {
  //           console.log(error.response, "error");
  //           setApiError(error.response.message);
  //           notifyError();
  //         });
  //     })
  //     .catch((error) => {
  //       console.log(error, "s3 error");
  //     });
  // }

  async function fetchMatches() {
    console.log("first");
    setLoadingMatches(true);
    let result = await getMatches();
    console.log(result);
    let matchList = result?.data?.data.map((item) => {
      return {
        label: item.title,
        value: item.matchId,
      };
    });
    setMatchList(matchList);
    setLoadingMatches(false);
  }

  useEffect(() => {
    if (getUserToken()) {
      fetchMatches();
    }
  }, []);
  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <Modal className="fade" show={show}>
        <Modal.Header>
          <Modal.Title>Create Package</Modal.Title>
          <Button variant="" className="close" onClick={() => close()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        {loadingMatches === false && (
          <Modal.Body>
            {apiError && (
              <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                {apiError}
              </div>
            )}
            <form onSubmit={onSubmitPackage}>
              <div className="form-group">
                <ReactSelect
                  options={matchList}
                  className="w-100 mb-3"
                  required
                  onChange={(value) => setMatchId(value.value)}
                />
                <div className="d-flex align-items-center gap-3 mb-3">
                  <div className="d-flex flex-column align-items-left mr-3">
                    <label htmlFor="free">Free</label>
                    <input
                      type="radio"
                      name="isPaid"
                      id="free"
                      value="free"
                      className=""
                      required
                      checked={isFree === "free"}
                      onChange={(e) => setIsFree(e.currentTarget.value)}
                    />
                  </div>
                  <div className="d-flex flex-column align-items-left ">
                    <label htmlFor="paid">Paid</label>
                    <input
                      type="radio"
                      name="isPaid"
                      id="paid"
                      value="paid"
                      className=""
                      required
                      checked={isFree === "paid"}
                      onChange={(e) => setIsFree(e.currentTarget.value)}
                    />
                  </div>
                </div>
                {isFree == "paid" && (
                  <input
                    type="number"
                    className="form-control mb-3"
                    placeholder="Price"
                    required
                    onChange={(e) => setCost(e.target.value)}
                  />
                )}
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  className="form-control"
                  placeholder="Description"
                  required
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>

              <div className="mt-4">
                <button type="submit" className="btn btn-primary mr-3 ">
                  Create
                </button>
                <button
                  type="button"
                  onClick={() => close()}
                  className="btn btn-danger "
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        )}

        {loadingMatches && (
          <Modal.Body>
            <h3 className="text-center">Loading...</h3>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}
