import React from 'react'

function TableLoaderSubAdmin() {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Reset Password</th>
            <th>Edit Permission</th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3].map((index) => (
            <tr key={index}>
              <td>
                <div className="shimmer"></div>
              </td>
              <td>
                <div className="shimmer"></div>
              </td>
              <td>
                <div className="shimmer-button"></div>
              </td>
              <td>
                <div className="shimmer-button"></div>
              </td>
              <td>
                <div className="shimmer-button"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TableLoaderSubAdmin