import React, { useRef, useState } from "react";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import ReactSelect from "react-select";
import { toast, ToastContainer } from "react-toastify";
import {
  addNewBanner,
  deleteBanner,
  getBannerList,
  putMatchControls,
  sendNotification,
  updateBannerOrder,
} from "../../services/asyncFunctions";
import { loadingToggleAction } from "../../store/actions/AuthActions";
import addPhoto, {
  AWS_BANNER_PHOTO_BASE_URL,
  AWS_PHOTO_BASE_URL,
} from "./test";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AddNewBanner from "../components/modals/AddNewBanner";
import { useEffect } from "react";
import EditBanner from "../components/modals/EditBanner";
import TopBanner from "../components/modals/TopBannerModal";

const options = [
  { value: "googleAds", label: "Google Ads" },
  { value: "customAds", label: "Custom Ads" },
  { value: "off", label: "Off" },
];

function AdsManagement() {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [selectedOption, setSelectedOption] = useState("select");
  const [imageFile, setImageFile] = useState(null);
  const [showAddBannerModal, setShowAddBannerModal] = useState(false);
  const [showEditBannerModal, setShowEditBannerModal] = useState(false);
  const [bannerList, setBannerList] = useState(null);
  const [selectedBannerId, setSelectedBannerId] = useState(null);
  const [showTopBannerModal, setShowTopBannerModal] = useState(false);

  const onDragEnd = (result) => {
    if (!result.destination) return; // If dropped outside the list

    const newItems = Array.from(bannerList);
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);

    setBannerList(newItems);
  };

  async function uploadBannerImage() {
    if (!imageFile) {
      notifyError("Please select an image file");
      return;
    }
    dispatch(loadingToggleAction(true));
    let imageResponse = null;
    if (imageFile) {
      imageResponse = await addPhoto(imageFile, "banner");
      updateBanner(imageResponse.imageName);
      console.log(imageResponse);
      setShowAddBannerModal(false);
    } else {
      updateBanner("");
    }
  }

  async function updateBanner(image) {
    let payload = {
      title,
      url: redirectUrl,
      image,
    };
    try {
      let result = await addNewBanner(payload);
      console.log(result);
      notifyTopRight("Success!");
      dispatch(loadingToggleAction(false));
      setImageFile(null);
      getBanners();
    } catch (e) {
      notifyError("Couldn't Complete the action!");
      dispatch(loadingToggleAction(false));
    }
  }

  async function getBanners() {
    try {
      let result = await getBannerList();
      console.log(result);
      setBannerList(result.data.data);
    } catch (e) {
      notifyError("Couldn't get banners!");
    }
  }

  async function deleteBannerById(id) {
    try {
      let result = await deleteBanner(id);
      console.log(result);
      notifyTopRight("Successfully deleted!");
      getBanners();
    } catch (e) {
      notifyError("Couldn't Perform Action");
    }
  }

  async function updateBannerOrderIds() {
    let sortedOrderIds = bannerList.map((item) => item._id);
    try {
      let result = await updateBannerOrder(sortedOrderIds);
      notifyTopRight("Order updated!");
    } catch (e) {
      notifyError("Couldn't Perform Action");
    }
  }

  function handleOptionChange(value) {
    setSelectedOption(value);
  }

  function saveToggleChanges(){
    if (selectedOption == "off") {
      toggleAddStates({ googleAdds: false, customBanner: false });
    } else if (selectedOption == "googleAds") {
      toggleAddStates({ googleAdds: true, customBanner: false });
    } else {
      toggleAddStates({ customBanner: true,  googleAdds: false });
    }
  }

  async function toggleAddStates(payload) {
    try {
      let res = await putMatchControls(payload);
      notifyTopRight("State updated!")
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  }

  function handleImageFileUpdate(e) {
    setImageFile(e.target.files[0]);
  }

  function handleInputChange(e) {
    if (e.target.name === "name") {
      setTitle(e.target.value);
    } else {
      setRedirectUrl(e.target.value);
    }
  }

  function openEditBannerModal(e){
    setSelectedBannerId(e)
    setShowEditBannerModal(true);
  }

  const notifyTopRight = () => {
    toast.success("✅ Notification Sent!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    getBanners();
  }, []);
  return (
    <>
      <AddNewBanner
        show={showAddBannerModal}
        close={() => setShowAddBannerModal(false)}
        handleFileChange={handleImageFileUpdate}
        handleInputChange={handleInputChange}
        submitBanner={uploadBannerImage}
      />
      <EditBanner 
      show={showEditBannerModal}
      close={() => setShowEditBannerModal(false)}
      bannerId={selectedBannerId}
      toastFailure={notifyError}
      toastSuccess={notifyTopRight}
      refetch={getBanners}
      />
      <TopBanner 
      show={showTopBannerModal}
      close={()=> setShowTopBannerModal(false)}
      />
      <Card>
        <Card.Header>
          <Card.Title>Manage Ads</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="d-flex flex-column align-items-start pb-1">
            <div className="w-75 mb-2">Select Ad Type</div>
            <div className="d-flex align-items-center justify-content-between w-100 mb-5">
            <ReactSelect
              options={options}
              className="w-50 mr-3"
              defaultValue={selectedOption}
              onChange={(value) => handleOptionChange(value.value)}
            />
            <button className="btn btn-success" onClick={saveToggleChanges}>
              Save state
            </button>
            </div>
          </div>
          {selectedOption == "customAds" && (
            <div className="banner-ads-container">
              <div className="d-flex align-items-center justify-content-end mb-2">
              <button
                  className="btn btn-secondary px-3 py-2 mr-2"
                  onClick={() => setShowTopBannerModal(true)}
                >
                  Top Banner
                </button>
                <button
                  className="btn btn-warning px-3 py-2 mr-2"
                  onClick={() => setShowAddBannerModal(true)}
                >
                  Add Item +
                </button>
                <button
                  className="btn btn-secondary px-3 py-2"
                  onClick={updateBannerOrderIds}
                >
                  Update Order
                </button>
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        display: "flex",
                        flexDirection: "column", // Set to column
                        gap: "1rem", // Set the gap between cards
                        height: "100%", // Set the height to 100% to take full height
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <th className="col-4">Ad Name</th>
                        <th className="col-5">Image</th>
                        <th className="col-2">Link</th>
                        <th className="col-1">Delete</th>
                      </div>
                      {bannerList &&
                        bannerList.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item._id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  width: "100%",
                                  height: "100px",
                                  padding: "1rem",
                                  marginBottom: "8px", // Adjust spacing between cards
                                  background: snapshot.isDragging
                                    ? "#cfcfcf"
                                    : "#f0f0f0", // Change background color while dragging
                                  borderRadius: "4px", // Adjust border radius
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Add box shadow
                                  ...provided.draggableProps.style,
                                }}
                                className="d-flex align-items-center justify-content-between"
                              >
                                <td className="col-4">{item.title}</td>
                                <td className="col-5">
                                  <img
                                    src={AWS_BANNER_PHOTO_BASE_URL + item.image}
                                    alt=""
                                    width={100}
                                  />
                                </td>
                                <td className="col-2">
                                  <a
                                    href={item.url}
                                    className="btn btn-dark px-2 py-1"
                                    target="_blank"
                                  >
                                    Visit
                                  </a>
                                </td>
                                <div className="d-flex flex-column col-1">
                                <td
                                  role="button"
                                  className="btn btn-danger px-1 py-1 "
                                  onClick={() => deleteBannerById(item._id)}
                                >
                                  Delete
                                </td>
                                <td
                                  role="button"
                                  className="btn btn-dark px-1 py-1 mt-2"
                                  onClick={() => openEditBannerModal(item._id)}
                                >
                                  Edit
                                </td>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default AdsManagement;
