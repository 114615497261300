import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap";


function UpdateInningOvers({overs, show, onHide, updateOverHandler}) {
    const [overInput, setOverInput] = useState(overs);



    function submitFunc(){
        updateOverHandler(overInput);
        onHide();
    }

  return (
    <Modal
        size="sm"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => onHide()}
      >
        <Modal.Header>
          <Modal.Title>Inning overs </Modal.Title>
          <Button variant="" className="close" onClick={() => onHide()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center flex-wrap form-group">
            <input
              type="number"
              className="custom-text-input"
              placeholder="overs"
              value={overInput}
              onChange={(e) => {
                setOverInput(e.target.value);
              }}
            />
            <button
              className="btn btn-warning m-auto"
              type="button"
              onClick={submitFunc}
            >
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>
  )
}

export default UpdateInningOvers
