import React from 'react'
import { Card } from 'react-bootstrap'

function FantasyTipCard({text, backgroundColor, removeTip, tipId}) {
    const highlightText = (text) => {
        const parts = text.split(/(\#[^\s#]+)/g);
        return parts.map((part, index) => {
          if (part.startsWith('#')) {
            return (
              <span key={index} style={{ color: 'blue', fontWeight: 'bold' }}>
                {part}
              </span>
            );
          }
          return part;
        });
      };
  return (
    <div className='fantasy-tip-card rounded shadow-md justify-content-between' style={{background: backgroundColor}}>
       <span>{text}</span> 
       <span className='py-1 px-2 bg-danger text-light rounded pointer' onClick={()=> removeTip(tipId)}>X</span>
    </div>
  )
}

export default FantasyTipCard