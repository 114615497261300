import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";

export default function TieMatchModal({ show, onHide, actionFunction, tossModalFunction }) {


  function handleTossSelect(event, data){
    tossModalFunction(true);
    onHide();
  }
  return (
    <>

      <Modal
        size="lg"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => onHide()}
      >
        <Modal.Header>
          <Modal.Title>Match tie events</Modal.Title>
          <Button variant="" className="close" onClick={() => onHide()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center flex-wrap form-group">
            <div className="modal-event btn btn-secondary" 
                >
                 Declare Draw
                </div>
             <div className="modal-event btn btn-primary" 
             onClick={handleTossSelect}
                >
                 Super Over Toss
            </div>
            <div className="modal-event btn btn-warning" 
                >
                 Super over Draw
            </div>
            </div>
          </Modal.Body>
    
      </Modal>
    </>
  );
}
