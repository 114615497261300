import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import {
  getScoreMatches,
  matchTeamToOdds,
  postConnectMatchToOdds,
} from "../../services/asyncFunctions";
import MatchTeamOdds from "../components/modals/MatchTeamOdds";
import SelectMatchOdds from "../components/modals/SelectMatchOdds";

function OddsToMatch() {
  const [appMatches, setAppMatches] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMatchOdds, setSelectedMatchOdds] = useState(null);
  const [selectedTeamList, setSelectedTeamList] = useState(null);

  const fetchAppMatches = async () => {
    try {
      const response = await getScoreMatches();
      console.log(response);
      setAppMatches(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error appropriately, maybe set some error state or show a notification
    }
  };

  async function connectMatchToOdds(eventId, oddsMatchName) {
    try {
      const response = await postConnectMatchToOdds(
        selectedMatch,
        eventId,
        oddsMatchName
      );
      notifyTopRight();
      fetchAppMatches();
    } catch (error) {
      notifyError();
      console.error("Error fetching data:", error);
      // Handle the error appropriately, maybe set some error state or show a notification
    }
  }

  function getKeyValueList(list) {
    let finalList = list.map((item) => {
      return { value: item.selectionId, label: item.runner, checkValue: item.apiTeamName};
    });
    return finalList;
  }

  function notifyTopRight() {
    toast.success("✅ successfull!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  function notifyError() {
    toast.error("❌ Error !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  function handleMatchSelect(matchId) {
    setSelectedMatch(matchId);
    setShowModal(true);
  }

  function handleTeamModalOpen(oddsData, teams, matchId) {
    setSelectedMatchOdds(getKeyValueList(oddsData));
    setSelectedMatch(matchId);
    setSelectedTeamList(teams);
    setShowTeamModal(true);
  }
  const formatDate = (isoDate) => {
    const parts = isoDate.split("-");
    return `${parts[1]}/${parts[2]}/${parts[0]}`;
  };

  const filterMatches = () => {
    const formattedDate = formatDate(selectedDate);
    const results = appMatches.filter(
      (match) => match.date_start === formattedDate
    );
    setFilteredMatches(results);
  };

  async function submitTeamOdds(payload){
      try{
          let res = await matchTeamToOdds(selectedMatch, payload);
          if(res.status == 200){
            notifyTopRight("Added Successfully!")
            fetchAppMatches();
          }
      }catch(err){
          notifyError("Ops Something went wrong!")
      }
  }
  useEffect(() => {
    fetchAppMatches();
  }, []);
  useEffect(() => {
    if (appMatches) {
      const results = appMatches.filter((match) =>
        match.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredMatches(results);
    }
  }, [searchTerm, appMatches]);

  useEffect(() => {
    if (selectedDate) {
      filterMatches();
    }
  }, [selectedDate]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <SelectMatchOdds
        show={showModal}
        onHide={() => setShowModal(false)}
        submitFunc={connectMatchToOdds}
      />
      <MatchTeamOdds
        show={showTeamModal}
        onHide={() => setShowTeamModal(false)}
        oddsData={selectedMatchOdds}
        teams={selectedTeamList}
        submitFunc={submitTeamOdds}
      />
      <Card className="p-2">
        <h1>Match To Odds</h1>
        <section className="mt-3">
          <h3>Matches shown in app</h3>
          <div className="p-3">
            <input
              type="text"
              placeholder="Search by title"
              value={searchTerm}
              className="mb-2 px-2 py-3 rounded mr-2"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <input
              type="date"
              name=""
              id=""
              className="mb-2 px-2 py-3 rounded"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
            <ul className="">
              {filteredMatches.map((match) => (
                <li
                  key={match._id}
                  className={`w-100 shadow px-2 py-3 text-white rounded d-flex align-items-center ${
                    match?.oddsMatchName && "bg-dark"
                  } justify-content-between mb-3`}
                  style={{ backgroundColor: "#5F9DF7" }}
                >
                  <div className="d-flex flex-column align-items-start">
                    <span>
                      {match.title} - {match.status_str}
                    </span>
                    <span className="font-weight-bold">
                      ({match.date_start})
                    </span>
                  </div>
                  <div className="d-flex align-items-center ">
                    {match?.matchOddsData && match.matchOddsData.length > 0 && (
                      <button
                        className="btn btn-success mr-2"
                        onClick={() =>
                          handleTeamModalOpen(match.matchOddsData, match.teams, match.matchId)
                        }
                      >
                        match teams
                      </button>
                    )}
                    <button
                      className="btn btn-warning"
                      onClick={() => handleMatchSelect(match.matchId)}
                    >
                      Add Odds
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>
      </Card>
    </>
  );
}

export default OddsToMatch;
