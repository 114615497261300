import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";

export default function SuperOverTossModal({ show, onHide, actionFunction, teamAData, teamBData }) {
    const [tossData, setTossData] = useState({
        //initializing toss object
        winningTeamId: null,
        battingTeamId: null,
        choice: "bat",
        screenText: null,
      });
      const [tossError, setTossError] = useState(false);

  function handleItemSelect(event, data){
    actionFunction(event, data);
    show();
  }


  function sendTossEvent() {
    // let payload = {
    //   ...tossData,
    // };
    // if (!payload.winningTeamId || !payload.battingTeamId) {
    //   setTossError("Select the winning team!");
    //   return;
    // } else {
    //   setTossError("");
    //   console.log(payload, "toss data");
    //   socket.emit("toss", payload);
    // }
  }
  return (
    <>

      <Modal
        size="lg"
        className="fade vertically-centered"
        show={show}
        centered
        onHide={() => onHide()}
      >
        <Modal.Header>
          <Modal.Title>Super Over toss</Modal.Title>
          <Button variant="" className="close" onClick={() => onHide()}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
          <Modal.Body>
          <div className="toss-container">
          <h1 className="text-center ">
            {teamAData ? teamAData?.short_name : "Team A"} Vs{" "}
            {teamBData ? teamBData?.short_name : "Team B"}
          </h1>
          <h3 className="text-center">Who won the toss?</h3>
          <div className="d-flex align-items-center justify-content-center">
            <div
              className={`toss-team-block shadow ${
                tossData.winningTeamId == teamAData?.teamId && "selected-team"
              }`}
              onClick={() =>
                setTossData({ ...tossData, winningTeamId: teamAData?.teamId })
              }
            >
              <img
                src={teamAData ? teamAData?.logo_url : ""}
                alt=""
                height={40}
                width={40}
              />
              <span>{teamAData ? teamAData?.short_name : "Team A"}</span>
            </div>
            <div
              className={`toss-team-block shadow ${
                tossData.winningTeamId == teamBData?.teamId && "selected-team"
              }`}
              onClick={() =>
                setTossData({ ...tossData, winningTeamId: teamBData?.teamId })
              }
            >
              <img
                src={teamBData ? teamBData?.logo_url : ""}
                alt=""
                height={40}
                width={40}
              />
              <span>{teamAData ? teamBData?.short_name : "Team B"}</span>
            </div>
          </div>

          <h4 className="text-center mt-4">Select One</h4>
          <div className="d-flex align-items-center justify-content-center">
            <div
              className={`toss-team-block shadow-sm  ${
                tossData.choice == "bat" && "selected-team"
              }`}
              onClick={() => setTossData({ ...tossData, choice: "bat" })}
            >
              {"Bat"}
            </div>
            <div
              className={`toss-team-block shadow-sm   ${
                tossData.choice == "ball" && "selected-team"
              }`}
              onClick={() => setTossData({ ...tossData, choice: "ball" })}
            >
              {"Ball"}
            </div>
          </div>
          <div className="w-100 d-flex flex-column align-items-center justify-content-center mt-4">
            <button className=" btn btn-primary m-auto" onClick={sendTossEvent}>
              Submit
            </button>
            {/* <button
              className="btn btn-warning"
              style={{ marginTop: "1rem" }}
              onClick={() => setCustomShow(true)}
            >
              Custom message
            </button> */}
            <h4 className="text-center text-danger">{tossError}</h4>
          </div>
        </div>
          </Modal.Body>
    
      </Modal>
    </>
  );
}
