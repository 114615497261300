import React, { useEffect, useState } from 'react'
import { FaInstagram } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { getSocialLinks, putSocialLinks } from '../../services/asyncFunctions';
import { toast } from 'react-toastify';
import { FaTelegram } from "react-icons/fa6";
import { useDispatch } from 'react-redux';
import { loadingToggleAction } from '../../store/actions/AuthActions';

function SocialLinks() {
  const [socialLinks, setSocialLinks] = useState({
    instagram: '',
    facebook: '',
    telegram: '',
    email: '',
    twitter: '',
    youtube: '',
  });
  const [successMessage, setSuccessMessage] = useState(false);
  const dispatch = useDispatch();

  // Fetch social links from the API
  const fetchSocialLinks = async () => {
    dispatch(loadingToggleAction(true));
    try {
      const response = await getSocialLinks();
      const data = await response.data.data;
      setSocialLinks({
        instagram: data.instagram,
        facebook: data.facebook,
       email: data.email,
        telegram: data.telegram,
        twitter: data.twitter,
        youtube: data.youtube,
      });
    } catch (error) {
      console.error('Failed to fetch social links:', error);
    }finally{
      dispatch(loadingToggleAction(false));
    }
  };

  // Update social links
  const updateSocialLinks = async (updatedLinks) => {
    setSuccessMessage(false);
    dispatch(loadingToggleAction(true));
    try {
      const response = await putSocialLinks(updatedLinks);
      setSuccessMessage(true);
      const data = response.data.data;
      setSocialLinks({
        instagram: data.instagram ? data.instagram : socialLinks.instagram,
        facebook: data.facebook ? data.facebook : socialLinks.facebook,
        email: data.email ? data.email : socialLinks.email,
        twitter: data.twitter ? data.twitter : socialLinks.twitter,
        youtube: data.youtube ? data.youtube : socialLinks.youtube,
        telegram: data.telegram ? data.telegram : socialLinks.telegram,
      });
      
    } catch (error) {
      console.error('Failed to update social links:', error);
    }finally{
      dispatch(loadingToggleAction(false));
    }
  };

  useEffect(() => {
    fetchSocialLinks();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSocialLinks((prevLinks) => ({
      ...prevLinks,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateSocialLinks(socialLinks);
  };




  return (
    
    <form onSubmit={handleSubmit} class="social-links-container shadow-md">
    <div class="form-group">
      <MdAlternateEmail size={50} color='rgba(77, 180, 77, 0.844)' className='mb-2'/>
      <input type="email" name='email' placeholder="Email" className='form-control' value={socialLinks.email} onChange={(e) => handleChange(e)}/>
    </div>
    <div class="form-group">
    <FaInstagram size={50} color='rgba(77, 180, 77, 0.844)' className='mb-2'/>
      <input type="text" name='instagram' placeholder="Instagram" className='form-control' value={socialLinks.instagram} onChange={(e) => handleChange(e)}/>
    </div>
    <div class="form-group">
    <FaTelegram size={50} color='rgba(77, 180, 77, 0.844)' className='mb-2'/>

      <input type="text" name='telegram' placeholder="Telegram" className='form-control' value={socialLinks.telegram} onChange={(e) => handleChange(e)}/>
    </div>
    <div class="form-group">
    <FaFacebookSquare size={50} color='rgba(77, 180, 77, 0.844)' className='mb-2'/>

      <input type="text" name='facebook' placeholder="Facebook" className='form-control' value={socialLinks.facebook} onChange={(e) => handleChange(e)}/>
    </div>
    <div class="form-group">
    <FaSquareXTwitter size={50} color='rgba(77, 180, 77, 0.844)' className='mb-2'/>

      <input type="text" name='twitter' placeholder="Twitter" className='form-control' value={socialLinks.twitter} onChange={(e) => handleChange(e)}/>
    </div>
    <div class="form-group">
    <FaYoutube size={50} color='rgba(77, 180, 77, 0.844)' className='mb-2'/>

      <input type="text" name='youtube' placeholder="YouTube" className='form-control' value={socialLinks.youtube} onChange={(e) => handleChange(e)}/>
    </div>
    <button class="btn btn-secondary">Save Changes</button>

    {successMessage && <p className='text-center text-success'>Changes update successfully!</p>}
  </form>





  )
}

export default SocialLinks